import React from "react";
import Welcome from "./Welcome";
import NmIndia from "./homepage/NmIndia";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FooterBar from "./FooterBar";
import { Outlet, Route, Routes } from "react-router-dom";

import "./../frontend-css/frontend.css";
import "./../frontend-css/About.css";
import "./../frontend-css/Contact.css";
import "./../frontend-css/career.css";
import "./../frontend-css/product.css";
import "./../frontend-css/thankyou.css";
import "./../frontend-css/addressForm.css";
import "./../frontend-css/productDetail.css";
import "./../frontend-css/cart.css";
import "./../frontend-css/footer.css";
import "./../frontend-css/header.css";
import "./../frontend-css/leadership.css";
import "./../frontend-css/blog.css";
import "./../frontend-css/dashboard.css";
import "./../frontend-css/dashboardPersonalDetail.css";
import "./../frontend-css/dashboardOrders.css";
import "./../frontend-css/blogDetail.css";
import "./../frontend-css/gallery.css";
const Combined = () => {
  return (
    <>
      <Welcome />
      <NmIndia />
      <Navbar none="d-none" block="d-lg-block" />
      <Outlet />
      <Footer />
      <FooterBar />
    </>
  );
};

export default React.memo(Combined);
