const Footer = () => {
    const currentYear = new Date().getFullYear();
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' for instant scrolling
      });
    }
  return (
    <>
      <div>
        <div className="section-body">
          <footer className="admin-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  Copyright © {currentYear} <a href="https://nmindiabio.com/">NM India Biotech</a>.
                </div>
                <div className="col-md-6 col-sm-12 text-md-right">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="fake_url">Designed by <a href="https://webanixsolutions.com/" target="_blank">Webanix Pvt Ltd</a></a>
                    </li>
                    {/* <li className="list-inline-item">
                                            <a href="fake_url">FAQ</a>
                                        </li> */}
                    {/* <li className="list-inline-item">
                                            <a href="fake_url" className="btn btn-outline-primary btn-sm">
                                                Buy Now
                                                </a>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
