import { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";

const Roles = (props) => {
  const { setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [role, setRole] = useState([]);

  const headers = [
    { name: "ROLE NAME", field: "name", sortable: true, classKey: "" },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  // get all rolesn list
  useEffect(() => {
    setIsLoading(true);
    if (!authPermissions.includes("Role & Permission-List")) {
      setIsLoading(false);
      navigate("/admin/dashboard");
    }
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("roles", options)
      .then((roles) => {
        const RolesData = roles.data.data.map((value, key) => {
          value["name"] = value.name.replace("_", " ").toUpperCase();
          let buttons = [];
          if (authPermissions.includes("Role & Permission-Edit"))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/roles/permissions/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <i className="fa fa-user"></i>
              </Link>
            );
          value["action"] = buttons;
          return value;
        });
        setRole(RolesData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [authPermissions]);

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            {/* hearder breadcrumb */}
            <div className="card-header">
              <strong className="top-left-heading">Roles & Permissions</strong>

              <ol className="headerroutes breadcrumb m-0 bg-none ">
                <li className="item color-gray">|</li>
                <li className="item">
                  <Link
                    to="/admin/dashboard"
                    className="route-heading item mx-2"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="item">
                  <FaGreaterThan className="grater-than-route" />
                </li>
                <li className="route-heading item mx-2 mt-1">
                  Roles & Permissions
                </li>
              </ol>
            </div>

            {/* right side add button */}
            <div className="header-action">
              {/* <Link to="/admin/roles/add" className="text-decoration-none">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="fe fe-plus mr-2" />
                  Add
                </button>
              </Link> */}
            </div>
          </div>
        </div>

        <div className="section-body ">
          <div className="container-fluid">
            <div className="tab-content ">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={role}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;
