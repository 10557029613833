import React from "react";
import ReactDOM from "react-dom";

// ConfirmationModal component
const ConfirmationModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      id="exampleModalCenter"
      className="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      style={{
        "padding-right": "15px",
        display: "block",
        "background-color": "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{ "text-align": "center" }}>
            <p>{message}</p>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-success mr-1"
                data-dismiss="modal"
                onClick={onConfirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(".popup-modal") // Root element to render the portal
  );
};

export default ConfirmationModal;
