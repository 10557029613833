import React, { useContext, useEffect, useState } from "react";
import { MdDone } from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../hooks/axiosPrivate";
import LoaderContext from "../context/loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../Toster";

function TnankYouPage() {
  const { setIsLoading } = useContext(LoaderContext);
  const orderId = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState({});
  const [totals, setTotals] = useState([]);

  useEffect(() => {

    axiosPrivate
      .get("ecom/orders/" + orderId.id)
      .then((res) => {
        setOrders(res?.data?.data[0]);
        getTotals(res?.data?.data[0]);
      })
      .catch((error) => {
      });
  }, []);

  function getFinalTotal(subTotal, discount, shipping) {
    return subTotal - Number(discount) + Number(shipping);
  }

  function getTotals(data) {
    var object = {
      subTotal: 0,
      total: 0,
      shipping: 0,
      taxes: 0,
      saving: 0,
    };
    data.order_items?.forEach((element) => {
      object["subTotal"] = object["subTotal"] + element.price;
      // object['shipping'] = object['shipping'] + element.shipping_amount;
      // object['discount_amount'] = object['discount_amount'] + element.discount_amount;
    });
    setTotals(object);
  }

  function getVarientImage(varient_images, Id) {
    debugger;
    let value = varient_images?.filter((res) => Id == res.id);
    return value[0]?.image;
  }

  const invoiceDownloadHandler = (e) => {
    e.preventDefault();
    let data = {
      order_id: orderId?.id,
    };
    axiosPrivate
      .post("/ecom/orders/ecom-invoice", data)
      .then((res) => {
        window.location.href = `${process.env.REACT_APP_COMMON_URL}ecominvoices/${res.data?.data[0]?.invoice}.pdf`;
      })
      .catch((err) => {
        showErrorToast("Reqest Failed");
      });
  };

  return (
    <>
      {/* Thank you Banner */}
      <div className="about-container">
        <div className="about-banner-outer-container">
          <div className="about-banner-inner-container">
            <h1 className="about-heading">Thank you</h1>
            <p className="about-home-text">
              Home/<span className="about-us-text">Thank You</span>
            </p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between thank-you-container ">
        {/* Order Confirmed Msg */}
        <div className="order-msg-container text-center">
          <MdDone className="order-done-icon" />
          <h2 className="confirmed-heading">Order Confirmed!</h2>
          <p className="confirmed-msg">
            Thank you for placing your order with NM India Biotech, your{" "}
            <strong> Order ID #{orderId.id}</strong>, We are thrilled to confirm
            that your order has been received and is being processed. We
            appreciate your trust in our products and services.
          </p>
          <Link to="/products">
            <button className="continue-shopping-btn">Continue shopping</button>
          </Link>
        </div>

        {/* Order summary */}
        <div className=" summary-outer-containner">
          <div className="order-summary-container">
            <h2 className="summary-heading">Order Summary</h2>
            <hr className="line" />

            {/* Summary product1 */}
            {orders.order_items?.map((res) => (
              <>
                <div className="d-flex justify-content-between align-items-center order-product-container">
                  <div className="order-img-div">
                    {/* <img src={getVarientImage(res.variant_images , res.variant_id)} alt="product" loading='lazy'/> */}
                    <img
                      src={`${process.env.REACT_APP_COMMON_FILE_URL}product-variants/${res.variant_images[0]?.image}`}
                      alt="product"
                      loading="lazy"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-end m-0 order-detail">
                    <div>
                      <h4 className="ordered-product-name">
                        {res.product_name}
                      </h4>
                      <p className="ordered-product-detail">
                        {res.product_description}
                      </p>
                      <p className="ordered-product-qty">
                        Quantity: {res.quantity}
                      </p>
                    </div>
                    <div className="ordered-product-price d-flex align-items-center">
                      <BsCurrencyRupee />
                      <p>{res.price}</p>
                    </div>
                  </div>
                </div>
                <hr className="line" />
              </>
            ))}

            {/* Summary product2 */}
            {/* <div className='d-flex justify-content-between align-items-center order-product-container'>
                            <div className='order-img-div'>
                                <img src="img/image62.png" alt="product" loading='lazy'/>
                            </div>
                            <div className='d-flex justify-content-between align-items-end m-0 order-detail'>
                                <div>
                                    <h4 className='ordered-product-name'>GeoPhos+</h4>
                                    <p className='ordered-product-detail'>Phosphate Solubilizing Bacteria</p>
                                    <p className='ordered-product-qty'>Quantity: 2</p>
                                </div>
                                <div className='ordered-product-price d-flex align-items-center'>
                                    <BsCurrencyRupee />
                                    <p>349</p>
                                </div>
                            </div>
                        </div>
                        <hr className='line' /> */}

            {/* Price total */}
            <div className="subtotal-container">
              <div className="d-flex justify-content-between ">
                <p className="subtotal">Subtotal</p>
                <p className="subtotal-price">
                  <BsCurrencyRupee />
                  {orders.order_amount}
                </p>
              </div>

              <div className="d-flex justify-content-between ">
                <p className="subtotal">Estimated Shipping</p>
                <p className="subtotal-price">
                  <BsCurrencyRupee />
                  {/* {orders.shipping_amount} */}
                  0.00
                </p>
              </div>

              {/* <div className='d-flex justify-content-between'>
                                <p className='subtotal'>Estimated taxs</p>
                                <p className='subtotal-price'>
                                    <BsCurrencyRupee />
                                    49
                                </p>
                            </div> */}

              <div className="d-flex justify-content-between ">
                <p className="subtotal">Total saving</p>
                <p className="subtotal-price">
                  -<BsCurrencyRupee />
                  {orders.discount_amount}
                </p>
              </div>
            </div>
            <hr className="line" />

            {/* Total amount*/}
            <div className="d-flex justify-content-between total-amount-container">
              <h5 className="total-amount">Total amount</h5>
              <div className="subtotal-price total-amount-price  d-flex">
                <BsCurrencyRupee />
                {/* <p>{getFinalTotal(totals.subTotal , orders.discount_amount , orders.shipping_amount)}</p> */}
                <p>{orders.total_amount}</p>
              </div>
            </div>
          </div>
          <button
            className="invoice-btn"
            onClick={(e) => invoiceDownloadHandler(e)}
          >
            Download invoice
          </button>
        </div>
      </div>
    </>
  );
}

export default React.memo(TnankYouPage); //TnankYouPage;
