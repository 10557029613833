import React, { useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useEffect } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Dialog } from "primereact/dialog";
import AddressForm from "../AddressForm";
import { showErrorToast, showSuccessToast } from "../../Toster";

function DashboardPeronalDetail() {
  const contactFormValidation = Yup.object().shape({
    name: Yup.string().required("Name is Required"),
    email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email!"
      )
      .required("Email is required"),

    primary_mobile: Yup.string()
      .test(
        "starts-not-with-0-to-5",
        "Mobile no is not valid",
        function (value) {
          if (!value) return true;
          return !/^[0-5]/.test(value);
        }
      )
      .required("Primary mobile no is required"),
    secondary_mobile: Yup.string().test(
      "starts-not-with-0-to-5",
      "Mobile no is not valid",
      function (value) {
        if (!value) return true;
        return !/^[0-5]/.test(value);
      }
    ),
  });
  const [addresses, setUserAddresses] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const [addressData, setAddressData] = useState({});

  const closeDiv = (data) => {
    setVisible(data);
  };
  const addAddressHandler = (e) => {
    e.preventDefault();
    setVisible(true);
    setAddressData({});
  };

  const editAddressHandler = (e, res) => {
    e.preventDefault();
    setVisible(true);
    setAddressData(res);
  };

  if (localStorage.getItem("userDetails")) {
    var { name, id } = JSON.parse(localStorage.getItem("userDetails"));
  }

  useEffect(() => {
    axiosPrivate
      .get(`users/get/addresses/${id}`)
      .then((res) => {
        setUserAddresses(res.data.data);
      })
      .catch((error) => {
        setUserAddresses([]);
      });
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userDetails"));
    axiosPrivate
      .get("users/get/" + data?.id)
      .then((res) => {
        setUserDetail(res.data?.data);
        setFieldValue("name", res.data?.data?.name);
        setFieldValue("email", res.data?.data?.email);
        setFieldValue("primary_mobile", res.data?.data?.mobile);
        setFieldValue("secondary_mobile", res.data?.data?.mobile);
      })

      .catch((error) => {});
  }, []);

  const initialValues = {
    name: "",
    email: "",
    primary_mobile: "",
    secondary_mobile: "",
  };
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: contactFormValidation,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("primary_mobile", values.primary_mobile);
      formData.append("secondary_mobile", values.secondary_mobile);

      axiosPrivate
        .patch(`users/profile/update/${id}`, formData)
        .then((res) => {
          showSuccessToast("Profile Details Updated");
        })
        .catch((err) => {
          showErrorToast(
            err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
          );
        });
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  return (
    <div className="personal_detail_form">
      <div className="user_detail_div">
        <div className="image_container">
          <img src="/img/mdi_user-edit.svg" alt="user name" />
        </div>
        <div className="user_detail">
          <h1 className="dashboard_main_heading main_heading">
            {userDetail?.name}
          </h1>
        </div>
      </div>

      {/* Delivery address form */}
      <form onSubmit={handleSubmit}>
        <div className="dashboard_form">
          <div className="field_container">
            <input
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              id="name"
              placeholder="Your name*"
            />
            {touched.name && errors.name ? (
              <div className="invalidDataError">{errors.name}</div>
            ) : null}
          </div>

          <div className="field_container">
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              id="userEmail"
              placeholder="Email"
            />
            {touched.email && errors.email ? (
              <div className="invalidDataError">{errors.email}</div>
            ) : null}
          </div>

          <div className="field_container">
            <input
              type="text"
              name="primary_mobile"
              value={values.primary_mobile}
              onBlur={handleBlur}
              onChange={numberFilter}
              id="primary_mobile"
              placeholder="Primary number*"
              maxLength={10}
            />
            {touched.primary_mobile && errors.primary_mobile ? (
              <div className="invalidDataError">{errors.primary_mobile}</div>
            ) : null}
          </div>

          <div className="field_container">
            <input
              type="text"
              name="secondary_mobile"
              values={values?.secondary_mobile}
              onChange={numberFilter}
              id="secondary_mobile"
              placeholder="Secondary number"
              maxLength={10}
            />
            {touched.secondary_mobile && errors.secondary_mobile ? (
              <div className="invalidDataError">{errors.secondary_mobile}</div>
            ) : null}
          </div>
        </div>
        <div className="update_container">
          <button type="submit" className="update_btn">
            Update
          </button>
        </div>
      </form>

      <>
        <Dialog
          header="Address Form"
          visible={visible}
          className="custom-dialog"
          // style={{ width: "50vw" }}

          onHide={() => setVisible(false)}
        >
          <AddressForm getData={closeDiv} sendData={addressData} />
        </Dialog>
      </>

      {/* Manage addresses */}
      <div className="second_section">
        <h2>Manage addresses</h2>
        <div className="address_section">
          <div
            className="card_1 shipping-card"
            onClick={(e) => addAddressHandler(e)}
          >
            <div className="add_address">
              <span className="plus-icon">+</span>
              <br></br>
              <span className="new-address-icon">Add New Address</span>
              <br></br>
              <img
                src="../img/iconoir_delivery-truck.svg"
                alt="Address"
                loading="lazy"
              />
            </div>
          </div>

          {addresses?.map((res) => (
            <div className="card_2 shipping-card">
              <div className="deliver_div">
                <Link to="/">
                  <button className="deliver_btn">Deliver Here</button>
                </Link>
              </div>
              <h4>Your Order detail</h4>
              <span className="phone_number">{res?.mobile}</span>
              <p className="mt-1 order_address">{res?.shipping_address}</p>
              <div
                className="deliver_div"
                onClick={(e) => editAddressHandler(e, res)}
              >
                <button className="edit_button mt-1">EDIT</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(DashboardPeronalDetail);
