import { useState, useCallback, useEffect } from 'react';
import CartCountContext from './CartCountContext';
import axiosPrivate from './../../hooks/axiosPrivate';

const CartCountState = (props) => {
    const [cartCountCheck, setCartCountCheck] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        let userCheck = JSON.parse(localStorage.getItem('userDetails'));
        if(userCheck) {
            axiosPrivate
                .get("carts/get")
                .then((res) => {
                    setCartCount(res.data.data?.length);
                }).catch((error) => {
                })
        }
        else {
            let localStorageCart = JSON.parse(localStorage.getItem('cart_items'));
            if(localStorageCart) {
                setCartCount(localStorageCart.length);
            }
        }
    }, [cartCountCheck])

    return (
        <CartCountContext.Provider value={{ cartCount, setCartCount, cartCountCheck, setCartCountCheck }}>
            {props.children}
        </CartCountContext.Provider>
    );
}

export default CartCountState;