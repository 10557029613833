import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import AuthContext from '../../../context/Auth/AuthContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Season = () => {
	const navigate = useNavigate();
	const { checkPermissionExists, authPermissions } = useContext(AuthContext);
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [seasonState, setSeasontState] = useState([]);

	// header
	const headers = [
		{
			name: 'SEASON  TITLE',
			field: 'season_title',
			sortable: true,
			classKey: '',
		},
		{ name: 'FROM DATE', field: 'from_date', sortable: true, classKey: '' },

		{ name: 'TO DATE', field: 'to_date', sortable: true, classKey: '' },
		{
			name: 'DISCOUNT (%)',
			field: 'discount_percentage',
			sortable: true,
			classKey: '',
		},

		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	// search item
	const searchItems = ['season_title', 'discount_percentage'];

	//  seasons data list
	useEffect(() => {
		if (!authPermissions.includes('Season-List')) {
			// navigate("/admin");
		}
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate.post('seasons', options).then((seasons) => {
			const seasonsData = seasons?.data?.data?.map((value, key) => {
				let buttons = [];
				// if (true && authPermissions.includes("Season-Edit"))
				buttons.push(
					<Link
						key="editButton##1"
						type="button"
						to={`/admin/season/edit/${value.id}`}
						className="btn btn-icon"
						title="Edit"
					>
						<BiEdit style={{ color: 'green' }} size={18} />
					</Link>
				);
				buttons.push(
					<button
						key="deleteButton##1"
						type="button"
						data-id={value.id}
						onClick={() => seasonDeleteHandler(value.id)}
						className="btn btn-icon js-sweetalert"
						title="Delete"
					>
						<RiDeleteBinLine style={{ color: 'red' }} size={17} />
					</button>
				);
				value['action'] = buttons.length > 0 ? buttons : '-';
				return value;
			});

			setSeasontState(seasonsData);
		});
	}, [authPermissions]);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function
	const seasonDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`seasons/delete/${id}`)
			.then(async (response) => {
				if (response.data.code === 200) {
					setSeasontState([]);
					setAlertMessage({ message: 'Season Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};

	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate.post('seasons', options).then((seasons) => {
				const seasonsData = seasons.data.data.map((value, key) => {
					let buttons = [];
					// if (true && authPermissions.includes("Season-Edit"))
					buttons.push(
						<button
							key="editButton##1"
							type="button"
							className="btn btn-icon"
							title="Edit"
						>
							<BiEdit style={{ color: 'green' }} size={18} />
						</button>
					);
					// if (true && authPermissions.includes("Season-Delete"))
					buttons.push(
						<button
							key="deleteButton##1"
							type="button"
							data-id={value.id}
							onClick={() => seasonDeleteHandler(value.id)}
							className="btn btn-icon js-sweetalert"
							title="Delete"
						>
							<RiDeleteBinLine style={{ color: 'red' }} size={17} />
						</button>
					);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setSeasontState(seasonsData);
				resolve(true);
			});
		});
	};

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center ">
						{/* hearder breadcrumb */}
						<div className="card-header">
							<strong className="top-left-heading">Season</strong>

							<ol className="headerroutes breadcrumb m-0 bg-none ">
								<li className="item color-gray">|</li>
								<li className="item">
									<Link
										to="/admin/dashboard"
										className="route-heading item mx-2"
									>
										Dashboard
									</Link>
								</li>
								<li className="item">
									<FaGreaterThan className="grater-than-route" />
								</li>
								<li className="route-heading item mx-2 mt-1">Season</li>
							</ol>
						</div>

						<div className="header-action">
							{authPermissions.includes('Season-Create') ? (
								<Link to="/admin/season/add" className="text-decoration-none">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</Link>
							) : null}
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="tab-content ">
							<div
								className="tab-pane fade show active"
								id="user-list"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											{
												<DataTable2
													lists={seasonState}
													headers={headers}
													searchItems={searchItems}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Season;
