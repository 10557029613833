import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Dropdown } from "primereact/dropdown";
import { showErrorToast, showSuccessToast } from "../../Toster";

const contactFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid mobile number', function(value) {
    // Check if the mobile number does not start from 0 to 5
    if (!value) return true; // If no value, let Yup.string().required handle it
    return !/^[0-5]/.test(value);
  }).required("Mobile number is required"),
  email: Yup.string()
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email!"
    )
    .required("Email is required"),
  subject: Yup.string().required("Subject is required"),
});

const ContactUsPage = () => {
  const [successMsg, setSuccessMsg] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [selectedState, setState] = useState([]);
  const [selectedCity, setCity] = useState([]);
  const [selectedDealer, setDealer] = useState([]);
  const [dealerList, getDealers] = useState({});

  useEffect(() => {
    axiosPrivate
      .get("locations/get/state")

      .then((res) => {
        setStates(res?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  async function setSelected(value) {
    setState(value);
    await axiosPrivate
      .post("locations/get/city", value)

      .then((res) => {
        setCities(res.data.data);
      })
      .catch((error) => {
      });
  }

  async function setSelectedCity(value) {
    setCity(value);

      const newState =selectedState.state
      const object={
        state:newState.toLowerCase().replace(/\b\w/g, char => char.toUpperCase()),
        city :value.city
      }
     
    
    await axiosPrivate
      .post(`dealers/by/state/city`,object)

      .then((res) => {
        setDealers(res?.data?.data);
      })
      .catch((error) => {
      });
  }

  function setSelectedDealer(value) {
    setDealer(value);
    let finalDealer = dealers.find((res)=> res.id == value.id);
    getDealers(finalDealer);
  }

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    subject: "",
    company: "",
    city: "",
    state: "",
    country: "",
    message: "",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue
  } = useFormik({
    initialValues: initialValues,
    validationSchema: contactFormValidation,
    onSubmit: (values, { setFieldError }) => {
      axiosPrivate
        .post("businessenquiry/store", values)
        .then((res) => {
          handleReset();
          window.scroll({ top: 0, behavior: "smooth" });
         showSuccessToast("Inquiry form Submitted")
        })
        .catch((error) => {
          showErrorToast("Request Failed")
        });
    },
  });

  const stateObjects = states.map(state => ({ state }));
  

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <div className="">
      <div className="about-container">
        <div className="about-banner-outer-container">
          <div className="about-banner-inner-container">
            <h1 className="about-heading">Contact Us</h1>
            <p className="about-home-text">
              Home/<span className="about-us-text">Contact us</span>
            </p>
          </div>
        </div>
      </div>
      <div className="map_image">
          <img style={{width : '100%'}} src=".\img\map section.jpg"
          alt="Map"/>
      </div>
      <div className="d-flex justify-content-between contact-page-container">
        <div className="contact-us-address-container">
          <h2 className="heading">Find a Dealer</h2>
          <Dropdown
            onChange={(e) => setSelected(e.value)}
            options={stateObjects}
            value={selectedState}
            optionLabel="state"
            placeholder="Select State"
            className="w-full md:w-14rem search"
          />
          <Dropdown
            options={cities}
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            optionLabel="city"
            placeholder="Select City"
            className="w-full md:w-14rem"
          />
          <Dropdown
            options={dealers}
            value={selectedDealer}
            onChange={(e) => setSelectedDealer(e.value)}
            optionLabel="account_name"
            placeholder="Dealer"
            className="w-full md:w-14rem"
          />

          {Object.keys(dealerList).length > 0 ? <div className="show_dealers">
            <div className="dealer_details">
              <p>{dealerList.account_name} </p>
              <p>{dealerList.account_mobile}</p>
            </div>
            <span>
              Address : {dealerList.account_address} {dealerList.account_city} {dealerList.account_state} {dealerList.account_zip}
            </span>
          </div> : ''}

          {/* <div className='d-flex box-border align-items-center'>
                        <div className='icon-padding'>
                            <HiLocationMarker className='contact-page-icon' />
                        </div>
                        <div className='address-padding'>
                            <h5 className='contact-us-heading'>Regd. Office</h5>
                            <p className='contact-us-address'>Araji No. 4790, Jhamar Kotra Road,
                                Umarda, Udaipur - 313003,Rajasthan, INDIA.</p>
                        </div>
                    </div>

                    <div className='d-flex  box-border align-items-center'>
                        <div className='icon-padding'>
                            <HiLocationMarker className='contact-page-icon' />
                        </div>
                        <div className='address-padding'>
                            <h5 className='contact-us-heading'>Factory Address</h5>
                            <p className='contact-us-address'>Araji No. 4790, Jhamar Kotra Road,
                                Umarda, Udaipur - 313003,Rajasthan, INDIA.</p>
                        </div>
                    </div>

                    <div className='d-flex  box-border align-items-center'>
                        <div className='icon-padding'>
                            <HiLocationMarker className='contact-page-icon' />
                        </div>
                        <div className='address-padding'>
                            <h5 className='contact-us-heading'>Regional Mktg. Office</h5>
                            <p className='contact-us-address'>14 - Vinayak Nagar Complex,Opp. Maha Satiya,
                                Udaipur - 313001, Rajasthan, INDIA.</p>
                        </div>
                    </div>

                    <div className='d-flex  box-border align-items-center'>
                        <div className='icon-padding'>
                            <MdEmail className='contact-page-icon' />
                        </div>
                        <div className='address-padding'>
                            <Link to='/'>
                                <p className='contact-email'>info@nmindiabio.com</p>
                            </Link>
                        </div>
                    </div>

                    <div className='d-flex  box-border align-items-center'>
                        <div className='icon-padding'>
                            <IoMdCall className='contact-page-icon' />
                        </div>
                        <div className='address-padding'>
                            <p className='contact-number'>Toll Free- 1800 891 9981</p>
                        </div>
                    </div> */}
        </div>
        <div className="contact-form">
          <div className="col-12">
            <h2 className="business-enquiry-heading">Business Enquiry</h2>
          </div>
          {successMsg ? (
            <div className="success_msg">
              <p className="m-0">
                Thank you for submitting the business enquiry!
              </p>
            </div>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit}>
            <div className="row row-cols-sm-2 row-cols-1 form-container g-0 justify-content-between">
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  value={values?.name}
                  onChange={(event) => {
                    nameFilter(event);
                  }}
                  onBlur={handleBlur}
                  name="name"
                  id="name"
                  placeholder="Your Name *"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
                {touched.name && errors.name ? (
                  <div className="invalidDataError">{errors.name}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={(event) => {
                    numberFilter(event);
                  }}
                  onBlur={handleBlur}
                  value={values?.mobile}
                  name="mobile"
                  id="number"
                  placeholder="Mobile Number *"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
                {touched.mobile && errors.mobile ? (
                  <div className="invalidDataError">{errors.mobile}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  name="email"
                  id="emailid"
                  placeholder="Email *"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
                {touched.email && errors.email ? (
                                    <div className="invalidDataError">
                                        {errors.email}
                                    </div>
                                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.subject}
                  name="subject"
                  id="subject"
                  placeholder="Subject *"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
                {touched.subject && errors.subject ? (
                  <div className="invalidDataError">{errors.subject}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.company}
                  name="company"
                  id="company"
                  placeholder="Company"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={(event) => {
                    nameFilter(event);
                  }}
                  onBlur={handleBlur}
                  value={values?.city}
                  name="city"
                  id="city"
                  placeholder="City"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={(event) => {
                    nameFilter(event);
                  }}
                  onBlur={handleBlur}
                  value={values?.state}
                  name="state"
                  id="state"
                  placeholder="State"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  onChange={(event) => {
                    nameFilter(event);
                  }}
                  onBlur={handleBlur}
                  value={values?.country}
                  name="country"
                  id="country"
                  placeholder="Country"
                  className="form-input"
                  style={{paddingLeft:'15px'}}
                />
              </div>
              <div className="col-12 contact_field">
                <textarea
                  name="message"
                  value={values?.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="message"
                  cols="30"
                  rows="5"
                  className="text-area"
                  placeholder="Comments"
                  style={{paddingLeft:'15px'}}
                ></textarea>
              </div>
            </div>
            <div className="submit-btn-container col-12">
              <button type="submit" className="form-submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContactUsPage);
