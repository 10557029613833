import React, { useEffect, useState } from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { HiLocationMarker, HiShoppingCart } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { MdEmail, MdKeyboardArrowDown } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import Dashboard from "./dashboard/Dashboard";

function Welcome() {

  const navigate = useNavigate();
  let count = 0;

  const [showMenubar, setshowmenu] = useState(false);
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [activeLink, setActiveLink] = useState("Dashboard");

  const clearAuthData = (e) => {
    e.preventDefault()
    localStorage.removeItem("userData");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userRefreshToken")
    navigate("/");
  };

  function redirectToAnother() {
    const authDeatils = JSON.parse(localStorage.getItem("userDetails"));
    if (localStorage.getItem("userData")) {
      if (authDeatils.role == 8) {
        navigate("/user/dashboard");
      } else {
        navigate("admin/dashboard");
      }
    } else {
      navigate("/user-panel/signin");
    }
  }

  return (
    <>
      <div className="nm_header">
        <div
          id="top-bar"
          className="row m-0 row-cols-2 row-cols-lg-1 py-2 g-0 d-flex align-items-center"
        >
          <p className="welcome-bar">Welcome to Organic Farming</p>

          <div className="d-flex justify-content-end row-cols-2 d-block d-lg-none nav-account">
            <span className="d-flex align-items-center burger-btn">
              <RiAccountCircleFill
                className="mx-1 account-icon"
                onClick={redirectToAnother}
              />
              <Link to="/cart">
                {" "}
                <HiShoppingCart className="mx-1 account-icon" />
              </Link>
            </span>

            <button
              type="button"
              onClick={() => setshowmenu(!showMenubar)}
              data-bs-toggle="offcanvas"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="burger-btn d-flex justigy-content-end"
            >
              {!showMenubar ? (
                <GiHamburgerMenu className="burger-icon text-light" />
              ) : (
                ""
              )}
            </button>

            <div className={` ${showMenubar ? "show-navbar" : "hide-navbar"}`}>
              <div className="sideBar">
                <div className="logo-container">
                  <img
                    src="/assets/images/logo.png"
                    alt="NM India"
                    className="logo-img"
                    loading="lazy"
                  />
                  <div onClick={() => setshowmenu(!showMenubar)}>
                    {showMenubar ? (
                      <RxCross1 className="burger-icon text-light cross-icon" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="second-section">
                  <ul>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        to="/"
                        className="nav-menu-link active"
                        aria-current="page"
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        className="nav-menu-link active"
                        aria-current="page"
                        to="/about-us"
                      >
                        About Us
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        className="nav-menu-link active"
                        aria-current="page"
                        to="/products"
                      >
                        Products
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        className="nav-menu-link active"
                        aria-current="page"
                        to="/home"
                      >
                        Blog
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        className="nav-menu-link active"
                        aria-current="page"
                        to="/careers"
                      >
                        Career
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item"
                      onClick={() => setshowmenu(!showMenubar)}
                    >
                      <Link
                        className="nav-menu-link active"
                        aria-current="page"
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li
                      className="nav-menu-item user_panel"
                      onClick={() => {
                        setShowUserPanel(!showUserPanel);
                      }}
                    >
                      User Panel
                      <MdKeyboardArrowDown
                        className={`${showUserPanel ? "menuIcon" : ""} `}
                      />
                      <ul
                        className={`${
                          showUserPanel ? "user_panel_show" : "user_panel_hide"
                        }`}
                      >
                        <Link to="/user/my-dashboard">
                          <li
                            className="panel_item"
                            onClick={() => {
                              setActiveLink("Dashboard");
                              setshowmenu(!showMenubar);
                            }}
                          >
                            Dashboard
                          </li>
                        </Link>
                        <Link to="/user/personal-detail">
                          <li
                            className="panel_item"
                            onClick={() => {
                              setshowmenu(!showMenubar);
                            }}
                          >
                            Personal Detail
                          </li>
                        </Link>
                        <Link to="/user/orders">
                          <li
                            className="panel_item"
                            onClick={() => {
                              setshowmenu(!showMenubar);
                            }}
                          >
                            My Orders
                          </li>
                        </Link>
                        <li
                          className="panel_item"
                          onClick={(e) => clearAuthData(e)}
                        >
                          Log Out
                        </li>
                      </ul>
                    </li>
                    {/* <div className="mobile_search_bar">
                      <input
                        type="search"
                        className="search"
                        placeholder="search..."
                      />
                      <button>X</button>
                    </div> */}

                    {/* Location and contact icons */}
                    <div className="nav-item d-flex contact-icons">
                      <Link to="/contact-us">
                        <HiLocationMarker className=" fs-2 me-3 mr-4" />
                      </Link>
                      <a href="tel:18008919981">
                        <IoMdCall className=" mr-4 fs-2" />
                      </a>
                      <a href="mailto:info@nmindiabio.com">
                        <MdEmail className=" fs-2 mr-4" />
                      </a>
                    </div>
                    <div className="d-none">
                      <Dashboard
                        navigationLink={activeLink}
                        id="dashboard-component"
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Welcome);
