import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";

const Users = (props) => {
  const { setIsLoading } = useContext(LoaderContext);
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [userState, setUserState] = useState([]);
  // header
  const headers = [
    { name: "Area Manager Name", field: "name", sortable: true, classKey: "" },
    {
      name: "Target Period",
      field: "target_date",
      sortable: true,
      classKey: "",
    },
    {
      name: "Target Amount (₹)",
      field: "target_amount_sum",
      sortable: true,
      classKey: "",
    },
    // { name: "Modified By", field: "modified", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name", "target_date", "target_amount_sum"];

  //   get all sales target list
  useEffect(() => {
    setIsLoading(true);
    if (!authPermissions.includes("Sales Target-List")) {
      // navigate("/admin");
    }
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .post("sales-target", options)
      .then((users) => {
        const usersData = users.data.data.map((value, key) => {
          let buttons = [];
          // if (true && authPermissions.includes("Sales Target-Edit"))
          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/sales-target/edit/${value.user_id}/${value.financial_year}`}
              className="btn btn-icon"
              title="Edit"
            >
              <BiEdit style={{ color: "green" }} size={18} />
            </Link>
          );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });
        setUserState(usersData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [authPermissions]);
  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            {/* hearder breadcrumb */}
            <div className="card-header">
              <strong className="top-left-heading"> Sales Targets</strong>

              <ol className="headerroutes breadcrumb m-0 bg-none ">
                <li className="item color-gray">|</li>
                <li className="item">
                  <Link
                    to="/admin/dashboard"
                    className="route-heading item mx-2"
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="item">
                  <FaGreaterThan className="grater-than-route" />
                </li>
                <li className="route-heading item mx-2 mt-1">Sales Targets</li>
              </ol>
            </div>

            <div className="header-action">
              {/* {authPermissions.includes("Sales Target-Create") ? ( */}
              <Link
                to="/admin/sales-target/add"
                className="text-decoration-none"
              >
                <button
                  type="button"
                  className="btn btn-primary add_btn"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="fe fe-plus mr-2" />
                  Add
                </button>
              </Link>
              {/* ) : null} */}
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={userState}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
