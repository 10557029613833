import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";

const careerFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile_no: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid mobile number', function(value) {
    // Check if the mobile number does not start from 0 to 5
    if (!value) return true; // If no value, let Yup.string().required handle it
    return !/^[0-5]/.test(value);
  }).required("Mobile number is required"),
  email: Yup.string()
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email!"
    )
    .required("Email is required"),
  resume: Yup.string().required("Resume is required"),
  apply_position: Yup.string().required("Position is required"),
});

function CareerPage() {
  const [successMsg, setSuccessMsg] = useState(false);
  const fileInputRef = useRef(null);

  const initialValues = {
    name: "",
    mobile_no: "",
    email: "",
    apply_position: "",
    resume: null,
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: careerFormValidation,
    onSubmit: (values, { setFieldError, resetForm }) => {
      let formData = new FormData();
      Object.keys(values).map((res) => {
        formData.append(res, values[res]);
      });
      axiosPrivate
        .post("career/store", formData)
        .then((res) => {
          // alert(res?.data?.message);
          resetForm();
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the input field value
          }
          window.scroll({ top: 0, behavior: "smooth" });
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 3000);
        })
        .catch((error) => {});
    },
  });

  // const onFileUpload = (event) => {
  //     setFieldValue('resume', event.target.files[0]);
  // }

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <>
      <div className="about-container">
        <div className="about-banner-outer-container">
          <div className="about-banner-inner-container">
            <h1 className="about-heading">Career</h1>
            <p className="about-home-text">
              Home/<span className="about-us-text">Career</span>
            </p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between career-page-container">
        <div className="career-choose-container">
          <h2 className="choose-us-heading">Why Choose Us?</h2>
          <p className="choose-us-detail">
            NM India Biotech is a leading provider of chemical-free argic
            inputs. We are part of the Sunlight Group, a diversified business
            conglomerate with a strong track record of success in the mining,
            logistics, petroleum, and food processing sectors. We are committed
            to providing our customers with high-quality, affordable agri inputs
            that are safe for the environment and beneficial to human health.
            Our products are made from natural ingredients and are rigorously
            tested to ensure their quality and efficacy. We offer a wide range
            of agri inputs, including fertilizers, pesticides, and herbicides.
            We also offer a variety of services, such as crop advisory, soil
            testing, and farm management. Choose NM India Biotech for
            chemical-free agri inputs that are safe for the environment and
            beneficial to human health. contact us today to learn more about our
            products and services.
          </p>
        </div>
        {/* <div className='career-form'> */}
        <div className="career-form">
          <div className="col-12 contact_field">
            <h2 className="choose-us-heading">Apply Now</h2>
          </div>
          {successMsg ? (
            <div className="success_msg">
              <p className="m-0">Thank you for the enquiry!</p>
            </div>
          ) : (
            ""
          )}
          <form initialValues={initialValues} onSubmit={handleSubmit}>
            <div className="row row-cols-2 form-container g-0 justify-content-between">
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  value={values?.name}
                  onChange={(event) => {
                    nameFilter(event);
                  }}
                  onBlur={handleBlur}
                  name="name"
                  id="name"
                  placeholder="Your Name *"
                  className="form-input"
                  style={{ paddingLeft: "15px" }}
                />
                {touched.name && errors.name ? (
                  <div className="invalidDataError">{errors.name}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  name="mobile_no"
                  value={values?.mobile_no}
                  onChange={(event) => {
                    numberFilter(event);
                  }}
                  onBlur={handleBlur}
                  id="number"
                  placeholder="Mobile Number *"
                  className="form-input"
                  style={{ paddingLeft: "15px" }}
                  maxLength={10}
                />
                {touched.mobile_no && errors.mobile_no ? (
                  <div className="invalidDataError">{errors.mobile_no}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailid"
                  placeholder="Email *"
                  className="form-input"
                  style={{ paddingLeft: "15px" }}
                />
                {touched.email && errors.email ? (
                  <div className="invalidDataError">{errors.email}</div>
                ) : null}
              </div>
              <div className="col-sm-6 col-12 contact_field">
                <input
                  type="text"
                  name="apply_position"
                  value={values?.apply_position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="position"
                  placeholder="Position Applying For *"
                  className="form-input"
                  style={{ paddingLeft: "15px" }}
                />
                {touched.apply_position && errors.apply_position ? (
                  <div className="invalidDataError">
                    {errors.apply_position}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 contact_field">
              <input
                type="file"
                name="resume"
                ref={fileInputRef}
                onChange={(event) =>
                  setFieldValue("resume", event.target.files[0])
                }
                onBlur={handleBlur}
                id="file"
                className="file-input"
                style={{ paddingLeft: "15px" }}
              />
              {touched.resume && errors.resume ? (
                <div className="invalidDataError">{errors.resume}</div>
              ) : null}
            </div>
            <div className="submit-btn-container col-12">
              <button type="submit" className="career-submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default React.memo(CareerPage);
