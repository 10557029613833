import React, { useRef, useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaRupeeSign } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { BsCurrencyRupee } from 'react-icons/bs';
import axiosPrivate from "../../hooks/axiosPrivate";

function UserDashboard() {
    const  [addresses,setUserAddresses] =useState([]);
    const [orders,setUserOrders]=useState([]);
    const [showSideBar, setShowSideBar] = useState(false);
    const divRef = useRef(null);
    const percentage = 27;
    let height;


    if (localStorage.getItem("userDetails")) {
        var { name, id } = JSON.parse(localStorage.getItem("userDetails"))
      }

    function getVarientImage(varients , id){
        let image_varient = varients.filter(res=> res.model_id == id)
        return process.env.REACT_APP_COMMON_FILE_URL+'product-variants/'+image_varient[0]?.image
    }

    useEffect(() => {
        axiosPrivate
          .get(`users/get/addresses/${id}`)
          .then((res) => {
            setUserAddresses(res.data.data);
          })
          .catch((error) => {
            setUserAddresses([]);
          });
    
        axiosPrivate
          .get("/ecom/orders/get")
          .then((res) => {
            setUserOrders(res.data.data);
          })
          .catch((error) => {
            setUserOrders([]);
          });
    
      }, []);

    return (
        <div id='dashboard' className='dashboard_user' ref={divRef}>
            <h1 className='dashboard_main_heading'>Welcome {name}</h1>
            {/* 1st component */}
            <div className='order_status' style={{"display" : "none"}}>
                {/* Order completion rate */}
                <div className='order_completion_rate'>
                    <div className='progressBar'>
                        <CircularProgressbar value={percentage} text={`${percentage}%`} />
                    </div>
                    <div className='orders'>
                        <h2 className='status_title'>Order Completion Rate</h2>
                        <p className='status_description'>Lorem Ipsum is simply dummy text of
                            the printing and typesetting industry.
                            Lorem Ipsum has been the industry's
                            standard dummy text ever since the
                            1500s</p>
                    </div>
                </div>
                {/* Pending order */}
                <div className='order_completion_rate'>
                    <div className='progressBar'>
                        <CircularProgressbar value={percentage} text={`${percentage}%`} />

                    </div>
                    <div className='orders'>
                        <h2 className='status_title'>Order Completion Rate</h2>
                        <p className='status_description'>Lorem Ipsum is simply dummy text of
                            the printing and typesetting industry.
                            Lorem Ipsum has been the industry's
                            standard dummy text ever since the
                            1500s</p>
                    </div>
                </div>
                {/* Cart conversion rate */}
                <div className='order_completion_rate'>
                    <div className='progressBar'>
                        <CircularProgressbar value={percentage} text={`${percentage}%`} />

                    </div>
                    <div className='orders'>
                        <h2 className='status_title'>Order Completion Rate</h2>
                        <p className='status_description'>Lorem Ipsum is simply dummy text of
                            the printing and typesetting industry.
                            Lorem Ipsum has been the industry's
                            standard dummy text ever since the
                            1500s</p>
                    </div>
                </div>
            </div>

            {/* 2nd component */}
            <div className='second_container'>
                {/* my orders */}
                <div className='orders_container'>
                    <h2 className='order_heading'>My Orders</h2>
                    <hr className='horizontal_line' />
                    {/* Dispatched product */}
                    {orders?.map((res) => (
                        <div className="card">
                            <div className='dispatch_div'>
                                <p className='status_dot'></p>
                                <p className='order_status_name'>{res.order_status}, <span className='order_id'>Order Id: #{res.order_id}</span></p>
                            </div>
                            <Link to="/dashboard">
                                {res.order_items.map((res)=>(
                                    <div className='ordered_product_div mt-2'>
                                        <div className='order-img-div'>
                                            <img src={getVarientImage(res.variant_images , res.variant_id)} alt="product" loading='lazy' />
                                        </div>
                                        <div className='d-flex justify-content-between align-items-end m-4 order-detail'>
                                            <div>
                                                <h4 className='ordered-product-name'>{res.product_name}</h4>
                                                <p className='ordered-product-detail'>{res.product_description}</p>
                                                <div className='ordered-product-price d-flex align-items-center'>
                                                    <BsCurrencyRupee />
                                                    <p>{res.price}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Link>
                        </div>
                    ))}

                    {/* Deliverd product */}
                    {/* <div className="card">
                        <div className='dispatch_div'>
                            <p className='status_dot'></p>
                            <p className='order_status_name'>Delivered on 23 june 2023</p>
                        </div>
                        <Link to="/dashboard">
                            <div className='ordered_product_div'>
                                <div className='order-img-div'>
                                    <img src="img/image62.png" alt="product" loading='lazy' />
                                </div>
                                <div className='d-flex justify-content-between align-items-end m-4 order-detail'>
                                    <div>
                                        <h4 className='ordered-product-name'>GeoPhos+(1kg)</h4>
                                        <p className='ordered-product-detail'>Phosphate Solubilizing Bacteria</p>
                                        <div className='ordered-product-price d-flex align-items-center'>
                                            <BsCurrencyRupee />
                                            <p>349</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div> */}

                </div>
                {/* addresses */}

                <div className='address_container'>
                    <h2 className='order_heading'>Addresses</h2>
                    <hr className='horizontal_line' />
                    <div className='address_cards'>
                        {addresses?.map((res , index)=>(
                            <>
                            {index < 4 ? <>
                                <div className='card'>
                                <h3 className='sub_heading'>{res.name}</h3>
                                <p className='card_content'>{res.shipping_address} {res.shipping_city} {res.shipping_state} {res.shipping_zip}</p>
                            </div>
                            <hr /></> : ''}
                            </>
                        ))}

                        <div className='view_button_container'>
                            {/* <Link to='/'> */}
                                <button className='view_button'>View All</button>
                            {/* </Link> */}
                        </div>
                    </div>

                    {/* <div className='card'>
                        <h3 className='sub_heading'>Regd. Office</h3>
                        <p className='card_content'>Araji No. 4790, Jhamar Kotra Road,
                            Umarda, Udaipur - 313003,Rajasthan, INDIA.</p>
                    </div>
                    <hr />

                    <div className='card'>
                        <h3 className='sub_heading'>Regd. Office</h3>
                        <p className='card_content'>Araji No. 4790, Jhamar Kotra Road,
                            Umarda, Udaipur - 313003,Rajasthan, INDIA.</p>
                    </div>
                    <div className='view_button_container'>
                        <Link to='/'>
                            <button className='view_button'>View All</button>
                        </Link>

                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default React.memo(UserDashboard);

