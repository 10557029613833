import React, { useContext } from "react";
import axiosPrivate from "../hooks/axiosPrivate";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AlertContext from "../context/Alert/AlertContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CartCountContext from "../context/CartCount/CartCountContext";

const ProductPage = () => {
  const [allCategories, setCategories] = useState([]);
  const [productList, setProducts] = useState([]);
  const [focusCategory, setFocusCategory] = useState({});
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);
  const navigate = useNavigate();
  useEffect(() => {
    axiosPrivate
      .get("/categories/get")
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        setCategories([]);
      });
    getProducts();
  }, []);

  function getProducts() {
    axiosPrivate
      .get("/product-details")
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((error) => {
        setProducts([]);
      });
  }

  function addToCart(product_detail) {
    let userLogin = localStorage.getItem("userData");
    let object = {
      product_id: product_detail.id,
      variant_id: product_detail.product_variants[0].id,
      quantity: 1,
    };
    if (userLogin) {
      axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
          // setProducts(res.data.data);
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
          setAlertMessage({ message: "Item Added in Cart!" });
        })
        .catch((error) => {
          // setProducts([]);
          setAlertMessage({ message: "Something Went Wrong!" });
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        });
    } else {
      object["product_name"] = product_detail.name;
      object["rate"] = product_detail.product_variants[0].rate;
      object["base_unit"] = product_detail.product_variants[0].base_unit;
      object["variant"] = product_detail.product_variants[0].variant;
      object["description"] = product_detail.description;
      object["image"] =
        "product-details/" + product_detail.product_images[0]?.image;
      let cart_items = localStorage.getItem("cart_items");
      let cart_array = [];
      cart_array.push(object);
      if (!cart_items) {
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      } else {
        let cart_array = JSON.parse(cart_items);
        let boolean_cart = false;
        cart_array?.forEach((element) => {
          if (object.product_id == element.product_id) {
            boolean_cart = true;
            element.quantity = element.quantity + 1;
          }
        });

        if (!boolean_cart) {
          cart_array.push(object);
        }
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      }
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
      setAlertMessage({ message: "Item Added in Cart!" });
    }
    setCartCountCheck(!cartCountCheck);
  }

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  // get product by category
  const getProductsByCategory = (category) => {
    setFocusCategory(category);
    setProducts([]);
    axiosPrivate
      .get(`product-details/get/${category.id}`)
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      {/* <div className="spinner_class">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> */}
      <div className="product-page">
        <div className="banner-image">
          <img src="img/product-banner.png" alt="product_image" />
        </div>
        <div className="product-content">
          <div className="text-center">
            <p>Shop</p>
            <span>
              Home/<span style={{ color: "#50AE31" }}>Shop</span>
            </span>
          </div>
        </div>
      </div>
      <div className="organic-products">
        <div className="text-center">
          <h6>Chemical Free</h6>
          <div className="border-bottom"></div>
          <h4>Shop Our Organic Products</h4>
          <p className="product-para">
            NM India Biotech is involved in the development, manufacturing and
            marketing of chemical free agri inputs. We belong to the Sunlight
            Group which has a diversebusiness portfolio in sectors like mining,
            logistics, petroleum & food processing since 2003.
          </p>
        </div>
        <div className="product-card">
          {allCategories.map((res) => (
            <div
              className={`inner-cards ${
                res.id == focusCategory.id ? "focus_background" : ""
              }`}
              onClick={() => getProductsByCategory(res)}
            >
              <div className="card_img">
                <Link to="/products">
                  <img
                    src={
                      process.env.REACT_APP_COMMON_FILE_URL +
                      "categories/" +
                      JSON.parse(res.image)?.file
                    }
                    alt="product_image"
                  />
                </Link>
              </div>
              <div className="storage-content">
                <p>{res.name}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="border-bottom-card"></div>
        <div className="product-list row ">
          {/* {productList.map((res) => (
            <div className="col-lg-3 col-md-4 col-sm-6 products-container">
              <div className="product-list-card">
                <div className="product-image">
                  <img
                    src={
                      process.env.REACT_APP_COMMON_FILE_URL +
                      "product-details/" +
                      res.product_images[0]?.image
                    }
                    alt={res.alt}
                  />
                </div>
                <div className="product-image-border"></div>
                <div className="product-content">
                  <div>
                    <Link to={"/productdetail/" + res.slug}>
                      <p className="product_name">{res.name.length >25 ?res.name.slice(0, 25)+"...":res.name}</p>
                    </Link>
                    <p className="product_tag_line">{res.description}</p>
                    <div className="product_buttons">
                      <button onClick={() => addToCart(res)}>
                        Add To Cart
                      </button>
                      <p className="product_name product-price">₹ {res.product_variants[0]?.rate}</p>
                    </div>
                      <p className="product_variant">({res.product_variants[0]?.variant}{res.product_variants[0]?.base_unit})</p>
                  </div>
                </div>
              </div>
            </div>
            
          ))} */}
          {/* <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="product-list-card">
              <div className="product-image">
                <img src="img/gau-vita 1.png" />
              </div>
              <div className="product-image-border"></div>
              <div className="product-content">
                <div>
                  <p className="product_name">Geo Potash</p>
                  <p className="product_tag_line">
                    Potash derived from molasses (K2O - 14.5%)
                  </p>
                  <div className="product_buttons">
                    <button>Add To Cart</button>
                    <p className="product_name">₹ 345</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="product-list-card">
              <div className="product-image">
                <img src="img/gau-vita 1.png" />
              </div>
              <div className="product-image-border"></div>
              <div className="product-content">
                <div>
                  <p className="product_name">PROM</p>
                  <p className="product_tag_line">
                    Phosphate Rich Oraganic Manure
                  </p>
                  <div className="product_buttons">
                    <button>Add To Cart</button>
                    <p className="product_name">₹ 345</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="product-list-card">
              <div className="product-image">
                <img src="img/gau-vita 1.png" />
              </div>
              <div className="product-image-border"></div>
              <div className="product-content">
                <div>
                  <p className="product_name">Gau-vita</p>
                  <p className="product_tag_line">Cattle Health Suppliment</p>
                  <div className="product_buttons">
                    <button>Add To Cart</button>
                    <p className="product_name">₹ 345</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="border-bottom-card"></div>
        <div className="product-list row ">
          {productList.map((res) =>
            res?.product_variants?.length > 0 ? (
              <div className="col-lg-3 col-md-4 col-sm-6 products-container">
                <div className="product-list-card">
                  <div className="product-image">
                    <img
                      src={
                        process.env.REACT_APP_COMMON_FILE_URL +
                        "product-details/" +
                        res.product_images[0]?.image
                      }
                      alt={res.alt}
                    />
                  </div>
                  <div className="product-image-border"></div>
                  <div className="product-content">
                    <div>
                      <Link to={"/productdetail/" + res.slug}>
                        {/* <p className="product_name">{res.name}</p> */}
                        <p className="product_name">
                          {res.name.length < 18
                            ? res.name
                            : res.name.slice(0, 18)+"..."}
                        </p>
                      </Link>
                      <p className="product_tag_line">{res.description}</p>
                      <div className="product_buttons">
                        <button onClick={() => addToCart(res)}>
                          Add To Cart
                        </button>
                        <p className="product_name product-price">
                          ₹ {res.product_variants[0]?.rate}
                        </p>
                      </div>
                      <p className="product_variant">
                        ({res.product_variants[0]?.variant}
                        {res.product_variants[0]?.base_unit})
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductPage); //ProductPage;
