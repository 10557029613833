import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import Changepassword from './Changepassword';
import classes from './Userprofile.module.css';

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
		email: Yup.string()
		.matches(
		  /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
		  "Please enter a valid email!"
		)
		.required("Email is required"),
	
		mobile: Yup.string().test('starts-not-with-0-to-5', 'Mobile no is not valid', function(value) {
	   
		if (!value) return true; 
		return !/^[0-5]/.test(value);
	  }).required("Primary mobile no is required"),
	  additional_mobile: Yup.string().test('starts-not-with-0-to-5', 'Mobile no is not valid', function(value) {
	   
		if (!value) return true; 
		return !/^[0-5]/.test(value);
	  })
};

const Userprofile = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [isPasswordChange, setIsPasswordChange] = useState(false);
	const [getData, setGetData] = useState({});

	const OpenpasswordModel = () => {
		setIsPasswordChange(true);
	};

	const closeModelPassword = () => {
		setIsPasswordChange(false);
	};

	// user get by id

	useEffect(() => {
		axiosPrivate
			.get(`users/get/${id}`)
			.then((response) => {
				if (response.data.code === 200) {
					setGetData({ ...response.data.data, password: '' });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				} else if (error.request) {
					//response not received though the request was sent
					showErrorToast('Internal server error');
				} else {
					//an error occurred when setting up the request
					showErrorToast('Internal server error');
				}
			});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	// formik user Update

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			mobile: '',
			additional_mobile: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var values = {
				name: values.name,
				email: values.email,
				primary_mobile: values.mobile,
				secondary_mobile: values.additional_mobile,
			};

			axiosPrivate
				.patch(`/users/profile/update/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'updated successfully' });
					let user = JSON.parse(localStorage.getItem('userDetails'));
					user.name = values.name;
					user.email = values.email;
					user.mobile = values.mobile;
					localStorage.setItem('userDetails', JSON.stringify(user));
					navigate('/admin/dashboard');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	const logoutHandler = (e) => {
		e.preventDefault()
		localStorage.removeItem("userData");
		localStorage.removeItem("userRefreshToken");
		localStorage.removeItem("userDetails");
		navigate("/user-panel/signin");
	  };


	  const numberFilter = (event) => {
		const inputValue = event.target.value;
	
		const numericValue = inputValue.replace(/\D/g, "");
		formik.setFieldValue(event.target.name, numericValue);
	  };

	return (
		<>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header d-flex justify-content-between">
									<strong style={{ fontWeight: '700', fontSize: '18px' }}>
										User Profile
									</strong>
									<strong onClick={(e)=>logoutHandler(e)} className='mr-2 d-inline d-sm-none' >
										Logout <i class="fa fa-sign-out" aria-hidden="true"></i>
									</strong>
								</div>
								<form onSubmit={formik.handleSubmit} id="userprofile">
									<div className="card-body">
										<div className="row clearfix">
											{/* Name */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Name:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="User Name "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>

											{/* Email */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Email:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="email"
														name="email"
														type="email"
														className="form-control"
														placeholder="Email Name "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.email}
													/>
													{formik.touched.email && formik.errors.email ? (
														<div className={classes.invalidDataError}>
															{formik.errors.email}
														</div>
													) : null}
												</div>
											</div>

											{/* Primary Mpbile Number */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Primary Mobile Number:
													<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="mobile"
														name="mobile"
														type="text"
														className="form-control"
														placeholder="Primary Mobile Number "
														onChange={numberFilter}
														onBlur={formik.handleBlur}
														value={formik.values.mobile}
														maxLength={10}
													/>
													{formik.touched.mobile && formik.errors.mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.mobile}
														</div>
													) : null}
												</div>
											</div>

											{/* Secondary Mobile Number */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Secondary Mobile Number:
													<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="additional_mobile"
														name="additional_mobile"
														type="text"
														className="form-control"
														placeholder="Secondary Mobile Number "
														onChange={numberFilter}
														onBlur={formik.handleBlur}
														value={formik.values.additional_mobile}
														maxLength={10}
													/>
													{formik.touched.additional_mobile &&
													formik.errors.additional_mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.additional_mobile}
														</div>
													) : null}
												</div>
											</div>

											{/* chang Password link */}
											{/* <div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<a
														href="#"
														onClick={() => OpenpasswordModel()}
														style={{
															fontSize: '15px',
															textDecoration: 'underline',
														}}
													>
														Change Password
													</a>
												</div>
											</div> */}

											{/* button */}
											{isPasswordChange ? (
												''
											) : (
												<div className="col-12 submit-btn">
													<div className="table-responsive">
														<table className="table table-striped"></table>
													</div>
													<button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
														onClick={() => navigate('/admin/dashboard')}
													>
														Close
													</button>
													<button
														type="submit"
														className="btn btn-primary  ml-2"
													>
														Submit
													</button>
												</div>
											)}
										</div>
									</div>
								</form>

								{/* <div className="card-body" style={{ padding: '0 20px 20px' }}>
									{isPasswordChange ? (
										<Changepassword changePasswordClose={closeModelPassword} />
									) : (
										''
									)}
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Userprofile;
