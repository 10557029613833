import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import Swal from 'sweetalert2';
import DeleteConfirm from '../../../delete-confirmation/DeleteConfirm';
const ProductDetails = () => {
	const imageURL = process.env.REACT_APP_COMMON_FILE_URL;
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [categoryState, setCategoryState] = useState([]);
	const [confirm, setConfirm] = useState(false)
	const [id, setId] = useState("")
	// table heading
	const headers = [
		{ name: 'image', field: 'image', sortable: true, classKey: '' },
		{
			name: 'PRODUCT NAME',
			field: 'name',
			sortable: true,
			classKey: '',
		},
		{ name: 'CATEGORY', field: 'category_name', sortable: true, classKey: '' },

		// { name: 'PRICE', field: 'rate', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'category_name'];

	//get all  product detail list
	useEffect(() => {
		window.scroll(0, 0);
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('product-details', options)
			.then((productdata) => {
				const productDetailData = productdata?.data?.data?.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/productdetail/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() =>{
									setId(value.id)
									setConfirm(true)
								}}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					value['image'] = process.env.REACT_APP_COMMON_FILE_URL + 'product-details/' + value.product_images[0]?.image;
					return value;
				});

				setCategoryState(productDetailData);
			})
			.catch((error) => {
			})
	}, []);

	//alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	//  product detail delete function
	const productDetailDeleteHandler = async () => {
		axiosPrivate
			.post(`product-details/${id}?_method=DELETE`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setCategoryState([]);
					setAlertMessage({ message: 'Product Detail deleted successfully' });
					await refreshTable();
				}
			})
	};

	
	//any  product delete after refresh table
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('product-details', options)
				.then((productdata) => {
					const productDetailData = productdata?.data?.data?.map(
						(value, key) => {
							let buttons = [];
							if (true)
								buttons.push(
									<button
										key="editButton##1"
										type="button"
										className="btn btn-icon"
										title="Edit"
									>
										<BiEdit style={{ color: 'green' }} size={18} />
									</button>
								);
							if (true)
								buttons.push(
									<button
										key="deleteButton##1"
										type="button"
										data-id={value.id}
										onClick={() =>{
											setId(value.id)
											setConfirm(true)
										}}
										className="btn btn-icon js-sweetalert"
										title="Delete"
									>
										<RiDeleteBinLine style={{ color: 'red' }} size={17} />
									</button>
								);
							value['action'] = buttons.length > 0 ? buttons : '-';
							value['image'] = process.env.REACT_APP_COMMON_FILE_URL + 'product-details/' + value.product_images[0]?.image;
							return value;
						}
					);
					setCategoryState(productDetailData);
					resolve(true);
				})
		});
	};

	return (
		<>
		{
			confirm && <DeleteConfirm deleteMethod = {productDetailDeleteHandler} setConfirm = {setConfirm} text = {"By clicking 'Yes', this product detail will be permanently deleted."} success = {"Product detail has been deleted."} cancel={"Product detail deletion cancelled."}/>
		}
			<div className="section-body">
				<div className="container-fluid">
					<div className="mx-4">
						<div className="d-flex justify-content-between align-items-center ">
							{/* hearder breadcrumb */}
							<div className="card-header">
								<strong className="top-left-heading">Product Details</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link to="/admin/dashboard" className="route-heading item mx-2">
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">Product Details</li>
								</ol>
							</div>

							<div className="header-action">
								<Link
									to="/admin/productdetail/add"
									className="text-decoration-none"
								>
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</Link>
							</div>
						</div>
					</div>

					<div className="section-body">
						<div className="container-fluid">
							<div className="tab-content">
								<div
									className="tab-pane fade show active"
									id="category-list"
									role="tabpanel"
								>
									<div className="card">
										<div className="card-body">
											<div className="table-responsive">
												{
													<DataTable2
														lists={categoryState}
														headers={headers}
														searchItems={searchItems}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductDetails;
