import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axiosPrivate from "../../../hooks/axiosPrivate";

const ColumnChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const { id } = userDetails;

        const response = await axiosPrivate.get(
          `sales-target/get/area-manager/${id}`
        );
        setData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchChartData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const targetAmounts = data.map((entry) => parseFloat(entry.target_amount));
  const achievedAmounts = data.map((entry) => parseFloat(entry.sale));

  const months = data.map((entry) =>
    new Date(entry.target_date).toLocaleDateString("en-US", { month: "short" })
  );

  const series = [
    {
      name: "Sales Target Amount",
      data: targetAmounts,
    },
    {
      name: "Achieved Target Amount",
      data: achievedAmounts,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      markers: {
        fillColors: ["#004583", "#6f6593"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: months,
    },
    yaxis: {
      title: {
        text: "Rupees",
      },
    },
    fill: {
      opacity: 1,
      colors: ["#004583", "#6f6593"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " rupees";
        },
      },
    },
  };

 
  

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height="350"
        />
      </div>
    </div>
  );
};

export default ColumnChart;
