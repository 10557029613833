import { useContext, useEffect, useState } from "react";
import { BiShow } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import MergeModal from "../../../context/components/Modals/MergeModel";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "./logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";

const Logistic = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { checkPermissionExists, authPermissions, userDetails } =
    useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [logistics, setLogisticsState] = useState([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // if (!authPermissions.includes("Logistic-List")) {
    //   navigate("/admin");
    // }
    if (userDetails.id != undefined) {
      axiosPrivate
        .get("logistics/list/items", {
          data: { current_user_id: userDetails.id },
        })
        .then((response) => {
          setLogisticsState(response?.data?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [authPermissions, userDetails, refresh]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion-custome");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active-custome");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, [logistics]);

  //State For selected items start
  const [selectedItems, setSelectedItems] = useState([]);
  const [mergerButton, setMergerButton] = useState(false);
  //State for selected items end

  //State For Merge Modal start
  const [showModal, setShowModal] = useState(false);
  //State For Merge Modal end

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //Function to handle and add selected id start
  const selectItems = async (event, id) => {
    let oldLogisticSelectedIds = new Set(selectedItems);
    let selectedItemsArray = selectedItems;
    if (event.target.checked && oldLogisticSelectedIds.has(id) != true) {
      selectedItemsArray.push(id);
      setSelectedItems(selectedItemsArray);
    } else {
      let index = selectedItemsArray.indexOf(id);
      if (index > -1) {
        selectedItemsArray.splice(index, 1);
        setSelectedItems(selectedItemsArray);
        event.target.checked = false;
      }
    }
    if (selectedItemsArray.length > 0) {
      setMergerButton(true);
    } else {
      setMergerButton(false);
    }
    return true;
  };
  //Function to handle and add selected id end

  //Function to remove the selected items start
  const removeSelectedItems = () => {
    //Loop and remove checked items start
    document.querySelectorAll(".select-merge").forEach(function (checkBox) {
      checkBox.checked = false;
    });
    //Loop and remove checked items end

    setSelectedItems([]);
    setMergerButton(false);
    return true;
  };
  //Function to remove the selected items end

  //Merge Open Modal function start
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };
  //Merge Open Modal function end

  //Merge Close Modal function start
  const closeModal = () => {
    setShowModal(false);
    setRefresh(true);
  };
  //Merge Close Modal function end

  //Function to handle modal form submission start
  const handleConfirm = async () => {
    closeModal();
    removeSelectedItems();

    // Display a success toast message
    toast.success("Merge operation successful!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    navigate(`/admin/logistics`);
    setRefresh(true);
    return true;
  };
  //Function to handle modal form submission end

  var acc = document.getElementsByClassName("accordion-custome");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active-custome");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Logistics</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Logistics</li>
            </ol>
          </div>

          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <MergeModal
                isOpen={showModal}
                onConfirm={handleConfirm}
                onCancel={closeModal}
                orderItemsIds={selectedItems}
              />
              <div className="card my-0 py-0">
                {mergerButton && authPermissions.includes("Logistic-Create") ? (
                  <div className="card-header">
                    <div className="col-md-6 d-flex flex-row justify-content-end">
                      <button
                        className={`btn btn-sm btn-round btn-primary`}
                        onClick={(event) => openModal(event)}
                      >
                        Merge
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-round btn-default1 ml-2"
                        onClick={(event) => {
                          removeSelectedItems();
                        }}
                      >
                        Clear Selected
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="card-body">
                  {logistics?.length > 0
                    ? logistics.map((logistic, index) => {
                        return logistic.group_items.length > 0 ? (
                          <div
                            key={`${logistic.group_name}-${index}`}
                            style={{ padding: "0px 0px 6px 0px" }}
                          >
                            <button className="accordion-custome">
                              <div className="d-flex">
                                <span className="mr-auto p-2">
                                  Dealers Location {logistic.group_name}
                                </span>
                                {authPermissions.includes("Logistic-Create") ? (
                                  <button
                                    onClick={(event) => {
                                      event.preventDefault();
                                      navigate(
                                        `/admin/logistics/splite?slug=${logistic.group_name}`
                                      );
                                    }}
                                    className="btn btn-sm btn-primary px-2 mt-1 mx-2"
                                    style={{
                                      padding: "-0.8rem 0.5rem !important", height: "100%",
                                    }}
                                  >
                                    Add Dispatch
                                  </button>
                                ) : null}
                              </div>
                            </button>
                            <div className="panel">
                              <div className="flex">
                                {(() => {
                                  let htmlDataFirst = [];
                                  for (
                                    let i = 0;
                                    i < logistic.group_items.length;
                                    i++
                                  ) {
                                    htmlDataFirst.push(
                                      <div className="caret">
                                        <span style={{ display: "none" }}>
                                          {
                                            logistic.group_items[i].group
                                              .dealer_name
                                          }{" "}
                                          (Order No: #
                                          {
                                            logistic.group_items[i].group
                                              .order_no
                                          }
                                          )
                                        </span>
                                        <div>
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <td>
                                                  <b>
                                                    {
                                                      logistic.group_items[i]
                                                        .group.dealer_name
                                                    }{" "}
                                                  </b>
                                                  <b>
                                                    (Order No: #
                                                    {
                                                      logistic.group_items[i]
                                                        .group.order_no
                                                    }
                                                  </b>
                                                  )
                                                </td>

                                                <td>
                                                  <b>Product</b>
                                                </td>
                                                <td>
                                                  <b>Remaining Quantity</b>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {(() => {
                                                let htmlData = [];
                                                for (
                                                  let m = 0;
                                                  m <
                                                  logistic.group_items[i]
                                                    .order_items.length;
                                                  m++
                                                ) {
                                                  htmlData.push(
                                                    <tr>
                                                      {logistic.group_items[i]
                                                        .order_items[m]
                                                        .dispatch_type ==
                                                        null ||
                                                      logistic.group_items[i]
                                                        .order_items[m]
                                                        .dispatch_type ===
                                                        "splited" ? (
                                                        <>
                                                          <td></td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                selectItems(
                                                                  event,
                                                                  logistic
                                                                    .group_items[
                                                                    i
                                                                  ].order_items[
                                                                    m
                                                                  ]
                                                                    .order_item_id
                                                                )
                                                              }
                                                              className={`select-merge select-merge-checked-${logistic.group_items[i].order_items[m].order_item_id}`}
                                                              name="select-merger"
                                                              defaultChecked={
                                                                false
                                                              }
                                                            />
                                                            <span>
                                                              {" "}
                                                              {
                                                                logistic
                                                                  .group_items[
                                                                  i
                                                                ].order_items[m]
                                                                  .product_name
                                                              }
                                                            </span>
                                                          </td>
                                                          <td>
                                                            {
                                                              logistic
                                                                .group_items[i]
                                                                .order_items[m]
                                                                .remaining_quantity
                                                            }
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <td></td>
                                                          <td>
                                                            <span>
                                                              *{" "}
                                                              {
                                                                logistic
                                                                  .group_items[
                                                                  i
                                                                ].order_items[m]
                                                                  .product_name
                                                              }
                                                            </span>
                                                          </td>
                                                          <td>
                                                            $
                                                            {
                                                              logistic
                                                                .group_items[i]
                                                                .order_items[m]
                                                                .remaining_quantity
                                                            }
                                                          </td>
                                                        </>
                                                      )}
                                                    </tr>
                                                  );
                                                }
                                                return htmlData;
                                              })()}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return htmlDataFirst;
                                })()}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                    : "No data found"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logistic;
