import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

 function BlogDetail() {

    return (
        <>
            {/* Banner image */}
            <div className='about-container' id='about-us'>
                <div className='about-banner-outer-container'>
                    <div className='about-banner-inner-container'>
                        <h1 className='about-heading'>Blog</h1>
                        <p className='about-home-text'>Home/<span className='about-us-text'>Blog</span></p>
                    </div>
                </div>
            </div>

            {/* detail section */}
            <div className="blog-detail-page">
                {/* image */}
                <div className="blog-image">
                    <img src="../img/Rectangle 168.png" alt="blog" />
                </div>
                <div className="blog-detail">
                    <h2 className="blog-heading">Lorem ipsum dolor sit amet, sitelit.</h2>
                    <p className="blog-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </p>
                </div>

                <div>
                    <h2 className="blog-heading">Lorem ipsum dolor sit amet, sitelit.</h2>
                    <p className="blog-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    </p>
                </div>
                <div className="tag-container">
                    <h3 className="tag">Tags:</h3>
                    <div className="tagFields">
                        <input type="text" name="productTag1" placeholder="Your Product Tags" />
                        <input type="text" name="productTag1" placeholder="Your Product Tags" />
                    </div>
                </div>

                <div className="reply-container">
                    <h3 className="tag leave-reply">Leave a Reply</h3>
                    <p>Your email address will not be published. Required fields are marked *</p>

                    <form >
                        <div className='row row-cols-2 form-container g-0 justify-content-between'>
                            <div className="col-sm-6 col-12 contact_field">
                                <input type="text"  name='name' id='name' placeholder='Your Name*' className='form-input' />
                               
                            </div>
                            <div className="col-sm-6 col-12 contact_field">
                                <input type="text" name='mobile_no'  id='number' placeholder='Mobile Number' className='form-input' />
                              
                            </div>

                            <div className="col-12 contact_field">
                                <input type="text" name='apply_position' id='position' placeholder='Enter website' className='form-input' />
                              
                            </div>
                        </div>
                        <div className="col-12 contact_field">
                            <textarea  name='comment 'id='comment' rows="5" className='file-input' placeholder="Commnet"/>
                           
                        </div>
                        <div className='submit-btn-container col-12 commnent'>
                            <button type="submit" className='career-submit-btn'>Posr Comment</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default React.memo(BlogDetail);