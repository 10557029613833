import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import AuthContext from '../../../context/Auth/AuthContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import DeleteConfirm from '../../../delete-confirmation/DeleteConfirm';
const Category = () => {
	const { checkPermissionExists, authPermissions } = useContext(AuthContext);
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [categoryState, setCategoryState] = useState([]);
	const [confirm, setConfirm] = useState(false)
	const [id, setId] = useState("")

	const headers = [
		{ name: 'image', field: 'image', sortable: true, classKey: '' },
		{ name: 'CATEGORY NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'Slug', field: 'slug', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'slug'];
	const ImageLink = process.env.REACT_APP_COMMON_FILE_URL;

	//get all category list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate.get('categories', options).then((categories) => {
			const categoriesData = categories?.data?.data?.map((value, key) => {
				let buttons = [];
				// if (true && authPermissions.includes("Category-Edit"))
				buttons.push(
					<Link
						key="editButton##1"
						type="button"
						to={`/admin/category/edit/${value.id}`}
						className="btn btn-icon"
						title="Edit"
					>
						<BiEdit style={{ color: 'green' }} size={18} />
					</Link>
				);
				// if (true && authPermissions.includes("Category-Delete"))
				buttons.push(
					<button
						key="deleteButton##1"
						type="button"
						data-id={value.id}
						onClick={() =>{
							setId(value.id)
							setConfirm(true)
						}}
						className="btn btn-icon js-sweetalert"
						title="Delete"
					>
						<RiDeleteBinLine style={{ color: 'red' }} size={17} />
					</button>
				);

				value['action'] = buttons.length > 0 ? buttons : '-';
				value['image'] =
					ImageLink + 'categories/' + JSON.parse(value.image).file;
				// value["alt"] = JSON.parse(value.image).alt;
				return value;
			});

			setCategoryState(categoriesData);
		});
	}, []);

	//alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// category delete function
	const categoryDeleteHandler = async () => {
		axiosPrivate
			.post(`categories/${id}?_method=DELETE`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setCategoryState([]);
					setAlertMessage({ message: 'category deleted successfully' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Delete category Internal server error');
				}
			});
	};
	//any category delete after refresh table
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('categories', options)
				.then((categories) => {
					const categoriesData = categories?.data?.data?.map((value, key) => {
						let buttons = [];
						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<BiEdit style={{ color: 'green' }} size={18} />
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() =>{
										setId(value.id)
										setConfirm(true)
									}}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<RiDeleteBinLine style={{ color: 'red' }} size={17} />
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						value['image'] =
							ImageLink + 'categories/' + JSON.parse(value.image).file;
						return value;
					});
					setCategoryState(categoriesData);
					resolve(true);
				})
				.catch((error) => {
					reject(error);
					if (error.response) {
						//response status is an error code
						showErrorToast(
							'Delete Category After Listing Internal server error'
						);
					}
				});
		});
	};

	return (
		<>
		{
			confirm && <DeleteConfirm deleteMethod = {categoryDeleteHandler} setConfirm = {setConfirm} text = {"By clicking 'Yes', this category will be permanently deleted."} success = {"Category has been deleted."} cancel={"Category deletion cancelled."}/>
		}
			<div className="section-body ">
				<div className="container-fluid">
					<div className="mx-4">
						<div className="d-flex justify-content-between align-items-center ">
							{/* hearder breadcrumb */}
							<div className="card-header">
								<strong className="top-left-heading">Categories</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link to="/admin/dashboard" className="route-heading item mx-2">
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">
										Categories
									</li>
								</ol>
							</div>

							{/* right side add button */}
							<div className="header-action">
								{/* {authPermissions.includes("Category-Create") ? ( */}
								<Link to="/admin/category/add" className="text-decoration-none">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</Link>
								{/* ) : null} */}
							</div>
						</div>
					</div>
					<div className="section-body ">
						<div className="container-fluid">
							<div className="tab-content ">
								<div
									className="tab-pane fade show active"
									id="category-list"
									role="tabpanel"
								>
									<div className="card">
										<div className="card-body">
											<div className="table-responsive">
												{
													<DataTable2
														lists={categoryState}
														headers={headers}
														searchItems={searchItems}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Category;
