import React from "react";
import FailedImg from "../assets/images/payment_failed.png";
import { Link } from "react-router-dom";

const PaymentFailedPage = () => {
  return (
    <div className="text-center">
      <div>
        <img
          src={FailedImg}
          width="25%"
          className="img-fluid img-responsive img-rounded"
          alt="payment failed image"
        />
      </div>
      <div>
        <h2>Payment Failed</h2>  
        <p className="mx-5">
          The Payment was unsuccessful due to an abnormality . Please try again
          later or use another payment method .{" "}
        </p>
      </div>
      <Link to="/cart">
      <button className="btn btn-lg btn-primary">Go To Cart  !</button>
      </Link>
     
    </div>
  );
};

export default React.memo(PaymentFailedPage);
