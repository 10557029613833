import React from "react";
const EwayInvoice = () => {
    return (
      <div className="container">
        <div className="Eway-bill">
            <h5>e-Way Bill</h5>
            <div className="first_section">
                <div className="doc_info">
                    <label><span>Doc Name </span> <span><b>:  Tax Invoice - NM/23-24/166</b></span></label><br></br>
                    <label><span>Date </span> <span><b>:  31-May-23</b></span></label><br></br>
                    <label><span>IRN </span> <span><b>:  ef333333333333333</b></span></label><br></br>
                    <label><span>Ack No </span> <span><b>:  14554333333</b></span></label><br></br>
                    <label><span>Ack Date </span> <span><b>:  31-May-23</b></span></label>
                </div>
                <div className="doc_qrCode">
                    <h6>e-Way Bill</h6>
                    <img src="QR_code.webp" />
                </div>
            </div>

            <div className="second-section py-2">
                <p className="heading">1. e-Way Bill Details</p>
                <div className="second-sub-section mt-2">
                    <div>
                        <label><span>e-Way Bill No. </span> <b> : 7119384848</b></label><br></br>
                        <label><span>Generated By </span> <b> : 08AASSSSSS</b></label><br></br>
                        <label><span>Supply Type </span> <b> : Outward-Supply</b></label>
                    </div>

                    <div>
                        <label><span>Mode </span> <b> : 1-Road</b></label><br></br>
                        <label><span>Approx Distance </span> <b> : 1-Road</b></label><br></br>
                        <label><span>Transaction Type </span> <b> : 1-Road</b></label>
                    </div>

                    <div>
                        <label><span>Generated Date </span> <b> : 1-Road</b></label><br></br>
                        <label><span>Valid Upto </span> <b> : 1-Road</b></label><br></br>
                    </div>
                </div>
            </div>

            <div className="third-section py-2">
                <p className="heading">2. Address Details</p>
                <div>
                    <div>
                        <p className="mb-0 from-dispatch"><b>From</b></p>
                        <p className="mb-0">NM India Bio Private Limited</p>
                        <p className="mb-0">GSTIN : 08AWERRRR23</p>
                        <p className="mb-0">Rajasthan</p>
                    </div>
                    <div className="mt-3">
                        <p className="mb-0 from-dispatch"><b>Dispatch</b></p>
                        <p className="mb-0">NM India Bio Private Limited</p>
                        <p>Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur , Rajasthan
                    - 313003, INDIA.</p>
                    </div>
                </div>
                <div>
                    <div>
                        <p className="mb-0 from-dispatch"><b>From</b></p>
                        <p className="mb-0">NM India Bio Private Limited</p>
                        <p className="mb-0">GSTIN : 08AWERRRR23</p>
                        <p className="mb-0">Rajasthan</p>
                    </div>
                    <div className="mt-3">
                        <p className="mb-0 from-dispatch"><b>Dispatch</b></p>
                        <p className="mb-0">NM India Bio Private Limited</p>
                        <p>Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur , Rajasthan
                        - 313003, INDIA.</p>
                    </div>
                </div>
            </div> 
        </div>
      </div>
    );
  };
  
  export default React.memo(EwayInvoice);
  