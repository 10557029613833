import React, { useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import DeleteConfirm from '../../../delete-confirmation/DeleteConfirm';
const AboutTemplate = () => {
	const [AboutTemplateList, setAboutTemplateList] = useState([]);
	// const [refreshTable, setRefreshTable] = useState([]);
    const [keyCounter, setKeyCounter] = useState(0);
	const [confirm, setConfirm] = useState(false)
	const [id, setId] = useState("")

	const headers = [
		// { name: 'S No', field: '1', classKey: '' },
		{ name: 'Page Name', field: 'name', classKey: '' },
		{ name: 'Slug', field: 'slug', classKey: '' },
		{ name: 'Action', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'slug'];

	const onPageDelete = () => {
		axiosPrivate
			.post('pages/' + id + '?_method=DELETE')
			.then((res) => {
				setKeyCounter((prevCounter) => prevCounter + 1);
				showSuccessToast("Deletion successful");
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};

	useEffect(() => {
		axiosPrivate
			.get('/pages/get/template/about')
			.then((res) => {
				// showSuccessToast('Update successful');
				const getAllPages = res.data.data.map((value, key) => {
					let buttons = [];
					buttons.push(
						<Link
							key="editButton##1"
							type="button"
							to={`/admin/about-template/edit/${value.id}`}
							className="btn btn-icon"
							title="Edit"
						>
							<BiEdit style={{ color: 'green' }} size={18} />
						</Link>
					);
					buttons.push(
						<button
							key="deleteButton##1"
							type="button"
							data-id={value.id}
							onClick={() =>{
								setId(value.id)
								setConfirm(true)
							}}
							className="btn btn-icon js-sweetalert"
							title="Delete"
						>
							<RiDeleteBinLine style={{ color: 'red' }} size={17} />
						</button>
					);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setAboutTemplateList(getAllPages);
			})
			.catch((error) => { });
	}, [keyCounter]);

	return (
		<>
		{
			confirm && <DeleteConfirm deleteMethod = {onPageDelete} setConfirm = {setConfirm} text = {"By clicking 'Yes', this page will be permanently deleted."} success = {"Page has been deleted."} cancel={"Page deletion cancelled."}/>
		}
			<div className="section-body ">
				<div className="container-fluid">
					<div className="mx-4">
						<div className="d-flex justify-content-between align-items-center ">
							{/* hearder breadcrumb */}
							<div className="card-header">
								<strong className="top-left-heading"> About Template</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link
											to="/admin/dashboard"
											className="route-heading item mx-2"
										>
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">
										About Template
									</li>
								</ol>
							</div>
							{/* right side add button */}
							<div className="header-action">
								<Link
									to="/admin/about-template/add"
									className="text-decoration-none"
								>
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="tab-content">
							<div
								className="tab-pane fade show active"
								id="user-list"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											{
												<DataTable2
													keyCounter={keyCounter}
													lists={AboutTemplateList}
													headers={headers}
													searchItems={searchItems}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutTemplate;
