import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Piechart2 = ({ ageWiseData }) => {
  const [series, setSeries] = useState([0]);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: [`Rs. 0`],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#f9c500"],
      },
    },
    fill: {
      colors: ["#fccc55"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const userCredentials = JSON.parse(localStorage.getItem("userDetails"));
  const { name } = userCredentials;

  useEffect(() => {
    if (ageWiseData) {
      const username = name; // Get the username from credentials
      const userSpecificData = ageWiseData?.filter(
        (item) => item?.party_name === username
      );

      if (userSpecificData.length > 0) {
        let temp = 0;
        userSpecificData.forEach((item) => {
          temp += parseInt(item.due_amount);
        });
        setSeries([temp]);
        setTotalOutstanding(temp);
        console.log("Total outstanding", temp);
      }
    }
  }, [ageWiseData, name]);

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      labels: [`Rs. ${totalOutstanding}`],
    }));
  }, [totalOutstanding]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      width={380}
    />
  );
};

export default Piechart2;
