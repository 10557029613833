import { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { GrView } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
 

const Career = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [careerState, setCareerState] = useState([]);

	const headers = [
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'CONTACT NO.', field: 'mobile_no', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },

		{ name: 'RESUME', field: 'resume', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'mobile_no', 'email'];

	// get All  carrer api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('career', options)
			.then((career) => {
				const carrerData = career?.data?.data?.map((value, key) => {
					let buttons = [];
					const data =
						process.env.REACT_APP_COMMON_FILE_URL + 'career/' + value.resume;

					if (true)
						buttons.push(
							<a
								key={`downloadButton##${key}`}
								href={data}
								target="_blank"
								rel="noreferrer"
							>
								<FiDownload style={{ color: 'blue' }} size={18} />
							</a>
						);

					if (true)
						buttons.push(
							<Link
								key={`viewButton##${key}`}
								type="button"
								to={`/admin/career/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);

					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setCareerState(carrerData);
			})
 	}, []);

	// alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Careers</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Careers</li>
						</ol>
					</div>

					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="carrer-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={careerState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Career;
