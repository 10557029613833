import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import { Link } from 'react-router-dom';
import { FaGreaterThan } from 'react-icons/fa';
const MenuCreation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [parentPageCheck, setParentPageCheck] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [city, setCity] = useState(null);
  const [pages, setPages] = useState([]);
  const [menus, setMenus] = useState([]);

  const initialValues = {
    name: "",
    slug: "",
    main_menu:'',
    parent_id: "",
    sequence_no: "",
  };

  const validationSchema = {
    name: Yup.string().required("Menu name is required"),
    slug: Yup.string().required("Menu slug is required"),
    main_menu: Yup.string().required("Main menu is required"),
    parent_id: Yup.string().required("Parent page is required"),
    sequence_no: Yup.string().required("Sequence number is required"),
  };

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`/menus/${id}`)
        .then((res) => {
          setFieldValue("name", res?.data?.data.title);
          setFieldValue("slug", res?.data?.data.slug);
          setFieldValue("parent_id", res?.data?.data.parent_id);
          setFieldValue("sequence_no", res?.data?.data.sequence_no);
        })
        .catch((err) => showErrorToast(err));
    }
  }, [id]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        title: values.name,
        slug: values.slug,
        parent_id: values.parent_id ? values.parent_id : "",
        sequence_no: values.sequence_no,
      };

      if (!id) {
        axiosPrivate
          .post("menus/create", formData)
          .then((res) => {
            showSuccessToast("Successfully created");
            navigate("/admin/menu-listing");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0].msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      } else {
        axiosPrivate
          .put(`/menus/update/${id}`, formData)
          .then((res) => {
            showSuccessToast("Menu Updated");
            navigate("/admin/menu-listing");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0].msg);
            } else {
              showErrorToast(error?.response?.data?.errors.msg);
            }
          });
      }
    },
  });

  const onSelectedCity = (event) => {
    setFieldValue("slug", event);
    setSelectedCity(event);
  };

  const onSelectedPage = (event) => {
    setFieldValue("parent_id", event);
    setSelectedPage(event);
  };

  const onSetMenu = (event) => {
    
    setCity(event);
    if (event == "no") {
      setFieldValue("main_menu", event);
      setFieldValue("parent_id", "");
      setParentPageCheck(true);
    } else {
      setParentPageCheck(false);
      setFieldValue("parent_id", event);
      setFieldValue("main_menu", event);
    }
  };

  useEffect(() => {
    const getAllPages = () => {
      axiosPrivate
        .get("pages")
        .then((res) => {
          let array = [
            {
              name: "Product Listing",
              slug: "products",
            },
            {
              name: "Product Detail",
              slug: "productdetail",
            },
            {
              name: "Contact",
              slug: "contact-us",
            },
            {
              name: "Careers",
              slug: "careers",
            },
          ];
          let finalPages = [...res?.data?.data, ...array];
          setPages(finalPages);
        })
        .catch((error) => {});
    };

    const getAllMenus = () => {
      axiosPrivate
        .get("menus")
        .then((res) => {
          setMenus(res?.data?.data);
        })
        .catch((error) => {});
    };

    // if(id.id){
    const getPageById = () => {
      axiosPrivate
        .get("menus/" + id.id)
        .then((res) => {})
        .catch((error) => {});
    };
    // }

    getAllPages();
    getAllMenus();
    if (id) {
      getPageById();
    }
  }, []);


  const numberFilter = (event) => {
		const inputValue = event.target.value;
	
		const numericValue = inputValue.replace(/\D/g, "");
		setFieldValue(event.target.name, numericValue);
	  };

	  const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  setFieldValue(event.target.name, inputValue);
		}
	  };
  return (
    <>
      <div>
        <div className="section-body mt-3 website-template">
          <div className="container-fluid">
          <div className="card-header">
          {id ? (
                <>
                  <strong className="top-left-heading">
                    Edit Menu
                  </strong>

                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link
                        to="/admin/dashboard"
                        className="route-heading item mx-2"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan className="grater-than-route" />
                    </li>
                    <li className="route-heading item mx-2 mt-1">
                      Edit Menu
                    </li>
                  </ol>
                </>
              ) : (
                <>
                  <strong className="top-left-heading">
                    Add Menu
                  </strong>

                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link
                        to="/admin/dashboard"
                        className="route-heading item mx-2"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan className="grater-than-route" />
                    </li>
                    <li className="route-heading item mx-2 mt-1">
                      Add Menu
                    </li>
                  </ol>
                </>
              )}
          </div>
            <div className="tab-content mt-3">
              <form onSubmit={handleSubmit}>
                <div
                  className="tab-pane fade show active"
                  id="home-template"
                  role="tabpanel"
                >
                  <div className="card">
                    
                    <div className="card-body">
                      <div className="row bordered-row">
                        {/*  Tax Name */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Menu Name:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Menu Name"
                              onChange={nameFilter}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="invalidDataError">
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Slug:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <Dropdown
                              value={selectedCity}
                              name="slug"
                              onBlur={handleBlur}
                              onChange={(e) => onSelectedCity(e.value)}
                              options={pages}
                              optionLabel="name"
                              optionValue="slug"
                              placeholder="Select Page"
                              className="w-full md:w-14rem"
                              style={{marginBottom:'0px'}}
                            />
                            {touched.slug && errors.slug ? (
                              <div className="invalidDataError">
                                {errors.slug}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Main Menu:<span className="required">*</span>
                          </label>
                          <div className="field-radiobutton d-flex align-items-center">
                            <RadioButton
                              inputId="main_yes"
                              name="menucheck"
                              value="yes"
                              onChange={(e) => onSetMenu(e.value)}
                              checked={city === "yes"}
                            />
                            <label htmlFor="main_yes" className="m-0 mx-2">
                              Yes
                            </label>
                          </div>
                          <div className="field-radiobutton d-flex align-items-center">
                            <RadioButton
                              inputId="main_no"
                              name="menucheck"
                              value="no"
                              onChange={(e) => onSetMenu(e.value)}
                              checked={city === "no"}
                            />
                            <label htmlFor="main_no" className="m-0 mx-2">
                              No
                            </label>
                            
                          </div>
                          {touched.main_menu && errors.main_menu ? (
                              <div className="invalidDataError">
                                {errors.main_menu}
                              </div>
                            ) : null}
                        </div>
                        {parentPageCheck ? (
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <label>
                              Parent Page:<span className="required">*</span>
                            </label>
                            <div className="form-group">
                              <Dropdown
                                value={selectedPage}
                                name="parent_id"
                                onBlur={handleBlur}
                                onChange={(e) => onSelectedPage(e.value)}
                                options={menus}
                                optionLabel="title"
                                optionValue="parent_id"
                                placeholder="Select Page"
                                className="w-full md:w-14rem"
                                style={{marginBottom:'0px'}}
                              />
                              {touched.parent_id && errors.parent_id ? (
                                <div className="invalidDataError">
                                  {errors.parent_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Sequence No:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="sequence_no"
                              name="sequence_no"
                              type="text"
                              className="form-control"
                              placeholder="Sequence Number"
                              onChange={numberFilter}
                              onBlur={handleBlur}
                              value={values.sequence_no}
                            />
                            {touched.sequence_no && errors.sequence_no ? (
                              <div className="invalidDataError">
                                {errors.sequence_no}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 submit-btn">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/menu-listing")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuCreation;
