import { useFormik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Userprofile.module.css';

const validationRules = {
	password: Yup.string()
		.max(20, 'Password must not exceed 20 characters')
		.min(8, 'Password must exceed 8 characters')
		.required('Password is required'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
};

const Changepassword = (props) => {
	let { id } = useParams();

	//  password change formik
	const formik = useFormik({
		initialValues: {
			password: '',
			confirm_password: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),

		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			const data = {
				password: values.password,
				confirm_password: values.confirm_password,
			};
			axiosPrivate
				.post(`users/change-password/${id}`, data)
				.then((response) => {
					toast('Password updated successfully', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					});

					props.changePasswordClose();
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	const closeChangePassword = () => {
		props.changePasswordClose();
	};

	return (
		<>
			<div className="tab-content">
				<div
					className="tab-pane fade show active"
					id="user-add"
					role="tabpanel"
				>
					<div className="card">
						<div className="card-header" style={{ padding: '0 20px 10px 0px' }}>
							<strong>Change Password</strong>
						</div>
						<div className="">
							<form onSubmit={formik.handleSubmit} id="changpassword">
								<div className="row clearfix">
									{/* password */}
									<div className="col-lg-6 col-md-6 col-sm-12">
										<label>
											Password:<span className="required">*</span>
										</label>

										<div className="form-group">
											<input
												id="password"
												name="password"
												type="password"
												className="form-control"
												placeholder="Password *"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.password}
											/>
											{formik.touched.password && formik.errors.password ? (
												<div className={classes.invalidDataError}>
													{formik.errors.password}
												</div>
											) : null}
										</div>
									</div>

									{/* confirm password */}
									<div className="col-lg-6 col-md-6 col-sm-12">
										<label>
											Confirm Password:<span className="required">*</span>
										</label>

										<div className="form-group">
											<input
												id="confirm_password"
												name="confirm_password"
												type="confirm_password"
												className="form-control"
												placeholder="Confirm your password *"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.confirm_password}
											/>
											{formik.touched.confirm_password &&
											formik.errors.confirm_password ? (
												<div className={classes.invalidDataError}>
													{formik.errors.confirm_password}
												</div>
											) : null}
										</div>
									</div>

									{/* close and update button */}

									<div className="col-12">
										<label>
											<span className="required"></span>
										</label>

										<div className="form-group mt-2 submit-btn">
											<button
												type="button"
												className="btn btn-secondary"
												data-dismiss="modal"
												onClick={() => closeChangePassword()}
											>
												Close
											</button>
											<button type="submit" className="btn btn-primary ml-2">
												Update
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Changepassword;
