import React, { useEffect, useMemo, useState } from 'react';

const PaginationComponent = ({
	total = 0,
	itemsPerPage = 10,
	currentPage = 1,
	onPageChange,
}) => {
	const [totalPages, setTotalPages] = useState(0);
	const [showLastPage, setShowLastPage] = useState(false);
	const [showFirstPage, setShowFirstPage] = useState(false);

	useEffect(() => {
		if (total > 0 && itemsPerPage > 0)
			setTotalPages(Math.ceil(total / itemsPerPage));
	}, [total, itemsPerPage]);

	useEffect(() => {
		setShowLastPage(currentPage < totalPages - 1);
		setShowFirstPage(currentPage > 1);
	}, [currentPage, totalPages]);

	const paginationItems = useMemo(() => {
		const pages = [];
		const maxVisiblePages = 10;

		let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
		let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

		if (totalPages >= maxVisiblePages && endPage === totalPages) {
			startPage = Math.max(endPage - maxVisiblePages + 1, 1);
		}

		for (let i = startPage; i <= endPage; i++) {
			pages.push(
				<div key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
					{i}
				</div>
			);
		}


		return pages;
	}, [totalPages, currentPage]);

	if (totalPages === 0) return null;

	return (
		<>
			<div className="pagination-container">
				<div className="float-right d-flex mt-3">
					<nav aria-label="Page navigation example">
						<ul className="pagination">
							<li className={`page-item ${showFirstPage ? '' : 'd-none'}`}>
								<button className="page-link" onClick={() => onPageChange(1)}>
									First
								</button>
							</li>
							<li
								className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
							>
								<button
									className="page-link"
									onClick={() => onPageChange(currentPage > 1 ?  currentPage-1 : 1)}
									disabled={currentPage == 1}
								>
									Previous
								</button>
							</li>
							{paginationItems.map((page) =>(
                                  
								<li
									key={page.key}
									className={
										page.key == currentPage ? 'page-item active' : 'page-item'
									}
								>
									<button
										className="page-link"
										onClick={() => onPageChange(page.key)}
									>
										{page}
									</button>
								</li>
							))}
							<li className="page-item">
								<button
									className="page-link"
									onClick={() => onPageChange(currentPage != totalPages ? currentPage + 1 : totalPages)}
									disabled={currentPage == totalPages}
								>
									Next
								</button>
							</li>
							<li className={`page-item ${showLastPage ? '' : 'd-none'}`}>
								<button
									className="page-link"
									onClick={() => onPageChange(totalPages)}
								>
									Last
								</button>
							</li>
						</ul>
					</nav>
				</div>
				<div className="pagination-message">
					Showing {(currentPage - 1) * itemsPerPage + 1} -{' '}
					{Math.min(currentPage * itemsPerPage, total)} of {total} results.
				</div>
			</div>
		</>
	);
};

export default PaginationComponent;
