import React from 'react';
import { Link } from "react-router-dom";
import { HiLocationMarker } from 'react-icons/hi';
import { IoMdCall } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import logoImg from '../../assets/img/logo.png'

function NmIndia() {
    return (
        <div className='nm_header'>
        <div className=' nmindia-container'>
            {/* NM India logo */}
            <div className='logo-container'>
                <Link  to="/"><img src={logoImg} style={{cursor : "pointer"}} alt="NM India" className='logo-img' loading='lazy'/></Link>
            </div>
            {/* address & contacts*/}
            <div className=' address-container'>
                <div className=' d-flex align-items-center nm-india-address'>
                    <Link to='/contact-us'>
                    <HiLocationMarker className='icon-color fs-1 mx-3 mx-sm-2' style={{fontSize : '30px'}} />
                    </Link>
                    <p className='font-size'>
                        Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur - 313003, Rajasthan, INDIA.</p>
                </div>

                <div className=' d-flex flex-column justify-content-center nm-india-address '>
                    <div className='order-margin'>
                        <a  href="tel:18008919981">
                        <IoMdCall className='icon-color' />
                        </a>
                        <p className=' font-size '>Order on Toll-free no:<a href="tel:18008919981"><span className='icon-color toll-free-no'>18008919981</span> </a></p>
                       
                    </div>
                    <a href="mailto:info@nmindiabio.com">
                    <div className='d-flex'>
                        
                        <MdEmail className='icon-color' />
                        <p className=' font-size'>info@nmindiabio.com</p> 
                        
                    </div>
                    </a>
                </div>
            </div>
        </div>
        </div>
    )
}

export default React.memo(NmIndia) //NmIndia