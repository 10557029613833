import React from "react";
const TaxInvoice = () => {
  return (
    <div className="container">
      <div className="tax-invoice">
        <div className="header-invoice mt-4 mb-2">
          <h6 className="text-center tax-heading">Tax Invoice</h6>
          <div className="header-in">
            <div style={{ width: "5%" }}>
              <img src="favicon-32x32.png" />
            </div>
            <div style={{ width: "53.5%" }}>
              <h3>NM India Bio Private Limited</h3>
              <p className="mb-0">
                <b>
                  Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur , Rajasthan
                  - 313003, INDIA.
                </b>
              </p>
              <p className="mb-0 d-flex">
                <span>9829355044</span>{" "}
                <span className="mx-3">bhanuprakash@nmidiabio.com</span>
              </p>
            </div>
          </div>
          <div className="invoice-bill mt-3">
            <div className="invoice-bill-content">
              <div className="invoice-date">
                <span>INVOICE</span>
                <br></br>
                <span>NM/23-24/166</span>
                <br></br>
                <span>31-MAY-2023</span>
                <br></br>
                <span>ewb:711342982366</span>
              </div>
              <div className="invoice-person-detail">
                <span>Bill to</span>
                <h5>
                  <b>Suresh Trader</b>
                </h5>
                <span>Dealer Code : GJ/JND01/3002</span>
                <br></br>
                <span>
                  0 , Panjara Pol Mangarol Road , Keshod , Junagadh , Gujarat ,
                  362220
                </span>
                <br></br>
                <span>GSTIN : 24ACQFS3953A1Z4 (24) Gujarat</span>
              </div>
            </div>
            <div className="invoice-bill-qrcode">
              <img src="QR_code.webp" />
            </div>
          </div>

          <table className="mid-line-table">
            <tr>
              <th className="first-th">IRN</th>
              <th>eeeeeeer4i3eee5345i345345io345i3i45i354oi3</th>
              <th>184838383838383</th>
              <th>@ 31-May-23</th>
            </tr>
          </table>

          <table>
            <tr>
              <th></th>
              <th>Order</th>
              <th>Dispatch Doc</th>
              <th>Through</th>
              <th>LR/RR/BL</th>
              <th>Carrier</th>
            </tr>

            <tr>
              <td>#</td>
              <td>114</td>
              <td>NM/23-24/166</td>
              <td>Kismat Roadlines</td>
              <td>6522</td>
              <td></td>
            </tr>
            <tr>
              <td>@</td>
              <td>31-May-2023</td>
              <td></td>
              <td>Kismat Roadlines</td>
              <td className="date">31-May-2023 </td>
              <td>GJ11TT7029</td>
            </tr>
          </table>

          <table className="main-table">
            <thead>
              <tr>
                <th className="s_no">S.No</th>
                <th className="product_name_td">Description of Goods</th>
                <th className="hsn_td1">HSN</th>
                <th>Quantity</th>
                <th>Weight</th>
                <th>Carton</th>
                <th>Rate</th>
                <th>Per</th>
                <th>Disc. %</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td className="product_name_td">
                  Wonder Earth Phosphogympsum Granular 50Kg
                </td>
                <td>3100100</td>
                <td>300 Bag</td>
                <td>15 Mt</td>
                <td>-</td>
                <td>380.00</td>
                <td>Bag</td>
                <td>-</td>
                <td>1,14,000.00</td>
              </tr>
              <tr>
                <td></td>
                <td className="discount-td">
                  <b>Discount</b>
                </td>
                <td colSpan={8} className="td_discount_igst">
                  14000
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="discount-td">
                  <b>IGST</b>
                </td>
                <td colSpan={8} className="td_discount_igst">
                  5000
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="product_name_td">
                  <b>Total</b>
                </td>
                <td></td>
                <td>300 Bag</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>1,14,000.00</td>
              </tr>
            </tbody>
          </table>

          <table className="amount-chargeble">
            <tbody>
              <tr>
                <td colSpan={10}>
                  <div className="amount-words">
                    <p className="m-0 amount">
                      <b>Amount Chargeable (In words)</b>
                    </p>
                    <p className="mx-1 mb-0 eof text-right">
                      <b>E. & O.E</b>
                    </p>
                  </div>
                </td>
                <td colSpan={2} className="text-right">
                  <b>Freight Payable : 20,047</b>
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <p className="mb-0 amount-eng">
                    <b>INR One Lakh Five Thousand Only</b>
                  </p>
                </td>
                <td colSpan={2} className="text-right">
                  <b>Due Amount : 84 , 953.00</b>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="tax-amount">
            <thead>
              <tr>
                <td className="hsn_td2" rowSpan="2">
                  HSN/SAC
                </td>
                <td>Taxable Value</td>
                <td colSpan={2} className="text-center">
                  Integrated Tax
                </td>
                <td>Total Tax Amount</td>
              </tr>
              <tr>
                <td></td>
                <td>Rate</td>
                <td className="amount-integrate">Amount</td>
              </tr>
              <tr>
                <td>310100</td>
                <td>10,00,00</td>
                <td>5%</td>
                <td>5000.00</td>
                <td>5000.00</td>
              </tr>
              <tr>
                <td>
                  <b>Total</b>
                </td>
                <td>
                  <b>10,00,00</b>
                </td>
                <td></td>
                <td>
                  <b>5000.00</b>
                </td>
                <td>
                  <b>5000.00</b>
                </td>
              </tr>
            </thead>
          </table>
          <div className="pt-2 px-1 bank_details">
            <div className="d-flex justify-content-between">
              <span className="d-flex">
                Tax Amount (in Words) : <h5>INR Five Thousand Only</h5>
              </span>
              <span className="pr-5">
                <b>Scan to Pay</b>
              </span>
            </div>
            <div className="company_div">
              <div>
                <p className="mb-0">
                  <b>Company's Bank Details</b>
                </p>
                <p className="mb-0">Bank Name : Bank of Baroda (India)</p>
                <p className="mb-0">A/c No. - 2342342342432</p>
                <p className="mb-0">
                  Branch & IFSC Code : Udaipur Main Branch & BARDCEWE
                </p>
              </div>
              <div className="invoice-bill-qrcode">
                <img src="QR_code.webp" />
              </div>
            </div>
            <div className="d-flex align-end justify-content-between mb-3">
              <p className="mb-0">
                <b>Declaration :</b> <br></br>
                We Declare that this invoice shows the real price of the goods
                described and that all particular true and correct
              </p>
              <p className="mb-0">Authorized Signatory</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaxInvoice);
