import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from '../../../context/loader/LoaderContext';

const ReturnOrdersView = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    let { id } = useParams();
    const { setAlertMessage } = useContext(AlertContext);
    const navigate = useNavigate();
    const [getData, setGetData] = useState({});
    const [productDetail, setProductDetail] = useState([]);

    //   Get order by id
    useEffect(() => {
        axiosPrivate
            .get(`/return-orders/order/${id}`)
            .then((response) => {
                setProductDetail(response.data.data.order_items);
                if (response.data.code === 200) {
                    setGetData({ ...response.data.data[0] });
                }
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    showErrorToast("Internal server error");
                }
            });
    }, []);

    const onSubmit = (data) => {
        axiosPrivate
            .get(`/return-orders/update-status/${id}/${data.order_status}`)
            .then((res) => {
                navigate("/admin/return-order");
                showSuccessToast("Order Status Updated")
            })
            .catch((error) => {
                if (typeof error.response.data.errors == "array") {
                    showErrorToast(error.response.data.errors[0].msg);
                } else {
                    showErrorToast(error.response.data.errors.msg);
                }
            });
    };

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    {/* hearder breadcrumb */}
                    <div className="card-header">
                        <strong className="top-left-heading">Return Order View</strong>

                        <ol className="headerroutes breadcrumb m-0 bg-none ">
                            <li className="item color-gray">|</li>
                            <li className="item">
                                <Link to="/admin/dashboard" className="route-heading item mx-2">
                                    Dashboard
                                </Link>
                            </li>
                            <li className="item">
                                <FaGreaterThan className="grater-than-route" />
                            </li>
                            <li className="route-heading item mx-2 mt-1">
                                <Link to="/admin/returnorders">Return Order List </Link>
                            </li>
                            <li className="item">
                                <FaGreaterThan className="grater-than-route" />
                            </li>
                            <li className="route-heading item mx-2 mt-1">
                                Return Order View
                            </li>
                        </ol>
                    </div>

                    <div className="tab-content mt-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className="tab-pane fade show active"
                                id="user-add"
                                role="tabpanel"
                            >
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row bordered-row">
                                            {/* Order Id*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Order ID:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="order_id"
                                                        name="order_id"
                                                        type="order_id"
                                                        className="form-control"
                                                        placeholder="User Name"
                                                        value={getData.order_id}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* user Name*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    User Name:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="user_name"
                                                        name="user_name"
                                                        type="user_name"
                                                        className="form-control"
                                                        placeholder="User Name"
                                                        value={getData.user_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row bordered-row">
                                            {/* order status */}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Order Status:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <select
                                                        id="order_status"
                                                        name="order_status"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Order Status"
                                                        {...register("order_status", { required: true })}
                                                    >
                                                        <option>Pending</option>
                                                        <option>Return Approved</option>
                                                        <option>Exchange Initiated</option>
                                                        <option>Exchange Approved</option>
                                                        <option>Exchange Completed</option>
                                                        <option>Return/Exchange Rejected</option>
                                                        <option>Refund Completed Via Acc</option>
                                                        <option>Refund Completed Via Voucher</option>
                                                    </select>
                                                    {errors.order_status && (
                                                        <span style={{ color: "red" }}>
                                                            This field is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {/* payment status */}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Payment Status:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="payment_status"
                                                        name="payment_status"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.payment_status}
                                                        placeholder="payment Status"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Product Section*/}
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <div>
                                                <p
                                                    // className="card-title"
                                                    style={{ fontWeight: "700", fontSize: "16px" }}
                                                >
                                                    Product Details
                                                </p>
                                            </div>
                                        </div>

                                        {/* product listing detail map   */}
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product Name</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {productDetail.map((item, index) => {
                          return ( */}
                                                <>
                                                    <tr>
                                                        {/* product */}

                                                        <td>
                                                            <input
                                                                id="product_name"
                                                                name="product_name"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="product name"
                                                                value={getData.product_name}
                                                                readOnly
                                                            />
                                                        </td>
                                                        {/* Product Quantity */}

                                                        <td>
                                                            <input
                                                                id="quantity"
                                                                name="quantity"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Quantity"
                                                                value={getData.quantity}
                                                                readOnly
                                                            />
                                                        </td>
                                                        {/* Product  Amount */}

                                                        <td>
                                                            <input
                                                                id="price"
                                                                name="price"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Quantity"
                                                                value={getData.total_amount}
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                                {/* //   );
                        // })} */}
                                            </tbody>
                                        </table>

                                        {/*Billing Section*/}
                                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                                            <p
                                                className="my-3"
                                                style={{ fontWeight: "700", fontSize: "16px" }}
                                            >
                                                Billing Address
                                            </p>
                                        </div>
                                        {/* billing section fields */}
                                        <div className="row bordered-row">
                                            {/* Bill Name*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Name:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_name"
                                                        name="bill_name"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.user_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* Bill  Mobile*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Mobile No:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_mobile"
                                                        name="bill_mobile"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.billing_mobile}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* Bill  Address*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Address:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_address"
                                                        name="bill_address"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.billing_address}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* Bill pincode*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Pincode:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_pincode"
                                                        name="bill_pincode"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Bill  Pincode"
                                                        value={getData.billing_pincode}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* bill_state*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    State:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_state"
                                                        name="bill_state"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.billing_state}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  bill city*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    City:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="bill_city"
                                                        name="bill_city"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.billing_city}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*Shiipping Section*/}
                                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                                            <p
                                                className="my-3"
                                                style={{ fontWeight: "700", fontSize: "16px" }}
                                            >
                                                Shipping Address
                                            </p>
                                        </div>

                                        {/* shipping section fields */}
                                        <div className="row bordered-row">
                                            {/*  shipping name*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Name:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_name"
                                                        name="ship_name"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.shipping_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  shipping  mobile*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Mobile No:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_mobile"
                                                        name="ship_mobile"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.shipping_mobile}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  shipping address*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Address:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_address"
                                                        name="ship_address"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.shipping_address}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  shipping  pincode*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Pincode:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_pincode"
                                                        name="ship_pincode"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.shipping_pincode}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  shipping   State*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    State:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_state"
                                                        name="ship_state"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.shipping_state}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  shipping    city*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    City:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="ship_city"
                                                        name="ship_city"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.shipping_city}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            {/* shipping amount*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Amount:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="shipping_amount"
                                                        name="shipping_amount"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.amount}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* payment heading*/}
                                        <div className="col-lg-12 col-md-12 col-sm-12 ">
                                            <p
                                                className="my-3"
                                                style={{ fontWeight: "700", fontSize: "16px" }}
                                            >
                                                Payment Details
                                            </p>
                                        </div>

                                        {/*payment fields */}
                                        <div className="row ered-row">
                                            {/*    Payment Mode*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Payment Mode:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="payment_mode"
                                                        name="payment_mode"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.payment_mode}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*    Payment  Status*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Payment Status:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="payment_status"
                                                        name="payment_status"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.payment_status}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* Coupon id */}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Coupon:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="coupon_id"
                                                        name="coupon_id"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.coupon_name}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* GST Number
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    GST Number :<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="gst_no"
                                                        name="gst_no"
                                                        type="text"
                                                        className="form-control"
                                                        value={getData.gst_no}
                                                        readOnly
                                                    />
                                                </div>
                                            </div> */}

                                            {/* order amount*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Order Amount:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="order_amount"
                                                        name="order_amount"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.order_amount}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/* coupon_discount */}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Coupon Discount(%) :<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="coupon_discount"
                                                        name="coupon_discount"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.coupon_discount}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*  Discount amount*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Total Discount Amount:
                                                    <span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="discount_amount"
                                                        name="discount_amount"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.discount_amount}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            {/*   Total amount*/}
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label>
                                                    Total Amount:<span className="required">*</span>
                                                </label>

                                                <div className="form-group">
                                                    <input
                                                        id="total_amount"
                                                        name="total_amount"
                                                        type="number"
                                                        className="form-control"
                                                        value={getData.total_amount}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* update and close button */}
                                        <div className="col-12 submit-btn">
                                            <hr className="mt-4" />
                                            <div className="table-responsive">
                                                <table className="table table-striped"></table>
                                            </div>
                                            <button
                                                type="button"
                                                id="button_1"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                                onClick={() => navigate("/admin/return-order")}
                                            >
                                                Close
                                            </button>
                                            <button type="submit" id="button_2" className="btn btn-primary  ml-2">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReturnOrdersView;
