
import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';

function BlogAccordion({handleSearch, searchIcon}) {
    return (
        <div className='accordian-container'>
            <div className='accordian-search-container'>
                <input type="search" name="blog-search" id="blog-search" placeholder='Enter keyword' className='blog-search' onChange={(e)=>{handleSearch(e)}}/>
                <BiSearch className={`blog-search-icon ${searchIcon ? 'show' : 'hide'}`} />
            </div>
            <div className="card blog-accordion">
                <Accordion activeIndex={2}>
                    <AccordionTab header="Categories" className='acoordion-section'>
                        <ul className='blog-list'>
                            <Link to='/blogs'><li>Commercial Farming(4)</li></Link>
                            <Link to='/blogs'><li>Mixed Farming(3)</li></Link>
                            <Link to='/blogs'> <li>Plantation Farming(5)</li></Link>
                        </ul>
                    </AccordionTab>
                    <AccordionTab header="Recent posts" className='acoordion-section'>
                        <div className='blog-menu-detail'>
                            <div className='blog-menu-img'>
                                <img src="/img/blogs/blog-1.webp" alt="blog Image" srcset="" loading='lazy' />
                            </div>
                            <div className='blog-post'>
                                <p className='blog-date'>feb 01</p>
                                <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                            </div>
                        </div>

                        <hr />
                        <div className='blog-menu-detail'>
                            <div className='blog-menu-img'>
                                <img src="/img/blogs/blog-2.webp" alt="blog Image" srcset="" loading='lazy' />
                            </div>
                            <div className='blog-post'>
                                <p className='blog-date'>feb 01</p>
                                <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                            </div>
                        </div>

                        <hr />
                        <div className='blog-menu-detail'>
                            <div className='blog-menu-img'>
                                <img src="/img/blogs/blog-3.webp" alt="blog Image" srcset="" loading='lazy' />
                            </div>
                            <div className='blog-post'>
                                <p className='blog-date'>feb 01</p>
                                <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    )
}

export default React.memo(BlogAccordion);