import { useFormik } from 'formik';
import React, {
	Component,
	Fragment,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AlertContext from '../../context/Alert/AlertContext';
import axiosPrivate from '../../hooks/axiosPrivate';
import classes from './SignUp.module.css';


import slider1 from '../../assets/images/slider1.svg';
import slider2 from '../../assets/images/slider2.svg';
import slider3 from '../../assets/images/slider3.svg';
import AuthContext from '../../context/Auth/AuthContext';

const SignUp = () => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	const navigate = useNavigate();
	const { setAlertMessage } = useContext(AlertContext);
	const [formValue, setFormSetValue] = useState({
		name: '',
		number: '',
		email: '',
	});

	// const inputChange = (eve) => {
	// 	const { name, value } = eve.target;
	// 	setFormSetValue((prevState) => ({
	// 		...prevState,
	// 		[name]: value,
	// 	}));
	// };
	const validationRules = {
		name: Yup.string()
			.max(120, 'Name must not exceed 120 characters')
			.required('Name is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		number: Yup.string()
			.required('Mobile is required')
			.matches(phoneRegExp, 'Mobile number is not valid')
			.min(10, 'Mobile number must be of 10 digits')
			.max(10, 'Mobile number must be of 10 digits'),
	};

	// useEffect(() => {
	// 	if (user.token) {
	// 		navigate('/admin', { replace: true });
	// 	} else {
	// 		navigate('/signup', { replace: true });
	// 	}
	// }, [user]);

	const formik = useFormik({
		initialValues: {
			name: '',
			number: '',
			email: '',
		},
		// validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError}) => {
			// var values = {
			// 	name: formValue.name,
			// 	email: formValue.email,
			// 	number: formValue.number,
			// };

			axiosPrivate
				.post('register', formik.values)
				.then((response) => {
					setAlertMessage({ message: 'New user created successfully' });
					navigate('otp');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<Fragment>
			<div className="auth_left">
				<form onSubmit={formik.handleSubmit}>
					<div className="card">
						<div className="text-center mb-5">
							<Link className="header-brand" to="/">
								<img src="/favicons/favicon-32x32.png" />
							</Link>
						</div>
						<div className="card-body">
							<div className="form-label ">Create new account</div>

							<div className="form-group">
								<label className="form-label">Name</label>
								<input
									id="name"
									name="name"
									type="text"
									className="form-control"
									placeholder="Enter Your Name"
									onChange={formik.handleChange}
								// onChange={(eve) => inputChange(eve)}
								onBlur={formik.handleBlur}
								value={formik.values.name}
								/>
								{formik.touched.name && formik.errors.name ? (
									<div className={classes.invalidDataError}>
										{formik.errors.name}
									</div>
								) : null}
							</div>
							<div className="form-group">
								<label className="form-label">Mobile Number</label>
								<input
									id="number"
									name="number"
									type="number"
									className="form-control"
									placeholder="Enter Your Mobile Number"
									onChange={formik.handleChange}

								// onChange={(eve) => inputChange(eve)}
								value={formik.values.number}
								/>
								{formik.touched.number && formik.errors.number ? (
									<div className={classes.invalidDataError}>
										{formik.errors.number}
									</div>
								) : null}
							</div>

							<div className="form-group">
								<label className="form-label">Email Address</label>
								<input
									id="email"
									name="email"
									type="email"
									className="form-control"
									placeholder="Enter Your Email"
									onChange={formik.handleChange}
								// onChange={(eve) => inputChange(eve)}
								value={formik.values.email}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div className={classes.invalidDataError}>
										{formik.errors.email}
									</div>
								) : null}
							</div>
							<div className="form-group">
								<label className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input" />
									<span className="custom-control-label">
										Agree the <a href="#">terms and policy</a>
									</span>
								</label>
							</div>
							<div className="form-footer">
								{/* <Link to="/otp"> */}
								<button className="btn btn-primary btn-block" type="submit">
									Click new account
								</button>
								{/* </Link> */}
							</div>
						</div>
						<div className="text-center text-muted">
							Already have account? <Link to="/signin">Sign In</Link>
						</div>
					</div>
				</form>
			</div>
			<div className="auth_right">
				<div
					className="carousel slide"
					data-ride="carousel"
					data-interval={3000}
				>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img src={slider1} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Fully Responsive</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src={slider2} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Quality Code and Easy Customizability</h4>
								<p>
									There are many variations of passages of Lorem Ipsum
									available.
								</p>
							</div>
						</div>
						<div className="carousel-item">
							<img src={slider3} className="img-fluid" alt="login page" />
							<div className="px-4 mt-4">
								<h4>Cross Browser Compatibility</h4>
								<p>
									Overview We're a group of women who want to learn JavaScript.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SignUp;
