import { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import MergeModal from "../../../context/components/Modals/MergeModel";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "../Logistic/logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import "../Account/invoice.css";

const Dispatch = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [logistics, setLogisticsState] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!authPermissions.includes("Account-List")) {
    //   navigate("/admin/dashboard");
    // }
    setIsLoading(true);
    axiosPrivate
      .get("dispatch/list/items")
      .then((response) => {
        setLogisticsState(response?.data?.data);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [authPermissions]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion-custome");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active-custome");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }, [logistics]);

  //State For selected items start
  const [selectedItems, setSelectedItems] = useState([]);
  const [mergerButton, setMergerButton] = useState(false);
  //State for selected items end

  //State For Merge Modal start
  const [showModal, setShowModal] = useState(false);
  //State For Merge Modal end

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //Function to handle and add selected id start
  const selectItems = async (event, id) => {
    let oldLogisticSelectedIds = new Set(selectedItems);
    let selectedItemsArray = selectedItems;
    if (event.target.checked && oldLogisticSelectedIds.has(id) != true) {
      selectedItemsArray.push(id);
      setSelectedItems(selectedItemsArray);
    } else {
      let index = selectedItemsArray.indexOf(id);
      if (index > -1) {
        selectedItemsArray.splice(index, 1);
        setSelectedItems(selectedItemsArray);
        event.target.checked = false;
      }
    }
    if (selectedItemsArray.length > 0) {
      setMergerButton(true);
    } else {
      setMergerButton(false);
    }
    return true;
  };
  //Function to handle and add selected id end

  //Function to remove the selected items start
  const removeSelectedItems = () => {
    //Loop and remove checked items start
    document.querySelectorAll(".select-merge").forEach(function (checkBox) {
      checkBox.checked = false;
    });
    //Loop and remove checked items end

    setSelectedItems([]);
    setMergerButton(false);
    return true;
  };
  //Function to remove the selected items end

  //Merge Open Modal function start
  const openModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };
  //Merge Open Modal function end

  //Merge Close Modal function start
  const closeModal = () => {
    setShowModal(false);
  };
  //Merge Close Modal function end

  //Function to handle modal form submission start
  const handleConfirm = async () => {
    closeModal();
    removeSelectedItems();
    navigate(`/admin/logistics`);
    return true;
  };
  //Function to handle modal form submission end

  var acc = document.getElementsByClassName("accordion-custome");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active-custome");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

  /* Function to reset state of logistic after invoice genrate */
  const resetLogisticState = async () => {
    await axiosPrivate.get("dispatch/list/items").then((response) => {
      setLogisticsState(response?.data?.data);
    });
  };

  /* Function to handle dispatch button  */
  const handleDispatchButton = async (
    event,
    orderId,
    splitId = null,
    mergeId = null
  ) => {
    const currentLogedInUserId = JSON.parse(
      localStorage.getItem("userDetails")
    );
    let user_id = currentLogedInUserId.id;
    event.preventDefault();
    setIsLoading(true);
    axiosPrivate
      .post("dispatch/update-order-status", {
        orderId: orderId,
        splitId: splitId,
        mergeId: mergeId,
        status: "1",
        user_id: user_id,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setAlertMessage({ message: response.message });
          await resetLogisticState();
        }
        setIsLoading(false);
      })
      .catch(async (error) => {
        setIsLoading(false);
      });
  };

  /* Function to handle dispatch button  */
  const handleDeliverButton = async (
    event,
    orderId,
    splitId = null,
    mergeId = null
  ) => {
    const currentLogedInUserId = JSON.parse(
      localStorage.getItem("userDetails")
    );
    let user_id = currentLogedInUserId.id;
    event.preventDefault();
    setIsLoading(true);
    axiosPrivate
      .post("dispatch/deliever/update-order-status", {
        orderId: orderId,
        splitId: splitId,
        mergeId: mergeId,
        status: "1",
        user_id: user_id,
      })
      .then(async (response) => {
        if (response.status == 201) {
          setAlertMessage({ message: response.message });
          await resetLogisticState();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  const pdfJSX = (data) => {
    return (
      <>
        <div className="jumBotron invoice-container">
          <div className="header-invoice">
            <div>
              <div className="header-in d-flex align-items-center">
                <div style={{ width: "190px" }}>
                  <img src={data?.logo} alt="NM Logo" />
                </div>
                <div className="right-sec">
                  <h6 className="tax-heading">Tax Invoice</h6>
                  <h3 className="company_name">NM India Bio Private Limited</h3>
                  <div className="invoice-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur ,
                    Rajasthan - 313003, INDIA.
                  </div>
                  <div className="my-0 invoice-address ">
                    <span>9829355044</span>
                    <span className="mx-3">bhanuprakash@nmidiabio.com</span>
                  </div>
                  <div className="invoice-address">
                    <span>
                      GSTIN: <b>08AACCE7746A1ZT</b>
                    </span>
                    <span className="mx-3">
                      PAN: <b>AACCE7746A</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="invoice-bill mt-3">
                  <div className="invoice-bill-content">
                    <div className="invoice-date">
                      <span>INVOICE</span>
                      <br></br>
                      <span>{data?.vch_no}</span>
                      <br></br>
                      <span>{formatDate(data?.date)}</span>
                    </div>
                    <div className="invoice-person-detail">
                      <p style={{ marginBottom: "0" }}>Bill to</p>
                      <div className="tax-heading">{data?.tallyLedgerName}</div>
                      <span>Dealer Code : </span>
                      <br></br>
                      <span>
                        {data?.billing_address} , {data?.billing_city} ,{" "}
                        {data?.billing_state} , {data?.billing_zip}
                      </span>
                      <br></br>
                      <span>
                        GSTIN : {data?.gstin} (24) {data?.billing_state}
                      </span>
                    </div>
                  </div>
                  <div
                    className="invoice-person-detail"
                    style={{ borderLeft: "1px solid black" }}
                  >
                    <p style={{ marginBottom: "0" }}>Ship to</p>
                    <div className="tax-heading">{data?.tallyLedgerName}</div>
                    <span>Dealer Code : </span>
                    <br></br>
                    <span>
                      {data?.shipping_address} , {data?.shipping_city} ,{" "}
                      {data?.shipping_state} , {data?.shipping_zip}
                    </span>
                    <br></br>
                    <span>
                      GSTIN : {data?.gstin} (24) {data?.shipping_state}
                    </span>
                  </div>
                  <div className="invoice-bill-qrcode">
                    <img src="QR_code.webp" />
                  </div>
                </div>

                <table className="mid-line-table">
                  <tr>
                    <th className="first-th">IRN</th>
                    <th>eeeeeeer4i3eee5345i345345io345i3i45i354oi3</th>
                    <th>184838383838383</th>
                    <th>@ {formatDate(data?.date)}</th>
                  </tr>
                </table>

                <table>
                  <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Dispatch Doc</th>
                    <th>Through</th>
                    <th>LR/RR/BL</th>
                    <th>Carrier</th>
                  </tr>

                  <tr>
                    <td>#</td>
                    <td>{data?.orderNo}</td>
                    <td>{data?.vch_no}</td>
                    <td>{data?.transporterName}</td>
                    <td>6522</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>@</td>
                    <td>{formatDate(data?.date)}</td>
                    <td></td>
                    <td></td>
                    <td className="date">{formatDate(data?.date)} </td>
                    <td>{data?.carrierNumber}</td>
                  </tr>
                </table>

                <table className="main-table">
                  <thead>
                    <tr>
                      <th className="s_no">S.No</th>
                      <th className="product_name_td">Description of Goods</th>
                      <th className="hsn_td1">HSN</th>
                      <th>Quantity</th>
                      <th>Weight</th>
                      <th>Carton</th>
                      <th>Rate</th>
                      <th>Per</th>
                      <th>Disc. %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.products?.map((product, index) => (
                      <>
                        <tr className="border-0" key={index}>
                          <td>{index + 1}</td>
                          <td className="product_name_td">
                            {product.product_name}
                          </td>
                          <td>310100</td>
                          <td>{`${product.quantity} ${product.base_unit}`}</td>
                          <td>{`${product.weight} ${product.unit}`}</td>
                          <td>-</td>
                          <td>{`${product.rate}.00`}</td>
                          <td>{product.base_unit}</td>
                          <td>-</td>
                          <td>{`${product.item_amount}.00`}</td>
                        </tr>
                      </>
                    ))}
                    <tr className="border-0" style={{ height: "30px" }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>-</b>
                      </td>
                    </tr>

                    <tr className="border-0">
                      <td></td>
                      <td
                        className="d-flex justify-content-between"
                        style={{ paddingRight: "5px" }}
                      >
                        <b>
                          <i>Less</i>
                        </b>
                        <b>
                          <i>Discount</i>
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>(-)00.00</b>
                      </td>
                    </tr>

                    {data?.itemsTax?.cgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>CGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.itemsTax?.sgst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>SGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.total_tax / 2}</b>
                        </td>
                      </tr>
                    )}
                    {data?.itemsTax?.igst && (
                      <tr className="border-0">
                        <td></td>
                        <td
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "5px" }}
                        >
                          <b></b>
                          <b>
                            <i>IGST</i>
                          </b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{data?.itemsTax?.igst_amount}</b>
                        </td>
                      </tr>
                    )}

                    <tr style={{ height: "200px" }} className="border-0">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Amount</b>
                      </td>
                      <td></td>
                      <td>
                        <b>
                          {data?.quantity} {data?.baseUnit}
                        </b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <b>Rs. {data?.total}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="amount-chargeble">
                  <tbody>
                    <div
                      className="d-flex"
                      style={{
                        borderLeft: "1px solid #000",
                        borderRight: "1px solid #000",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid #000",
                          width: "80%",
                          padding: "0px 5px",
                        }}
                      >
                        <div className="d-flex justify-content-between amount-words">
                          <p className="m-0 amount">
                            <b>Amount Chargeable (In words)</b>
                          </p>
                          <p className="mx-1 mb-0 eof text-right">
                            <b>E. & O.E</b>
                          </p>
                        </div>
                        <div className="mb-0 amount-eng">
                          <b>INR {data?.totalInWords}</b>
                        </div>
                        <p></p>
                      </div>

                      <div style={{ padding: "0px 5px", margin: "auto" }}>
                        <div className="d-flex justify-content-between">
                          <b>Freight Payable :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                        <div className="d-flex justify-content-between">
                          <b>Due Amount :</b>
                          <b style={{ paddingLeft: "5px" }}> 00.00</b>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>

                <table className="tax-amount">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>HSN/SAC</th>
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Taxable Value
                      </th>
                      {data?.itemsTax?.cgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Central Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.itemsTax?.sgst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>State Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}

                      {data?.itemsTax?.igst && (
                        <th className="p-0" style={{ width: "15%" }}>
                          <div
                            className="text-center"
                            style={{ borderBottom: "1px solid #000" }}
                          >
                            <b style={{ fontWeight: "bold" }}>Integrated Tax</b>
                          </div>
                          <div className="d-flex justify-content-around">
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                                width: "53px",
                              }}
                            >
                              Rate
                            </b>
                            <b style={{ borderRight: "1px solid #000" }}></b>
                            <b
                              style={{
                                fontWeight: "bold",
                                padding: "0px 5px",
                              }}
                            >
                              Amount
                            </b>
                          </div>
                        </th>
                      )}
                      <th style={{ width: "15%", textAlign: "center" }}>
                        Total Tax Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>310100</td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.total - data?.total_tax}
                      </td>

                      {data?.itemsTax?.cgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.cgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.itemsTax?.sgst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.sgst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.total_tax / 2}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      {data?.itemsTax?.igst && (
                        <>
                          <td className="p-0" style={{ width: "15%" }}>
                            <div className="d-flex justify-content-around">
                              <div
                                style={{
                                  fontWeight: "none",
                                  padding: "0px 5px",
                                }}
                              >
                                {data?.itemsTax?.igst}%
                              </div>
                              <div
                                style={{ borderRight: "1px solid #000" }}
                              ></div>
                              <div style={{ padding: "0px 5px" }}>
                                {data?.itemsTax?.igst_amount}
                              </div>
                            </div>
                          </td>
                        </>
                      )}

                      <td style={{ width: "15%", textAlign: "center" }}>
                        {data?.total_tax}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pt-2 pl-1 bank_details d-flex justify-content-between border border-dark">
                  <div className="d-flex flex-column justify-content-between w-50 pr-4">
                    <span className="d-flex">
                      Tax Amount (in Words) :&nbsp;
                      <b>INR {data?.totalTaxInWords}</b>
                      {/* <!-- Enter amount in words  --> */}
                    </span>
                    <div>
                      <p className="mb-0 pt-2">
                        <p
                          className="mb-0"
                          style={{ textDecoration: "underline" }}
                        >
                          Declaration :
                        </p>
                        We Declare that this invoice shows the real price of the
                        goods described and that all particular true and
                        correct.
                      </p>
                    </div>
                  </div>
                  <div className="company_div w-50">
                    <div className="mt-0">
                      <p className="mb-0">Company's Bank Details</p>
                      <p className="mb-0">
                        Bank Name : <b>Bank of Baroda (India)</b>
                      </p>
                      <p className="mb-0">
                        A/c No. : <b>01370500000868</b>
                      </p>
                      <p className="mb-0">
                        Branch & IFSC Code :{" "}
                        <b>Udaipur Main Branch & BARB0UDAIPU</b>
                      </p>
                    </div>
                    <div
                      className="invoice-bill-qrcode qr-code border border-dark w-100 h-100 p-1"
                      style={{ borderBottom: "0px !important" }}
                    >
                      <div>
                        <div className="text-left">
                          <b>Scan to Pay</b>
                        </div>
                        <div className="mt-1">
                          <img
                            src={data?.qr}
                            style={{ width: "140px", height: "100px" }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-between mt-3">
                        <p style={{ fontWeight: "600" }}>
                          For{" "}
                          <span style={{ fontWeight: "bold" }}>
                            NM India Bio Private Limited
                          </span>{" "}
                        </p>
                        <p className="mb-0">Authorized Signatory</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border border-dark text-center p-2">
                  This is a computer generated Invoice
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const printHandler = async (values) => {
    const printElement = ReactDOMServer.renderToString(pdfJSX(values));
    html2pdf().from(printElement).save();
  };

  const generateInvoice = async (event, splitId, mergeId, orderId, orderNo) => {
    setIsLoading(true);
    event.preventDefault();
    await axiosPrivate
      .post("accounting/generate/invoice", {
        order_id: orderId,
        split_id: splitId,
        merge_id: mergeId,
        godown_id: "2f7a93e9-1fc4-11ee-9c9b-b02628e88184",
      })
      .then(async (response) => {
        setIsLoading(false);
        if (response.status == 201) {
          await resetLogisticState();
          await response?.data?.invoice?.forEach((item) => {
            let total_quantity = 0;

            // Extract quantities, convert to numbers, and sum them up
            total_quantity = item?.products?.reduce((acc, product) => {
              return acc + Number(product?.quantity);
            }, 0);
            item.quantity = total_quantity;
          });

          for (let i = 0; i < response.data.invoice.length; i++) {
            await printHandler(response?.data?.invoice[i]);
          }

          showSuccessToast("Invoice generated successfully");
        }
        if (response.status == 200) {
          showErrorToast(response?.data?.message);
        }

        if (response.status == 500) {
          setIsLoading(false);
          showErrorToast("Server Error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorToast(err);
      });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Dispatched</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Dispatched</li>
            </ol>
          </div>
          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <MergeModal
                isOpen={showModal}
                onConfirm={handleConfirm}
                onCancel={closeModal}
                orderItemsIds={selectedItems}
              />
              <div className="card">
                <div className="card-body">
                  {logistics.map((logistic, index) => {
                    return (logistic &&
                      logistic.splited_group_items.length > 0) ||
                      logistic.merged_group_items.length > 0 ? (
                      <div
                        key={`${logistic.group_name}-${index}`}
                        style={{ padding: "0px 0px 6px 0px" }}
                      >
                        <button className="accordion-custome">
                          <div className="d-flex">
                            <span className="mr-auto p-2">
                              Dealers Location {logistic.group_name}
                            </span>
                          </div>
                        </button>
                        <div className="panel1">
                          {/* Splite Items Table Start */}
                          {(() => {
                            return logistic.splited_group_items.length <=
                              0 ? null : (
                              <div className="flex">
                                <div className="caret">
                                  <div className="splited-items">
                                    <p className="account_heading">
                                      <strong>#Splited Orders</strong>
                                    </p>
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <td>
                                              <strong>Order No</strong>
                                            </td>
                                            <td>
                                              <strong>Dealer Name</strong>
                                            </td>
                                            <td>
                                              <strong>Order Date</strong>
                                            </td>

                                            <td>
                                              <strong>Status</strong>
                                            </td>
                                            <td>
                                              <strong>Actions</strong>
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(() => {
                                            let htmlDataFirst = [];
                                            for (
                                              let i = 0;
                                              i <
                                              logistic.splited_group_items
                                                .length;
                                              i++
                                            ) {
                                              htmlDataFirst.push(
                                                <tr
                                                  key={`${logistic.group_name}-${logistic.splited_group_items[i].name}`}
                                                >
                                                  {
                                                    <>
                                                      <td>
                                                        <span>
                                                          <Link
                                                            // className="btn btn-lg"
                                                            // style={{border:'none'}}
                                                            to={`/admin/dispatch/view/${logistic.splited_group_items[i].split_id}/${logistic.splited_group_items[i].order_id}`}
                                                          >
                                                            #
                                                            {
                                                              logistic
                                                                .splited_group_items[
                                                                i
                                                              ].order_no
                                                            }
                                                          </Link>
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].name
                                                          }
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {formatDate(
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ].order_date
                                                          )}
                                                        </span>
                                                      </td>

                                                      <td>
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                        0 ? (
                                                          <span className="tag tag-warning  ml-0 mr-0">
                                                            Pending
                                                          </span>
                                                        ) : null}
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                          1 &&
                                                        logistic
                                                          .splited_group_items[
                                                          i
                                                        ].delivered_status ==
                                                          0 ? (
                                                          <span
                                                            className="tag tag-success  ml-0 mr-0"
                                                            style={{
                                                              background:
                                                                "#e08b3d",
                                                              color: "white",
                                                            }}
                                                          >
                                                            Dispatched
                                                          </span>
                                                        ) : null}
                                                        {logistic
                                                          .splited_group_items[
                                                          i
                                                        ]?.dispatch_status ==
                                                          1 &&
                                                        logistic
                                                          .splited_group_items[
                                                          i
                                                        ].delivered_status ==
                                                          1 ? (
                                                          <span
                                                            className="tag tag-default  ml-0 mr-0"
                                                            style={{
                                                              background:
                                                                "#50ae31",
                                                              color: "white",
                                                            }}
                                                          >
                                                            Delivered
                                                          </span>
                                                        ) : null}
                                                      </td>
                                                      {(() => {
                                                        return (
                                                          <td
                                                            style={{
                                                              minWidth: "260px",
                                                            }}
                                                          >
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                            1 ? (
                                                              <button
                                                                onClick={(
                                                                  event
                                                                ) => {
                                                                  generateInvoice(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id,
                                                                    null,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_no
                                                                  );
                                                                }}
                                                                className="btn btn-default btn-sm ml-1 download-invoice"
                                                              >
                                                                {/* Download Invoice */}
                                                                Generate Invoice
                                                              </button>
                                                            ) : null}
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                            0 ? (
                                                              <button
                                                                className="btn btn-success ml-1 deliver-css"
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  handleDispatchButton(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id
                                                                  )
                                                                }
                                                              >
                                                                Dispatch
                                                              </button>
                                                            ) : null}
                                                            {logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              .delivered_status ==
                                                              0 &&
                                                            logistic
                                                              .splited_group_items[
                                                              i
                                                            ]
                                                              ?.dispatch_status ==
                                                              1 ? (
                                                              <button
                                                                className="btn btn-success ml-1 deliver-css"
                                                                onClick={(
                                                                  event
                                                                ) =>
                                                                  handleDeliverButton(
                                                                    event,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].order_id,
                                                                    logistic
                                                                      .splited_group_items[
                                                                      i
                                                                    ].split_id
                                                                  )
                                                                }
                                                              >
                                                                Delivered
                                                              </button>
                                                            ) : null}
                                                          </td>
                                                        );
                                                      })()}
                                                    </>
                                                  }
                                                </tr>
                                              );
                                            }
                                            return htmlDataFirst;
                                          })()}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })()}
                          {/* Splite Items Table end */}
                          {/* Merge Items Table Start */}
                          {logistic.merged_group_items.length > 0
                            ? (() => {
                                return (
                                  <div className="flex">
                                    <div className="caret">
                                      <div className="splited-items">
                                        <p className="account_heading">
                                          <strong>#Merged Orders</strong>
                                        </p>
                                        {(() => {
                                          return logistic.merged_group_items
                                            .length <= 0
                                            ? null
                                            : logistic.merged_group_items.map(
                                                (mergedItems, index) => {
                                                  return (
                                                    <>
                                                      {/* <div className="d-flex flex-row">
                      <div>
                        {index+1}.
                      </div>
                    </div> */}
                                                      <div className="table-responsive">
                                                        <table className="table dispatch-table">
                                                          <thead>
                                                            <tr>
                                                              <td>
                                                                <b>
                                                                  {" "}
                                                                  {index + 1} .
                                                                  Order No
                                                                </b>
                                                              </td>
                                                              <td>
                                                                <b>
                                                                  Dealer Name
                                                                </b>
                                                              </td>

                                                              <td>
                                                                <b>
                                                                  Order Date
                                                                </b>
                                                              </td>

                                                              <td>
                                                                <b>Status</b>
                                                              </td>
                                                              <td>
                                                                <b> Actions</b>
                                                              </td>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {(() => {
                                                              let htmlDataFirst =
                                                                [];
                                                              for (
                                                                let i = 0;
                                                                i <
                                                                mergedItems
                                                                  .group_items
                                                                  .length;
                                                                i++
                                                              ) {
                                                                htmlDataFirst.push(
                                                                  <tr
                                                                    key={`${mergedItems.group_items[i].name}`}
                                                                  >
                                                                    {
                                                                      <>
                                                                        <td>
                                                                          <span>
                                                                            <Link
                                                                              // className="btn "
                                                                              to={`/admin/dispatch/view/merge/${mergedItems.merge_id}`}
                                                                            >
                                                                              #
                                                                              {
                                                                                mergedItems
                                                                                  .group_items[
                                                                                  i
                                                                                ]
                                                                                  .order_no
                                                                              }
                                                                            </Link>
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .name
                                                                            }
                                                                          </span>
                                                                        </td>
                                                                        <td>
                                                                          <span>
                                                                            {formatDate(
                                                                              mergedItems
                                                                                .group_items[
                                                                                i
                                                                              ]
                                                                                .order_date
                                                                            )}
                                                                          </span>
                                                                        </td>

                                                                        <td>
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          0 ? (
                                                                            <span className="tag tag-warning  ml-0 mr-0">
                                                                              Pending
                                                                            </span>
                                                                          ) : null}
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            0 ? (
                                                                            <span
                                                                              className="tag tag-success  ml-0 mr-0"
                                                                              style={{
                                                                                background:
                                                                                  "#e08b3d",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              Dispatched
                                                                            </span>
                                                                          ) : null}

                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            1 ? (
                                                                            <span
                                                                              className="tag tag-default  ml-0 mr-0"
                                                                              style={{
                                                                                background:
                                                                                  "#50ae31",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              Delivered
                                                                            </span>
                                                                          ) : null}
                                                                        </td>
                                                                        <td>
                                                                          {/* {mergedItems
                                          .group_items[
                                          i
                                        ]
                                          .invoice_url !=
                                        undefined ? (
                                          <Link
                                            to={`${mergedItems.group_items[i].invoice_url}`}
                                            target="_blank"
                                            download
                                            className="btn btn-default btn-sm ml-1 download-invoice"
                                          >
                                            Download
                                            Invoice
                                          </Link>
                                        ) : (
                                          ""
                                        )} */}

                                                                          {mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          1 ? (
                                                                            <button
                                                                              onClick={(
                                                                                event
                                                                              ) => {
                                                                                generateInvoice(
                                                                                  event,
                                                                                  null,
                                                                                  mergedItems?.merge_id,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_id,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_no
                                                                                );
                                                                              }}
                                                                              className="btn btn-default btn-sm ml-1 download-invoice"
                                                                            >
                                                                              {/* Download Invoice */}
                                                                              Generate Invoice
                                                                            </button>
                                                                          ) : null}

                                                                          {mergedItems
                                                                            ?.group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                          0 ? (
                                                                            <button
                                                                              className="btn btn-success ml-1"
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                handleDispatchButton(
                                                                                  event,
                                                                                  mergedItems
                                                                                    ?.group_items[
                                                                                    i
                                                                                  ]
                                                                                    ?.order_id,
                                                                                  null,
                                                                                  mergedItems?.merge_id
                                                                                )
                                                                              }
                                                                            >
                                                                              Dispatch
                                                                            </button>
                                                                          ) : null}
                                                                          {mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            .delivered_status ==
                                                                            0 &&
                                                                          mergedItems
                                                                            .group_items[
                                                                            i
                                                                          ]
                                                                            ?.dispatch_status ==
                                                                            1 ? (
                                                                            <button
                                                                              className="btn btn-success ml-1"
                                                                              onClick={(
                                                                                event
                                                                              ) =>
                                                                                handleDeliverButton(
                                                                                  event,
                                                                                  mergedItems
                                                                                    .group_items[
                                                                                    i
                                                                                  ]
                                                                                    .order_id,
                                                                                  null,
                                                                                  mergedItems.merge_id
                                                                                )
                                                                              }
                                                                            >
                                                                              Delivered
                                                                            </button>
                                                                          ) : null}
                                                                        </td>
                                                                      </>
                                                                    }
                                                                  </tr>
                                                                );
                                                              }
                                                              return htmlDataFirst;
                                                            })()}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()
                            : null}
                          {/* Merge Items Table end */}
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dispatch;
