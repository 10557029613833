import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Coupon = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [couponState, setCouponState] = useState([]);

	// header
	const headers = [
		{
			name: 'COUPON NAME',
			field: 'name',
			sortable: true,
			classKey: '',
		},

		{
			name: 'COUPON CODE',
			field: 'coupon_code',
			sortable: true,
			classKey: '',
		},
		{ name: 'COUPON TYPE', field: 'coupon_type', sortable: true, classKey: '' },

		{
			name: 'COUPON COUNT ',
			field: 'coupon_usage',
			sortable: true,
			classKey: '',
		},

		{
			name: 'USED COUPON ',
			field: 'used_coupon_count',
			sortable: true,
			classKey: '',
		},

		{
			name: 'STATUS',
			field: 'is_active',
			sortable: true,
			classKey: '',
		},

		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	// search item
	const searchItems = ['name', 'coupon_type'];

	//  coupons data list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('coupons', options)
			.then((coupons) => {
				const couponData = coupons?.data?.data?.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/coupon/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => couponDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';

					return value;
				});

				setCouponState(couponData);
			}).catch((error) => {
			})
 	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function
	const couponDeleteHandler = async (id) => {
		axiosPrivate
			.post(`coupons/${id}?_method=DELETE`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setCouponState([]);
					setAlertMessage({ message: 'Coupon  Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};

	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate.get('coupons', options).then((coupons) => {
				const couponData = coupons?.data?.data?.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<button
								key="editButton##1"
								type="button"
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</button>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => couponDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setCouponState(couponData);
				resolve(true);
			});
		});
	};

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					<div className="mx-4">
						<div className="d-flex justify-content-between align-items-center ">
							{/* hearder breadcrumb */}
							<div className="card-header">
								<strong className="top-left-heading">Coupons</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link
											to="/admin/dashboard"
											className="route-heading item mx-2"
										>
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">Coupons</li>
								</ol>
							</div>

							<div className="header-action">
								<Link to="/admin/coupon/add" className="text-decoration-none">
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<i className="fe fe-plus mr-2" />
										Add
									</button>
								</Link>
							</div>
						</div>
					</div>

					<div className="section-body">
						<div className="container-fluid">
							<div className="tab-content">
								<div
									className="tab-pane fade show active"
									id="user-list"
									role="tabpanel"
								>
									<div className="card">
										<div className="card-body">
											<div className="table-responsive">
												{
													<DataTable2
														lists={couponState}
														headers={headers}
														searchItems={searchItems}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Coupon;
