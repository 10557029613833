import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';

// validation
const validationRules = {
	site_name: Yup.string()
		.min(4, 'Name must have 4 characters atleast')
		.max(120, 'Name must not exceed 120 characters')
		.required('Site name is required'),
	site_email: Yup.string()
		.max(120, 'Email must not exceed 220 characters')
		.required('Site email is required')
		.email('Invalid email address'),
};
export default function EditSettings() {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});
	const [initialValues, setInitialValues] = useState({
		site_name: '',
		site_email: '',
	});

	// setting get by id
	useEffect(() => {
		axiosPrivate.get(`settings/get/site/information`).then((response) => {
			if (response.data.code == 200) {
				setGetData(...response.data.data);
			}
		});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var data = [
				{
					site_name: values.site_name,
					site_email: values.site_email,
				},
			];

			axiosPrivate
				.post(`settings/update?_method=PATCH`, data)
				.then((response) => {
					setAlertMessage({ message: 'Setting Updated Successfully' });
					navigate('/admin/settings');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	return (
		<div className="section-body ">
			<div className="container-fluid">
				<div className="tab-content ">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading"> Edit Setting</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/settings"> Setting</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1"> Edit Setting</li>
						</ol>
					</div>

					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="site_name"
													name="site_name"
													type="text"
													className="form-control"
													placeholder="Site Name *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.site_name}
												/>
												{formik.touched.site_name && formik.errors.site_name ? (
													<div className="error">{formik.errors.site_name}</div>
												) : null}
											</div>
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="site_email"
													name="site_email"
													type="email"
													className="form-control"
													placeholder="Site Email *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.site_email}
												/>
												{formik.touched.site_email &&
												formik.errors.site_email ? (
													<div className="error">
														{formik.errors.site_email}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12 submit-btn">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button
												type="button"
												className="btn btn-secondary"
												data-dismiss="modal"
												onClick={() => navigate('/admin/settings')}
											>
												Cancel
											</button>
											<button type="submit" className="btn btn-primary ml-2">
												Update
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
