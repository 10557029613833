import { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Orders = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [orderState, setOrderState] = useState([]);

	// headrs
	const headers = [
		{ name: 'ORDER ID', field: 'order_id', sortable: true, classKey: '' },

		{
			name: 'CUSTOMER NAME',
			field: 'user_name',
			sortable: true,
			classKey: '',
		},
		{
			name: 'ORDER STATUS',
			field: 'order_status',
			sortable: true,
			classKey: '',
		},

		{
			name: 'PAYMENT STATUS',
			field: 'payment_status',
			sortable: true,
			classKey: '',
		},

		{
			name: 'CONTACT NO.',
			field: 'ship_mobile',
			sortable: true,
			classKey: '',
		},
		{
			name: 'TOTAL AMOUNT',
			field: 'total_amount',
			sortable: true,
			classKey: '',
		},

		{
			name: 'ORDER DATE',
			field: 'order_date',
			sortable: true,
			classKey: '',
		},

		// { name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['new_order_id', 'user_name', 'order_status', 'payment_status', 'order_date', 'ship_mobile', 'total_amount'];

	// get All  ordrs lits api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('ecom/orders', options)
			.then((order) => {
				const orderData = order?.data?.data?.map((value, key) => {
					
					
					const formattedDate = new Date(value.order_date).toLocaleDateString(
						'en-US',
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						}
					);

					return {
						...value,
						order_date: formattedDate,

						order_id: (
							<Link
								to={`view/${value.id}`}
								style={{ textDecoration: 'underline' }}
							>
								{value.order_id}
							</Link>
						),
						new_order_id: value.order_id,
						total_amount: JSON.stringify(value.total_amount)
					};
				});

				setOrderState(orderData);
			}).catch((err)=>{
			})
 	}, []);

	// alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Orders</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Orders</li>
						</ol>
					</div>

					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="orders-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={orderState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Orders;
