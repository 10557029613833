import React, { useEffect, useState } from "react";
import UserData from "./UserData";
import { FaDiceD6, FaUser, FaShoppingCart } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { BiLogOut } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardPeronalDetail from "./DashboardPeronalDetail";
import DashboardOrders from "./DashboardOrders";
import axiosPrivate from "../../hooks/axiosPrivate";
import { string } from "yup";

function Dashboard() {
  const [activeLink, setActiveLink] = useState("Dashboard");
  const [addresses, setUserAddresses] = useState([]);
  const [orders, setUserOrders] = useState([]);
  const [returnOrders, setReturnOrders] = useState([])
  const location = useLocation();
  const navigate = useNavigate();


  const showActive = (string) => {
    setActiveLink(string);
  };

  useEffect(()=>{
 setActiveLink("Dashboard")
  },[])

  if (localStorage.getItem("userDetails")) {
    var { name, id } = JSON.parse(localStorage.getItem("userDetails"))
  }

  const handleDataFromChild = (height) => {
    const sideBar = document.querySelector(".side_bar");
    if (sideBar) {
      sideBar.style.height = height;
    }
  };

 

  useEffect(() => {
    if (location?.pathname === "/user/dashboard") {
    }

    axiosPrivate
      .get(`users/get/addresses/${id}`)
      .then((res) => {
        setUserAddresses(res?.data?.data);
      })
      .catch((error) => {
        setUserAddresses([]);
      });

    axiosPrivate
      .get("/ecom/orders/get")
      .then((res) => {
        setUserOrders(res?.data?.data);
      })
      .catch((error) => {
        setUserOrders([]);
      });

    // return orders

    axiosPrivate
      .get(`return-orders/${id}`)
      .then((res) => {
        setReturnOrders(res?.data?.data);
      })
      .catch((error) => {
        setReturnOrders([])
      });
  }, []);



  const clearAuthData = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userRefreshToken");
    navigate("/");
  };

  return (
    <div className="dashboard_container">
      <div className={`side_bar`}>
        <ul className="menu_list">
          <Link
            to="/user/dashboard"
            onClick={() => {
              showActive("Dashboard");
            }}
          >
            {" "}
            <li
              className={`menu_item ${activeLink == "Dashboard" ? "active_link" : ""
                }`}
            >
              <FaDiceD6 className="icons" />
              <p>Dashboard</p>
            </li>
          </Link>

          <Link
            to="/user/dashboard"
            onClick={() => {
              showActive("Personal Details");
            }}
          >
            {" "}
            <li
              className={`menu_item ${activeLink == "Personal Details" ? "active_link" : ""
                }`}
            >
              <FaUser className="icons" />
              <p>Personal Details</p>
            </li>
          </Link>

          <Link
            to="/user/dashboard"
            onClick={() => {
              showActive("My orders");
            }}
          >
            {" "}
            <li
              className={`menu_item ${activeLink == "My orders" ? "active_link" : ""
                }`}
            >
              <FaShoppingCart className="icons" />
              <p>My orders</p>
            </li>
          </Link>

          <Link to="/">
            {" "}
            <li className="menu_item" onClick={clearAuthData}>
              <BiLogOut className="icons" />
              <p>Log out</p>
            </li>
          </Link>
        </ul>
      </div>

      <div id="user_data" className="user_data_container">
        {activeLink == "Dashboard"  && (
          <UserData onData={handleDataFromChild} addresses={addresses} orders={orders} name={name} onViewAllOrders={showActive} />
        )}
        {activeLink == "Personal Details" && (
          <DashboardPeronalDetail addresses={addresses} />
        )}
        {activeLink == "My orders" && <DashboardOrders orders={orders} name={name} returnOrders={returnOrders}/>}
      </div>
    </div>
  );
}

export default React.memo(Dashboard);
