import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addUserStyle.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
var validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  role: Yup.string().required("Role is required"),

  email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email!"
      )
      .required("Email is required"),
  
    mobile: Yup.string().test('starts-not-with-0-to-5', 'Primary mobile number is not valid', function(value) {
     
      if (!value) return true; 
      return !/^[0-5]/.test(value);
    }).required("Primary mobile number is required"),

    // additional_mobile: Yup.string().test('starts-not-with-0-to-5', 'Secondary mobile number is not valid', function(value) {
    //   if (!value) return true;
    //   return !/^[0-5]/.test(value);
    // }),
    
};

const EditNMStaff = () => {
  const { setIsLoading } = useContext(LoaderContext);
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [defaultValueData, setDefaultValueData] = useState({});
  const [isAreaDropDown, setIsAreaDropDown] = useState(false);
  const [areaManagerList, setAreaMangerList] = useState([]);
  const [nmStaffRole, setNmStaffRole] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateChangeVal, setStateChageVal] = useState("");
  const [isCityStateDropdown, setIsCityStatedropDown] = useState(false);

  useEffect(() => {
    const hasLabel = ["3", "5", "6"].includes(nmStaffRole);
    hasLabel ? setIsCityStatedropDown(true) : setIsCityStatedropDown(false);

    const hasLabelDealear = ["4", "7"].includes(nmStaffRole);

    hasLabelDealear ? setIsAreaDropDown(true) : setIsAreaDropDown(false);
  }, [nmStaffRole]);

  //  user all roles list
  useEffect(() => {
    axiosPrivate.get("roles/get/all").then((user_roles) => {
      setRoles(user_roles?.data?.data);
    });
  }, []);

  // all state list
  useEffect(() => {
    axiosPrivate.get("locations/get/state").then((response) => {
      setStates(response.data.data);
    });
  }, []);

  //all city list
  useEffect(() => {
    axiosPrivate
      .post("locations/get/city", { state: stateChangeVal })
      .then((response) => {
        let currentArray = cities;
        currentArray.push(response.data.data);
        setCities(currentArray);

        // Set the first state as the default nmStaffRole state
      });
  }, [stateChangeVal]);

  //nmStaff data get by id
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`users/get/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          setDefaultValueData({ ...response.data.data });
        }

        if (
          response.data.data.role == 3 ||
          response.data.data.role == 5 ||
          response.data.data.role == 6
        ) {
          setIsCityStatedropDown(true);
          // if (response.data.data.state_id) {
          // 	setStateChageVal(response.data.data.state_id);
          // };
          let currentArray = [];
          response.data.data.assigned_address.forEach(async (element) => {
            await axiosPrivate
              .post("locations/get/city", { state: element.state })
              .then((response) => {
                currentArray.push(response.data.data);
              });
          });
          setCities(currentArray);
        } else if (
          response.data.data.role === 4 ||
          response.data.data.role === 7
        ) {
          setIsAreaDropDown(true);
        } else {
          setIsCityStatedropDown(false);
          setIsAreaDropDown(false);
        }
        // formik.setFieldValue('addresses', array);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
        setIsLoading(false);
      });
  }, [roles]);

  // Area Manager list
  useEffect(() => {
    axiosPrivate.get("users/get/user/area_manager").then((response) => {
      setAreaMangerList(response.data.data);
      formik.setFieldValue(
        "area_manager_id",
        response.data.data.area_manager_id
      );
    });
  }, []);

  //set default value
  useEffect(() => {
    if (defaultValueData != "") {
      formik.setValues({ ...defaultValueData });
    }
  }, [defaultValueData]);

  //  update and send data to api (after check validation)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      primary_mobile: "",
      secondary_mobile: "",
      role: "",
      assigned_address: [],
      area_manager_id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      var values = {
        name: values.name,
        email: values.email || null,
        primary_mobile: values.mobile,
        secondary_mobile: values?.additional_mobile || 0,
        role: String(values.role),
        assigned_address: values.assigned_address ?? null,
        area_manager_id: values.area_manager_id || null,
      };

      axiosPrivate
        .patch(`users/update/${id}`, values)
        .then((response) => {
          setAlertMessage({ message: "User Updated Successfully" });
          setIsLoading(false);
          navigate("/admin/users");
        })
        .catch((error) => {
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
          setIsLoading(false);
        });
    },
  });

  const onSelectState = (event, index) => {
    // setStateSelectedState(event.target.value);
    // const stateArray = event.target.value.map(obj => obj.state);
    axiosPrivate
      .post("locations/get/city", { state: event.target.value })
      .then((response) => {
        let currentArray = cities;
        currentArray[index] = response.data.data;
        document.getElementById(`assigned_address[${index}].city`).value = "";
        setCities(currentArray);
      });
  };

  const onAddRow = (check) => {
    if (check) {
      let newObj = {
        state: "",
        city: "",
        zip: "",
      };
      let currentObj = formik.values.assigned_address;
      currentObj.push(newObj);
      formik.setFieldValue("assigned_address", currentObj);
    } else {
      // if(formik.values.addresses.length > 1) {
      let currentObj = formik.values.assigned_address;
      currentObj.splice(currentObj.length - 1, 1);
      formik.setFieldValue("assigned_address", currentObj);
      // }
    }
  };


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit User Details</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin/dashboard" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/users">Users</Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>

            <li className="route-heading item mx-2 mt-1">Edit User Details</li>
          </ol>
        </div>

        <div className="tab-content">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    {/* name */}
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label>
                        Name:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name *"
                          onChange={(event) => {
                            nameFilter(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* email */}
                    <div className="col-md-6 col-sm-12">
                      <label>
                        Email:<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Email ID *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email &&
                            formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                      </div>
                    </div>
                    {/* primary mobile number */}
                    <div className="col-md-6 col-sm-12">
                      <label>
                        Primary Mobile Number:
                        <span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <input
                          id="mobile"
                          name="mobile"
                          type="text"
                          className="form-control"
                          placeholder="Primary Mobile Number"
                          onChange={(event) => {
                            numberFilter(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                          maxLength={10}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Secondary mobile number */}
                    <div className="col-md-6 col-sm-12">
                      <label>
                        Secondary Mobile Number (Optional):
                        <span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="additional_mobile"
                          name="additional_mobile"
                          type="text"
                          className="form-control"
                          placeholder="Secondary Mobile Number"
                          onChange={(event) => {
                            numberFilter(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.additional_mobile}
                          maxLength={10}
                        />
                        {formik.touched.additional_mobile &&
                            formik.errors.additional_mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.additional_mobile}
                            </div>
                          ) : null}
                      </div>
                    </div>
                    {/*  role select */}
                    <div className="col-md-6 col-sm-12">
                      <label>
                        Select Role:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="role"
                          name="role"
                          onChange={(event) => {
                            setNmStaffRole(event.target.value);
                            formik.handleChange(event);
                          }}
                          value={formik.values.role}
                        >
                          <option>Select roles </option>
                          {roles.map((res) => (
                            <option key={res.id} value={res.id}>
                              {res.name
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </option>
                          ))}
                        </select>

                        {formik?.touched.role &&
                        formik.errors.role &&
                        nmStaffRole.length == 0 ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix">
                    {isCityStateDropdown ? (
                      <>
                        {formik.values.assigned_address?.length > 0 &&
                          formik.values.assigned_address.map((res, index) => (
                            <>
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  State:<span className="required"></span>
                                </label>

                                <div className="form-group">
                                  {/* <MultiSelect display="chip" optionLabel="state" value={stateSelectedState} placeholder="Select State" name='state' filter options={states} onChange={(e) => onSelectState(e)} /> */}
                                  <select
                                    className="form-control show-tick"
                                    id={`assigned_address[${index}].state`}
                                    name={`assigned_address[${index}].state`}
                                    onChange={(event) => {
                                      formik.handleChange(event);
                                      onSelectState(event, index);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.assigned_address[index]
                                        .state
                                    }
                                  >
                                    <option>Select State</option>
                                    {states.length > 0 &&
                                      states?.map((role) => (
                                        <option key={role} value={role}>
                                          {role}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  City:<span className="required"></span>
                                </label>

                                <div className="form-group">
                                  <select
                                    className="form-control show-tick"
                                    id={`assigned_address[${index}].city`}
                                    name={`assigned_address[${index}].city`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.assigned_address[index].city
                                    }
                                  >
                                    <option>Select City</option>
                                    {cities[index]?.length > 0 &&
                                      cities[index]?.map((role, index) => (
                                        <option key={index} value={role.id}>
                                          {role.city}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <label>
                                  Pincode:<span className="required"></span>
                                </label>

                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    placeholder="Enter zip_code"
                                    name={`assigned_address[${index}].zip`}
                                    id={`assigned_address[${index}].zip`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values.assigned_address[index].zip
                                    }
                                  />
                                   {formik.touched.zip && formik.errors.zip ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.zip}
                          </div>
                        ) : null}
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      ""
                    )}

                    {isAreaDropDown ? (
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Area Manager:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="area_manager_id"
                            name="area_manager_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.area_manager_id}
                          >
                            <option>Select Area Manager</option>
                            {areaManagerList.length > 0 &&
                              areaManagerList.map((role, key) => (
                                <option key={key + role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isCityStateDropdown ? (
                      <div className="action_btn_section mb-5">
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow(true);
                          }}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow(false);
                          }}
                        >
                          -
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* button */}
                    <div className="col-md-12 col-sm-12 submit-btn">
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/users")}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary ml-2">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditNMStaff;
