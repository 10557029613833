import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../context/components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Permission.module.css';

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	permission_name: Yup.string()
		.max(200, 'Name must not exceed 200 characters')
		.required('Permission Name is required'),
	permission_module: Yup.string()
		.max(200, 'Name must not exceed 200 characters')
		.required('Permission Module is required'),
};

const AddPermission = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		axiosPrivate
			.get('roles')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
			});
	}, []);

	const formik = useFormik({
		initialValues: {
			permission_name: '',
			permission_module: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.post('permissions/create', values)
				.then((response) => {
					// alert("response");
					setAlertMessage({ message: 'permission created successfully' });
					navigate('/admin/permission');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/permission', add: '/admin/permission/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Permission</strong>
									</div>

									<div className="card-body">
										<div className="row clearfix">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="permission_name"
														name="permission_name"
														type="text"
														className="form-control"
														placeholder="Permission Name*"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.permission_name}
													/>
													{formik.touched.permission_name &&
													formik.errors.permission_name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.permission_name}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="permission_module"
														name="permission_module"
														type="text"
														className="form-control"
														placeholder="Permission Module*"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.permission_module}
													/>
													{formik.touched.permission_module &&
													formik.errors.permission_module ? (
														<div className={classes.invalidDataError}>
															{formik.errors.permission_module}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-12">
												<hr className="mt-4" />
												<div className="table-responsive">
													<table className="table table-striped">
														<tbody></tbody>
													</table>
												</div>
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/permission')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddPermission;
