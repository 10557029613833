import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Customer.module.css';

//mobile validation check rajex
const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
	email: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),

	mobile: Yup.string()
		.required('Primary Mobile is required')
		.matches(phoneRegExp, 'Mobile number is not valid')
		.min(10, 'Mobile number must be of 10 digits')
		.max(10, 'Mobile number must be of 10 digits'),
};

const EditCustomer = () => {
	let { id } = useParams();
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [defaultValueData, setDefaultValueData] = useState({});

	// nmStaff data get by id
	useEffect(() => {
		axiosPrivate.get(`users/get/${id}`).then((response) => {
			if (response.data.code === 200) {
				setDefaultValueData(response.data.data);
			}
		});
	}, []);

	//set default value
	useEffect(() => {
		if (defaultValueData != '') {
			formik.setValues({ ...defaultValueData });
		}
	}, [defaultValueData]);

	//  update and send data to api (after check validation)
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			mobile: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.patch(`users/ecom/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'customer Updated Successfully' });
					navigate('/admin/customer');
				})
				.catch((error) => {
					showErrorToast('Internal server error');

					if (error.response) {
						if (typeof error.response.data.errors == 'array') {
							showErrorToast(error.response.data.errors[0].msg);
						} else {
							showErrorToast(error.response.data.errors.msg);
						}
					}
				});
		},
	});

	return (
		<div className="section-body ">
			<div className="container-fluid">
				{/* hearder breadcrumb */}
				<div className="card-header ">
					<strong className="top-left-heading">Edit Customer</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin/dashboard" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">
							<Link to="/admin/customer">Customer</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">Edit Customer</li>
					</ol>
				</div>

				<div className="tab-content ">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong style={{ fontWeight: '700', fontSize: '18px' }}>
										Edit Customer
									</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										{/* name */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Name:<span className="required">*</span>
											</label>

											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Name "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>
										{/* email */}
										<div className="col-md-6 col-sm-12">
											<label>
												Email:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="email"
													name="email"
													type="email"
													className="form-control"
													placeholder="Email ID "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{/* {formik.touched.email && formik.errors.email ? (
													<div className={classes.invalidDataError}>
														{formik.errors.email}
													</div>
												) : null} */}
											</div>
										</div>
										{/* primary mobile number */}
										<div className="col-md-6 col-sm-12">
											<label>
												Primary Mobile Number:
												<span className="required">*</span>
											</label>

											<div className="form-group">
												<input
													id="mobile"
													name="mobile"
													type="number"
													className="form-control"
													placeholder="Primary Mobile Number"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.mobile}
												/>
												{formik.touched.mobile && formik.errors.mobile ? (
													<div className={classes.invalidDataError}>
														{formik.errors.mobile}
													</div>
												) : null}
											</div>
										</div>

										{/* button */}
										<div className="col-md-12 col-sm-12 submit-btn">
											<div className="form-group">
												<button
													type="button"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/customer')}
												>
													Close
												</button>
												<button type="submit" className="btn btn-primary ml-2">
													Update
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditCustomer;
