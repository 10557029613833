import React from 'react'
import { useEffect } from 'react';

 function CertifiedSection(props) {
        let slicedHeading = props?.data?.certifiedsubheading?.split(' ');
        let targetedHeading = slicedHeading? slicedHeading[slicedHeading?.length - 1]: '';
        let sortedHeading = slicedHeading? (slicedHeading?.slice(0, slicedHeading?.length-1).join(' ')): '';

    return (
        <div className='nm_home'>
            <div className='certified-section'>
                <div className='responsive-heading'>
                    <h2 className='certified-heading'>{props?.data?.certifiedheading}</h2>
                    <div className='underline'></div>
                    <h3 className='certified-sub-heading'>{sortedHeading? sortedHeading: ''} <span className='targedHeading'>{targetedHeading? targetedHeading: ''}</span></h3>

                </div>
                <div className='certified-row'>
                    {/* heading & content */}
                    <div className='certified-section-content'>
                        <div className='desktop-heading'>
                            <h2 className='certified-heading'>{props?.data?.certifiedheading}</h2>
                            <div className='underline'></div>
                            <h3 className='certified-sub-heading'>{sortedHeading? sortedHeading: ''} <span className='targedHeading'>{targetedHeading? targetedHeading: ''}</span></h3>
                        </div>
                        <p className='my-0 product-section-para certified-section-content-p'>{props?.data?.certifiedcontent}</p>
                    </div>
                    {/* logos */}
                    <div className='certified-logos'>

                        <div className='certified-img-div'>
                            <img src={props?.data?.certifiedMainImage} className='certified-logo-img rsoca-logo' alt="RSOCA" loading='lazy' />
                        </div>
                        <div className='certified-sub-logos'>
                        {props?.data?.certifiedImgs?.map((res,index) => {
                            return(
                            <div className='sub-logo-div' key={index}>
                                <img src={res.certifiedImage} alt='WHO' loading='lazy' />
                            </div >

                            )
                        })}

                            {/* // <div className='sub-logo-div'>
                            //     <img src='./img/ISO-Logo.png' alt='' loading='lazy' />
                            // </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default React.memo(CertifiedSection)