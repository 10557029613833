import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Order.module.css";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/loader/LoaderContext";

// validation
const validationRules = {
  dealer_id: Yup.string().required("Dealer is required"),
  areaManager_id: Yup.string().required("Area manager is required"),
  stock_id: Yup.string().required("Product  is required"),
  qty: Yup.string().required("Quantity is required"),
  shipping_address_id: Yup.string().required("Shipping address is required"),
  salesman_id: Yup.string().required("Sales person is required"),
  image: Yup.string().required("Image is required"),
};

const AddOrder = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoaderContext);
  const { setAlertMessage } = useContext(AlertContext);
  const { userDetails } = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [salesList, setSalesList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [areaManagerList, setAreaManagerList] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [logicticList, setLogisticList] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [seasonDropDown, setSeasonDropDown] = useState(false);
  const [dearlerDropDown, setDealerDropDown] = useState(false);
  const [salesmanDropDown, setSalesmanDropDown] = useState(false);
  const [areamanagerDropDown, setAreaManagerDropDown] = useState(false);
  const [selectDiscountType, setSelectDiscountType] = useState();
  const [logicticDropDown, setLogisticDropDown] = useState(false);
  const [userData, setUserData] = useState();
  const [userAddresses, setUserAddresses] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredDealers, setFilteredDealers] = useState(dealerList);

  const [tableData, setTableData] = useState([
    {
      stock_id: "",
      price: "",
      qty: "",
      shipping_address_id: "",
    },
  ]);

  // season dropdown click function
  const SeasondropdownClick = (event) => {
    if (event.target.value == "Season Discount") {
      setSeasonDropDown(true);
    } else if (event.target.value != "Season Discount") {
      setSeasonDropDown(false);
    }
    setSelectDiscountType(event.target.value);
  };

  //Function to load sales person List
  const salesPersonListHandler = async (id) => {
    //Sales person list start
    if (id != undefined && id != "") {
      await axiosPrivate
        .get(`users/get/sales/person/${id}`)
        .then((salesPerson) => {
          setSalesList(salesPerson?.data?.data);
        });
    } else {
      setSalesList([]);
    }
    return true;
    //Sales person list end
  };

  //Load address id user is
  const setDealerIdHandler = (dealerId) => {
    if (dealerId != "") {
      //Get Dealer address
      axiosPrivate.get(`/users/get/addresses/${dealerId}`).then((addresses) => {
        setShippingAddress(addresses?.data?.data);
      });
    }
    formik.setFieldValue("dealer_id", dealerId);
    return true;
  };
  //Load address

  //Function to chage discount start
  function changeDiscountHandler(value) {
    value = parseInt(value);
    if (value > 0 && value <= 100) {
      setDiscount(parseInt(value));
    } else {
      setDiscount(0);
    }
    formik.setFieldValue("discount", value);
    return value;
  }
  //Function to chage discount end

  //Discount change start
  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [discount]);
  //Discount change end

  // calculate total price  (price*qty)
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    tableData.forEach((item) => {
      totalPrice += parseInt(item.price) * parseInt(item.qty);
    });
    return totalPrice;
  };

  // calculateTotalAmount  (totalprice /100)  including discount
  const calculateTotalAmount = () => {
    let totalPrice = calculateTotalPrice();
    if (discount != null && discount > 0 && discount <= 100) {
      let discountAmount = parseFloat(discount);
      totalPrice -= (parseInt(totalPrice) / 100) * parseInt(discountAmount);
    }
    return parseInt(totalPrice);
  };

  // add new product row
  const addNewRow = (eve) => {
    eve.preventDefault();
    setTableData([
      ...tableData,
      {
        stock_id: "",
        price: "",
        qty: "",
        shipping_address_id: "",
      },
    ]);
  };

  // remove   product row
  const removeExpensesTask = (index, eve) => {
    eve.preventDefault();
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, i) => i !== index));
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      dealer_id: "",
      areaManager_id: "",
      salesman_id: "",
      stock_id: "",
      price: "",
      qty: "",
      discount: "",
      totalAmount: "",
      season_id: "",
      logistic_user_id: "",
      area_manager_remark: "",
      remark: "",
      image: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      let orderId = "";
      const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
      let formData = new FormData();
      formData.append("dealer_id", values.dealer_id);
      formData.append("areaManager_id", values.areaManager_id);
      formData.append("salesman_id", values.salesman_id);
      formData.append("order_rows", JSON.stringify(tableData));
      formData.append("remark", values.remark);
      formData.append("image", values.image);
      formData.append("area_manager_remark", values.area_manager_remark);
      formData.append("total_amount", calculateTotalAmount());
      formData.append("season_id", values.season_id);
      formData.append("logistic_user_id", values.logistic_user_id);
      formData.append("current_user_id", userDetails.id);


      // var values = {
      //   dealer_id: values.dealer_id || null,
      //   areaManager_id: values.areaManager_id || null,
      //   salesman_id: values.salesman_id || null,
      //   order_rows: tableData,
      //   remark: values.remark,
      //   image: values.image,
      //   area_manager_remark: values.area_manager_remark,
      //   total_amount: calculateTotalAmount() || null,
      //   season_id: values.season_id || null,
      //   logistic_user_id: values.logistic_user_id || null,
      //   current_user_id: userDetails.id,
      // };

      await axiosPrivate
        .post("orders/store", formData)
        .then((response) => {
          orderId = response.data.orderId;
          setAlertMessage({ message: "New Order Created Successfully" });
          setIsLoading(false);
          navigate("/admin/order");
        })
        .catch((error) => {
          setIsLoading(false);
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0]?.msg);
          } else {
            showErrorToast(error.response.data.errors?.msg);
          }
        });

      let object = {
        title: "order created",
        order_id: orderId,
        user_id: values.areaManager_id,
        notification_for: "area_manager",
      };
      await axiosPrivate.post("/notifications/store", object).then((res) => {});
    },
  });

  // Component Load start all listing
  useEffect(() => {
    setIsLoading(true);
    //Check role start
    const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserData(userData);
    if (userData.role == "3" || userData.role == "1") {
      setDealerDropDown(true);
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      setLogisticDropDown(true);
    } else if (userData.role == "4") {
      setSalesmanDropDown(true);
      setAreaManagerDropDown(true);
      if (localStorage.getItem("userDetails")) {
        let area_manager_id = JSON.parse(localStorage.getItem("userDetails"));
        formik.setFieldValue("areaManager_id", area_manager_id.area_manager_id);
        if (area_manager_id.area_manager_id) {
          salesPersonListHandler(area_manager_id.area_manager_id);
        }

        if (area_manager_id.id) {
          setDealerIdHandler(area_manager_id.id);
        }
      }
    }
    //Check role end
    //Logistic list start
    axiosPrivate
      .get("users/get/logistic/manager")

      .then((logistics) => {
        setLogisticList(logistics?.data?.data);
      });
    //Logistic list end
    // Season list start
    axiosPrivate.post("seasons").then((seasons) => {
      setSeasonList(seasons?.data?.data);
    });
    //Dealer List start
    axiosPrivate.post("dealers").then((dealers) => {
      setDealerList(dealers?.data?.data);
    });
    //Dealer list end
    // if(userData.role != "4"){
    //Area Manager List start
    axiosPrivate.get("users/get/area/manager").then((AreaManager) => {
      setAreaManagerList(AreaManager?.data?.data);
    });
    //Area Manager List end
    // }
    //Product List start
    axiosPrivate
      .get("stocks")
      .then((order) => {
        setRoles(order?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    //Product List end
  }, []);
  //Component Load end

  return (
    <div>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Add Order</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/order">Order</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">Add Order</li>
            </ol>
          </div>

          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="row mx-3">
                    {dearlerDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Dealer :<span className="required">*</span>
                            </label>
                            {/* <input
                              type="text"
                              className="form-control"
                              name="searchDealer"
                              placeholder="Search for a dealer..."
                              onChange={formik.handleChange}
                              value={searchKeyword}
                            /> */}
                            <select
                              id="dealer_id"
                              className="form-control"
                              name="dealer_id"
                              // onChange={formik.handleChange}
                              onChange={(event) =>
                                setDealerIdHandler(event.target.value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.dealer_id}
                            >
                              <option value="">Select Dealer</option>
                              {dealerList.map((role) => (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.dealer_id &&
                            formik.errors.dealer_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.dealer_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* dealer select */}

                    {areamanagerDropDown > 0 ? (
                      <>
                        {/* Area Manager Select */}
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Area Manager:<span className="required">*</span>
                            </label>
                            <select
                              id="areaManager_id"
                              className="form-control"
                              name="areaManager_id"
                              onChange={async (event) => {
                                formik.handleChange(event);
                                await salesPersonListHandler(
                                  event.target.value
                                );
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.areaManager_id}
                            >
                              <option value="">Select Area Manager</option>
                              {areaManagerList.map((role) =>
                                role.id != null ? (
                                  <option key={role.name} value={role.id}>
                                    {role.name}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                            </select>
                            {formik.touched.areaManager_id &&
                            formik.errors.areaManager_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.areaManager_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* sales person dropdown */}
                    {salesmanDropDown ? (
                      <>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label>
                              Sales Person:<span className="required">*</span>
                            </label>
                            <select
                              id="salesman_id"
                              className="form-control"
                              name="salesman_id"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.salesperson_id}
                            >
                              <option value="">Select Sales Person</option>
                              {salesList.map((role) => (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.salesman_id &&
                            formik.errors.salesman_id ? (
                              <div className={classes.invalidDataError}>
                                {formik.errors.salesman_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* table */}
                  <div className={`card-body ${classes.tableWrapper}`}>
                    <table
                      className="table table-hover"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        {/* table heading */}
                        <tr>
                          <th>
                            <label>
                              Product:<span className="required">*</span>
                            </label>
                          </th>
                          {userData?.role != 4 ? (
                            <th>
                              <label>
                                Price:<span className="required"></span>
                              </label>
                            </th>
                          ) : (
                            ""
                          )}
                          <th>
                            <label>
                              Quantity:<span className="required">*</span>
                            </label>
                          </th>

                          <th>
                            <label>
                              Shipping Address:
                              <span className="required">*</span>
                            </label>
                          </th>
                          <th>
                            <label>
                              {/* Action:<span className="required">*</span> */}
                            </label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            {/* product dropdown */}
                            <td style={{ width: "33%" }}>
                              <select
                                id="stock_id"
                                className="form-control"
                                name="stock_id"
                                value={item.stock_id}
                                onChange={(event) => {
                                  const selectedRole = event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? { ...data, stock_id: selectedRole }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event);
                                  let price = roles.filter(
                                    (res) => res.id == selectedRole
                                  );
                                  tableData[index].price = price[0].rate;
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">Select Product</option>
                                {roles.map((stock_id) => (
                                  <option key={stock_id.id} value={stock_id.id}>
                                    {stock_id.tally_name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.stock_id &&
                              formik.errors.stock_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.stock_id}
                                </div>
                              ) : null}
                            </td>
                            {/* price */}
                            {userData?.role != 4 ? (
                              <td style={{ width: "15%" }}>
                                <input
                                  id="price"
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  value={item.price}
                                  onChange={(event) => {
                                    const priceValue = event.target.value;
                                    setTableData((prevData) =>
                                      prevData.map((data, i) =>
                                        i === index
                                          ? { ...data, price: priceValue }
                                          : data
                                      )
                                    );
                                    formik.handleChange(event);
                                  }}
                                />
                              </td>
                            ) : (
                              ""
                            )}
                            {/* Quantity */}
                            <td style={{ width: "15%" }}>
                              <input
                                id="qty"
                                type="number"
                                className="form-control"
                                name="qty"
                                value={item.qty}
                                onChange={(event) => {
                                  const qtyValue = event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? { ...data, qty: qtyValue }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event); // Call handleChange with the event
                                }}
                              />
                              {formik.touched.qty && formik.errors.qty ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.qty}
                                </div>
                              ) : null}
                            </td>
                            {/* Shipping Address */}
                            <td style={{ width: "100%" }}>
                              <select
                                id="shipping_address_id"
                                className="form-control"
                                name="shipping_address_id"
                                value={item.shipping_address_id}
                                onChange={(event) => {
                                  const selectedSippingAddress =
                                    event.target.value;
                                  setTableData((prevData) =>
                                    prevData.map((data, i) =>
                                      i === index
                                        ? {
                                            ...data,
                                            shipping_address_id:
                                              selectedSippingAddress,
                                          }
                                        : data
                                    )
                                  );
                                  formik.handleChange(event);
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">
                                  Select Shipping Address
                                </option>
                                {shippingAddress.map((shipping) => (
                                  <option key={shipping.id} value={shipping.id}>
                                    {shipping.shipping_address}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.shipping_address_id &&
                              formik.errors.shipping_address_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.shipping_address_id}
                                </div>
                              ) : null}
                            </td>

                            <td style={{ width: "33%" }}>
                              <div className={classes.actionBtns}>
                                <button
                                  className={`btn ${classes.addBtn}`}
                                  onClick={addNewRow}
                                >
                                  <GrAddCircle />
                                </button>
                                {tableData.length > 1 && (
                                  <button
                                    className={`btn ${classes.removeBtn}`}
                                    onClick={(event) =>
                                      removeExpensesTask(index, event)
                                    }
                                  >
                                    <GrSubtractCircle />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* discount field */}
                  <div
                    className="row"
                    style={{ width: " 97%", margin: "auto" }}
                  >
                    {/* Discount Type */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Discount Type:<span className="required"></span>
											</label>

											<div className="form-group">
												<select
													className="form-control"
													name="discount_type"
													onChange={(event) => SeasondropdownClick(event)}
												>
													<option>Select Discount Type</option>

													<option>Season Discount</option>
													<option>Product Discount</option>
													<option>Spacial Offer</option>
												</select>
											</div>
										</div> */}

                    {/* discount */}

                    {/* <div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Discount:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													type="number"
													className="form-control"
													name="discount"
													value={discount}
													onChange={(event) =>
														(event.target.value = changeDiscountHandler(
															event.target.value
														))
													}
												/>
											</div>
										</div> */}

                    {/* total Amount */}
                    {userData?.role != 4 ? (
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Total Order Amount:<span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="totalAmount"
                            type="number"
                            className="form-control"
                            name="totalAmount"
                            value={calculateTotalAmount()}
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Remark for Dealer */}
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Remarks:<span className="required"></span>
                      </label>

                      <div className="form-group">
                        <input
                          id="remark"
                          type="text"
                          className="form-control"
                          name="remark"
                          placeholder="Enter Remarks"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.remark}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label>
                        Image:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="image"
                          name="image"
                          type="file"
                          accept="image/*,application/pdf"
                          className="form-control"
                          placeholder="Image *"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "image",
                              e.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.image && formik.errors.image ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.image}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Remark for area manager */}
                    {userData?.role == 3 ? (
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Area Manager Remark:<span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="area_manager_remark"
                            type="text"
                            className="form-control"
                            name="area_manager_remark"
                            placeholder="Enter Remark"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.area_manager_remark}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* SeasonDrowpDown */}
                    {seasonDropDown ? (
                      <div className="col-lg-4 col-md-6 col-sm-12 px-4 ">
                        <div className="form-group">
                          <label>Season</label>
                          <select
                            id="season_id"
                            className="form-control "
                            name="season_id"
                            onChange={(event) => {
                              let discountPercentage = 0;
                              seasonList.map((seasons) => {
                                if (
                                  seasons.id === event.target.value &&
                                  seasons.discount_percentage != ""
                                ) {
                                  return (discountPercentage =
                                    seasons.discount_percentage);
                                }
                              });
                              formik.handleChange(event);
                              formik.setFieldValue(
                                "discount",
                                discountPercentage
                              );
                              setDiscount(discountPercentage);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.season_id}
                          >
                            <option value="">Select Season</option>
                            {seasonList.map((role) => (
                              <option key={role.name} value={role.id}>
                                {role.season_title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.season_id &&
                          formik.errors.season_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.season_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {logicticDropDown ? (
                      <div className="col-lg-4 col-md-6 col-sm-12 px-4 ">
                        <div className="form-group">
                          <label>Order Assign To :</label>
                          <select
                            id="logistic_user_id"
                            className="form-control "
                            name="logistic_user_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.logistic_user_id}
                          >
                            <option value="">Select Logistic Manager</option>
                            {logicticList.map((role) =>
                              role.id ? (
                                <option key={role.name} value={role.id}>
                                  {role.name}
                                </option>
                              ) : (
                                ""
                              )
                            )}
                          </select>
                          {formik.touched.logistic_user_id &&
                          formik.errors.logistic_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.logistic_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>

                  {/* close and submit button */}
                  <div className="col-12 submit-btn my-2">
                    <hr className="mt-4" />
                    <div className="table-responsive">
                      <table className="table table-striped"></table>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => navigate("/admin/order")}
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary ml-2">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
