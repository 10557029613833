import React from "react";
import { RiFacebookFill, RiLinkedinBoxFill } from "react-icons/ri";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { TiSocialYoutube } from "react-icons/ti";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import Gallery from "./homepage/Gallery";

function Footer() {
  let count = 0;
  let count1 = 0;
  let count2 = 0;
  function showHideExplore(string) {
    if (window.innerWidth <= 525) {
      if (string == "Explore") {
        count++;
        let element = document.querySelector(".footer-nav-items-mobile");
        if (count % 2 == 0) element.style.display = "none";
        else element.style.display = "block";
      } else if (string == "Contact") {
        count1++;
        let element = document.querySelector(".contact_navbar_mobile");
        if (count1 % 2 == 0) element.style.display = "none";
        else element.style.display = "block";
      } else if (string == "Gallery") {
        count2++;
        let element = document.querySelector(".gallery_mobile");
        if (count2 % 2 == 0) element.style.display = "none";
        else element.style.display = "block";
      }
    }
  }
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="nm_footer">
      <div className="footer row row-md-cols-4 row-sm-cols-2 row-cols-1 g-0 text-light ">
        <div className="footer-logo-section col-lg-3 col-md-12 col-sm-12 col-12 footer-section">
          {/* Logo */}
          <div>
            <img
              onClick={scroll}
              src="/img/nm-india-biotechlogo1.png"
              alt="NM-India logo"
              className="footer-logo"
              loading="lazy"
            />
          </div>
          <p className="footer-para">
            NM India Biotech Transforming Lives, Nurturing Health, Leading
            Innovation in Biotechnology Solutions.
          </p>
          {/* Social */}
          <div className="social-icons-container">
            <Link to="https://www.facebook.com/nmiindiabio/" target="_blank">
              {" "}
              <RiFacebookFill className="social-icons" />
            </Link>
            <Link
              to="https://www.instagram.com/nmindiabiotech/"
              target="_blank"
            >
              <AiFillInstagram className="social-icons" />
            </Link>
            {/* <Link to="/" target="_blank">
              <AiOutlineTwitter className="social-icons" />
            </Link> */}
            {/* <Link to="/" target="_blank">
              <RiLinkedinBoxFill className="social-icons" />
            </Link> */}
            <Link
              to="https://www.youtube.com/channel/UC7nCrgDg8w_H4BYPLy7PAIA"
              target="_blank"
            >
              <TiSocialYoutube className="social-icons" />
            </Link>
          </div>
        </div>

        {/* Explore */}
        <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-section d-flex justify-content-md-center desktop_explore">
          <div>
            <div className="footer-heading d-flex align-items-center">
              Explore
              <p className="radient-icon" />
            </div>
            <ul className="footer-nav-items">
              <Link to="/">
                <li className="nav-item" onClick={scroll}>
                  Home
                </li>
              </Link>
              <Link to="/about-us">
                <li className="nav-item" onClick={scroll}>
                  About Us
                </li>
              </Link>
              <Link to="/products">
                <li className="nav-item" onClick={scroll}>
                  Products
                </li>
              </Link>
              <Link to="/leadership">
                <li className="nav-item" onClick={scroll}>
                  Leadership
                </li>
              </Link>
              <Link to="/careers">
                <li className="nav-item" onClick={scroll}>
                  Careers
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="nav-item" onClick={scroll}>
                  Enquiry
                </li>
              </Link>
            </ul>
          </div>
        </div>

        {/* Contact */}
        <div className="footer-contact-container col-lg-4 col-md-12 col-sm-12 col-12 footer-section d-flex justify-content-md-center desktop_contact">
          <div className="footer-contact-inner-container">
            <div className="footer-heading d-flex align-items-center footer-contact mx-1">
              Contact
              <p className="radient-icon" />
            </div>
            <div className="contact_navbar">
              <div className="d-flex align-items-center contact-conent footer-margin-top">
                <Link to="/contact-us" onClick={scroll}>
                  {" "}
                  <HiLocationMarker className="footer-location-icons" />
                </Link>
                <div>
                  <h6 className="footer-sub-heading">Factory & Regd. Office</h6>
                  <p className="footer-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur - 313003,
                    Rajasthan, INDIA.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center contact-conent">
                <Link to="/contact-us" onClick={scroll}>
                  {" "}
                  <HiLocationMarker className="footer-location-icons" />
                </Link>
                <div>
                  <h6 className="footer-sub-heading">Regional Mktg. Office</h6>
                  <p className="footer-address">
                    2nd floor, 12, Residency Rd, above Equitas bank, Madhuban,
                    Udaipur, Rajasthan 313001
                  </p>
                </div>
              </div>
              <div className="d-flex contact-conent footer-address-hover">
                <a href="tel:18008919981">
                  <IoMdCall className="footer-contact-icons" />
                </a>
                <p className="footer-address">
                  Toll Free:<a href="tel:18008919981"> 1800 891 9981</a>
                </p>
              </div>
              <div className="d-flex contact-conent footer-address-hover">
                <a href="mailto:info@nmindiabio.com">
                  <MdEmail className="footer-contact-icons" />
                </a>
                <Link
                  to="mailto:info@nmindiabio.com"
                  className="footer-address"
                >
                  info@nmindiabio.com
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        <div className="col-lg-3 col-md-12 col-sm-12 col-12 footer-section d-flex justify-content-md-center desktop_gallery">
          <div className="gallery w-100">
            <div className="footer-heading d-flex align-items-center">
              Gallery
              <p className="radient-icon" />
            </div>
            <div className="footer-gallery-container">
              <Gallery />
            </div>
            <Link to="/gallery-list">
              <p className="text-center view-all" onClick={scroll}>
                View all
              </p>
            </Link>
          </div>
        </div>

        {/* mobile footer */}
        {/* Explore */}
        <div className="col-lg-2 col-md-12 col-sm-12 col-12 footer-section mobile_explore">
          <div>
            <div
              onClick={() => showHideExplore("Explore")}
              className="footer-heading d-flex align-items-center"
            >
              Explore <span className="dropdown-toggle"></span>
            </div>
            <ul className="footer-nav-items footer-nav-items-mobile">
              <Link to="/">
                <li className="nav-item" onClick={scroll}>
                  Home
                </li>
              </Link>
              <Link to="/about-us">
                <li className="nav-item" onClick={scroll}>
                  About us
                </li>
              </Link>
              <Link to="/products">
                <li className="nav-item" onClick={scroll}>
                  Products
                </li>
              </Link>
              <Link to="/leadership">
                <li className="nav-item" onClick={scroll}>
                  Leadership
                </li>
              </Link>
              <Link to="/careers">
                <li className="nav-item" onClick={scroll}>
                  Careers
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="nav-item" onClick={scroll}>
                  Enquiry
                </li>
              </Link>
            </ul>
          </div>
        </div>

        {/* Contact */}
        <div className="footer-contact-container col-lg-4 col-md-12 col-sm-12 col-12 footer-section d-flex justify-content-md-center mobile_contact">
          <div className="footer-contact-inner-container">
            <div
              onClick={() => showHideExplore("Contact")}
              className="footer-heading d-flex align-items-center"
            >
              Contact <span className="dropdown-toggle"></span>
            </div>
            <div className="contact_navbar_mobile">
              <div className="d-flex align-items-center contact-conent">
                <HiLocationMarker className="footer-location-icons" />
                <div>
                  <h6 className="footer-sub-heading">Factory & Regd. Office</h6>
                  <p className="footer-address">
                    Araji No. 4790, Jhamar Kotra Road, Umarda, Udaipur - 313003,
                    Rajasthan, INDIA.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center contact-conent">
                <HiLocationMarker className="footer-location-icons" />
                <div>
                  <h6 className="footer-sub-heading">Regional Mktg. Office</h6>
                  <p className="footer-address">
                    2nd floor, 12, Residency Rd, above Equitas bank, Madhuban,
                    Udaipur, Rajasthan 313001
                  </p>
                </div>
              </div>
              <div className="d-flex contact-conent">
                <IoMdCall className="footer-contact-icons" />
                <p className="footer-address">Toll Free: 1800 891 9981</p>
              </div>
              <div className="d-flex contact-conent">
                <MdEmail className="footer-contact-icons" />
                <p className="footer-address">info@nmindiabio.com</p>
              </div>
            </div>
          </div>
        </div>

        {/* Gallery */}
        <div className="col-lg-3 col-md-12 col-sm-12 col-12 footer-section d-flex justify-content-md-center mobile_gallery">
          <div className="gallery w-100">
            <div
              onClick={() => showHideExplore("Gallery")}
              className="footer-heading d-flex align-items-center"
            >
              Gallery <span className="dropdown-toggle"></span>
            </div>
            <div className="footer-gallery-container  gallery_mobile">
              <Gallery />
              <Link to="/gallery-list">
                <p className="text-center view-all" onClick={scroll}>
                  View all
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Footer); //Footer;
