import React, { useContext } from "react";
import { LoaderContext } from "../context/loader/LoaderContext";

const LoaderComponent = () => {
  const isLoading = true;
  return isLoading ? (
    <div className="spinner_class">
      <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : null;
};

export default LoaderComponent;
