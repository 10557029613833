import React, { useEffect, useState } from 'react'
import ChemicalFreeImage from './ChemicalFreeImage'
import AboutUsSection from './AboutUsSection'
import CertifiedSection from './CertifiedSection'
import EventsAndAchievements from './EventsAndAchievements'
import ProductSection from './ProductSection'

function HomePage(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.pageData);
  }, [props])

  return (
    <div>
      <ChemicalFreeImage data={data} />
      <AboutUsSection data={data}/>
      <ProductSection data={data}/>
      <CertifiedSection data={data}/>
      <EventsAndAchievements data={data}/>
    </div>
  )
}

export default React.memo(HomePage) //HomePage
