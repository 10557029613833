import React from 'react'

function Leadership(props) {
    const pageData = props;
    const initialValues = pageData.pageData;
    // const initialValues = {
    //     page_name: "Leadership",
    //     slug: "/leadership",
    //     main_heading: "Mr. Parmanand Patidar",
    //     main_content: "The journey of Mr. Parmanand Patidar starts with the R. K. Group since its early days in 1991. Working with the R K Group, he has been instrumental in expanding the production of marbles as well as in the establishment of their cement business.(Wonder Cement Ltd.). As a director and part of the leadership team Mr. Patidar has an association of more than 25 years with the R K Group. Being the head of the family, Mr.Patidar laid the seeds of the Sunlight Group in 2003 with younger brother Mr. Pushkar Patidar. The success of this new venture came from his passion for building the necessary structure needed for running general administration and creating a team that can deliver.He earnestly looks forward to the new project (NM India Biotech). For him it is a new born baby who needs to grow and become the first choice of customer in the field of agriculture and animal nutrition. He believes in the basics of simplicity, ethics, quality and transparency as the mantra for the exponential growth of NM India Biotech.",
    //     main_role: "Founder",
    //     main_image: "/img/leaders/Rectangle 113.png",

    //     team_section: [
    //         {
    //             team_member_image: 'img/leaders/Ellipse 38.png',
    //             team_member_name: 'Mr. Anil Kothari',
    //             team_member_content: 'Dr. Anil Kothari carries with him the comprehensive strength of his two decade experience in agricultural extension activities and training of thousands of farmers and agricultural field functionaries. Hailing from Udaipur, Rajasthan, he started his career in the "Directorate of Extension Education Udaipur" straight away after his post-graduation from Rajasthan College of Agriculture. Further, during his doctorate he continued his research on "Knowledge and adoption of post-harvest technology in agriculture".Dr. Kothari has been associated with NM India Biotech since its inception, overseeing the overall functions of technical support along with training and development. His technical capabilities are evident from his remarkable work & contribution in Gramin Vikas Trust, Swach Pariyojna Udaipur & BAIF. He also has set up significant milestones during his work with “The Agriculture Insurance Co. of India Ltd.” He now handles the dual responsibility of technical support & training.',
    //             team_member_role: 'Head - Technical & Training'
    //         },
    //         {
    //             team_member_image: 'img/leaders/Ellipse 37.png',
    //             team_member_name: 'Dr. Nitesh Jasani',
    //             team_member_content: 'As a fresh Msc. Graduate from DAVV - Indore and during his first assignment as Production Manager in Indore Biotech Input little did the Microbiologist in Mr.Nitesh Jasani realize that he would go on to shape and strategize its diverse facets.He spent around 10 years gaining expertise on Managing Production & Quality control, Production of biofertilizers & biopesticides, being a guide to farmers to encourage organic farming.Keeping up with new research he carried out remarkable work on "Capsulation Techniques of Biopesticide products “& Botanical pesticides. He also worked on “Use of Medicago sativa plant in the preparation of vermicompost with the help of Pheretima posthuma."Today, Mr. Jasani is primarily involved in the design and trials of innovative and result oriented agricultural inputs in terms of biofertilizers and plant nutrient supplements aimed at consolidating and placing NM India Biotech on a firm growth trajectory much ahead of competitor perceptions.',
    //             team_member_role: 'Head-Product Innovation & Process'
    //         },
    //         {
    //             team_member_image: 'img/leaders/Ellipse 42.png',
    //             team_member_name: 'Dr. Ramesh Chandra Ojha',
    //             team_member_content: 'Dr. Ramesh Chandra Ojha started his career as a Medical Doctor in 1967 as Medical Jurist in General Hospital, Udaipur, Rajasthan. While his travel around the world, Dr Ojha developed a strong interest in mining & minerals. Being a medical professional he never imagined that this interest would become his passion for the rest of his life. A new mineral was found in 1974 named Siliceous Earth. He started to work on this mineral since it was new and no use was known.He spent around 25 years gaining expertise on Managing Production & Quality control of innovative and result oriented agricultural inputs based on active silicon.Keeping up with new research on active silicon, he collectively worked with Maharana Pratap University of Agriculture & Technology & carried out remarkable studies on the beneficial effects of the mineral on agricultural crops, animal feed and grain storage.Today, Dr. Ojha leads & guides our team involved in manufacturing & research of products based on active silicon.',
    //             team_member_role: 'Technical Advisor'
    //         }
    //     ]
    // };


    return (
        <div className='leadership-page'>
            {/* Founder */}
            <section className='founder-container'>
                <div className='about-founder'>
                    <h1 className='founder-name'>{initialValues.main_heading}</h1>
                    <h2 className='founder-title'>{initialValues.main_role}</h2>
                    <p className='founder-deail'>{initialValues.main_content}</p>
                </div>
                <div className='founder-img-div'>
                    <div className='leader_image'>
                        <img src={initialValues.main_image} alt={initialValues.main_heading} loading='lazy' />
                    </div>
                </div>
            </section>

            {/* Team members */}
            <section className='team-section'>
                {initialValues?.team_section !== undefined && initialValues.team_section.length !== 0 && initialValues.team_section.map((value, index) => {

                    return (
                        index % 2 === 0 ?
                            <div className='team-member-container'>
                                <section className='team-member border-green' >
                                    <span className='border-circle top-circle'></span>
                                    <div className='team-img'>
                                        <img src={value.team_member_image} alt={value.team_member_name} loading='lazy' className='team-member-img' />
                                        <img src="img/leaders/Vector 3.png" alt="" srcset="" loading='lazy' className='img-shadow' />
                                        <span className='img-border'></span>
                                        <span className='border-dot'></span>
                                    </div>
                                    <img src="/img/leaders/Group 302.png" className='team-icons-right' alt="Profile" loading='lazy' />
                                    <div className='members-detail'>
                                        <h2 className='member-name'>{value.team_member_name}</h2>
                                        <h3 className='member-post'>{value.team_member_role}</h3>
                                    </div>
                                </section>
                                <section className='about-team-member '>
                                    <p className='about-team-member-p detail-left'>{value.team_member_content}

                                    </p>
                                </section>
                            </div> :

                            <div className='team-member-container team-member-second'>
                                <section className='about-team-member border-green'>
                                    <p className='about-team-member-p detail-right'>{value.team_member_content}</p>
                                </section>

                                <section className='team-member' >
                                    <div className='team-img'>
                                        <img src={value.team_member_image} alt="Mr. Anil Kothari" loading='lazy' className='team-member-img' />
                                        <img src="img/leaders/Vector 3.png" alt="" srcset="" loading='lazy' className='img-shadow' />
                                        <span className='img-border'></span>
                                        <span className='border-dot'></span>
                                    </div>
                                    <img src="/img/leaders/Group 301.png" className='team-icons-left' alt="setting" loading='lazy' />

                                    <div className='members-detail'>
                                        <h2 className='member-name'>{value.team_member_name}</h2>
                                        <h3 className='member-post'>{value.team_member_role}</h3>
                                    </div>
                                </section>

                            </div>
                    )
                })}

            </section>
        </div>
    )
}

export default React.memo(Leadership)
