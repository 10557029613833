import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../context/components/DataTable2';
import TopNavBar from '../../../context/components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Permission = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [permissions, setPermission] = useState([]);

	const headers = [
		{ name: '#', field: 'id', sortable: true, classKey: 'w60' },
		{ name: 'PERMISSION NAME', field: 'permission_name', sortable: true, classKey: '' },
        { name: 'PERMISSION MODULE', field: 'permission_module', sortable: true, classKey: '' },


		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['permission_name'];

	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('permissions', options)
			.then((permissions) => {

				const permissionsData = permissions.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/permission/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => PermissionDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setPermission(permissionsData);
			})
			.catch((error) => {
			});
	}, []);

	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	const PermissionDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`permissions/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setPermission([]);
					setAlertMessage({ message: 'permission deleted successfully' });
					await refreshTable();
				}
			})
			.catch((error) => {
			});
	};

	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('permissions', options)
				.then((permissions) => {
					const permissionData = permissions.data.data.map((value, key) => {
						let buttons = [];
						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => PermissionDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<i className="fa fa-trash-o text-danger"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});
					setPermission(permissionData);
					resolve(true);
				})
				.catch((error) => {
				});
		});
	};

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/permission', add: '/admin/permission/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">PERMISSION LIST</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={permissions}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Permission;
