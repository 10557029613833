import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
function DeleteConfirm({deleteMethod, setConfirm, text, success, cancel}) {

    function showConfirmMessage(callback) {
		Swal.fire({
			title: "Are you sure?",
			text: text,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#dc3545",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
			closeOnConfirm: false,
			customClass: {
			  cancelButton: 'btn btn-outline-primary ml-2 ',
			  confirmButton: "btn btn-primary",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				callback();
				Swal.fire(
					"Deleted!",
					success,
					"success"
				);
                setConfirm(false)
			} else {
				Swal.fire("Cancelled", cancel, "error");
                setConfirm(false)
			}
		});
	} 


    useEffect(() => {
        showConfirmMessage(() =>{
            deleteMethod()
        })
    }, [])
  return (
    <div></div>
  )
}

export default DeleteConfirm