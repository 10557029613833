import React, { useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../Toster";

// import classes from './addDesignationStyle.module.css';

const Reports = () => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedReport, setSelectedReports] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [validation, setValidation] = useState(false)


  const userRole = JSON.parse(localStorage.getItem("userDetails")).roles;

  const generateReports = async (e) => {
    e.preventDefault();
    if(!selectedReport){
      setValidation(true)
    }else{
      if (selectedReport === "Master Report" && (!fromDate || !toDate)) {
        showErrorToast("Please select both From Date and To Date.");
        return;
      }
    
      try {
        let response;
        if (selectedReport === "Master Report") {
          response = await axiosPrivate.get(`logs/reports/${fromDate}/${toDate}`);
        } else if (selectedReport === "Age Wise Dealer's Outstanding"){
          response = await axiosPrivate.post("tally/fetch/age-wise-report");
        }else{
          response = await axiosPrivate.post("orders/sales-report");
        }
    
        const reportData = response ? response.data.data : [];
    
       
    
        let csvContent;
        let fileName;
    
        if (selectedReport === "Master Report") {
          // const headers = Object.keys(reportData[0]).join(",");
         
          const customHeaders = [
            'Dealer Name',
            'City',
            'State',
            'Area Manager Name',
            'Sales Person Name',
            'Financial Year',
            'Target',
            'Sale',
          ].join(",");
  
          const rows = reportData.map((row) => Object.values(row).join(",")).join("\n");
  
          csvContent = `${customHeaders}\n${rows}`;
          fileName = "master_report.csv";
        } else if(selectedReport === "Age Wise Dealer's Outstanding") {
          const ageWiseCategorizedData = reportData.map(row => {
            const overDueDays = parseInt(row.over_due_days);
            if (overDueDays <= 7) {
              row['0-7days'] = row.due_amount;
              row['8-30days'] = 0;
              row['31-60days'] = 0;
              row['61-90days'] = 0;
              row['91-120days'] = 0;
              row['above 120days'] = 0;
            } else if (overDueDays <= 30) {
              row['0-7days'] = 0;
              row['8-30days'] = row.due_amount;
              row['31-60days'] = 0;
              row['61-90days'] = 0;
              row['91-120days'] = 0;
              row['above 120days'] = 0;
            } else if (overDueDays <= 60) {
              row['0-7days'] = 0;
              row['8-30days'] = 0;
              row['31-60days'] = row.due_amount;
              row['61-90days'] = 0;
              row['91-120days'] = 0;
              row['above 120days'] = 0;
            } else if (overDueDays <= 90) {
              row['0-7days'] = 0;
              row['8-30days'] = 0;
              row['31-60days'] = 0;
              row['61-90days'] = row.due_amount;
              row['91-120days'] = 0;
              row['above 120days'] = 0;
            } else if (overDueDays <= 120) {
              row['0-7days'] = 0;
              row['8-30days'] = 0;
              row['31-60days'] = 0;
              row['61-90days'] = 0;
              row['91-120days'] = row.due_amount;
              row['above 120days'] = 0;
            } else {
              row['0-7days'] = 0;
              row['8-30days'] = 0;
              row['31-60days'] = 0;
              row['61-90days'] = 0;
              row['91-120days'] = 0;
              row['above 120days'] = row.due_amount;
            }
            return row;
          });
          // Age-wise report customization
          const customHeaders = [
            'Dealer Name',
            'Bill Date',
            'Bill Ref',
            'Total Outstanding',
            '0-7days',
            '8-30days',
            '31-60days',
            '61-90days',
            '91-120days',
            'above 120days'
          ].join(",");
          
          const ageWiseRows = ageWiseCategorizedData.map((row) => {
            return [
              row.party_name.replace(/,/g, '-'),
              row.bill_date,
              row.bill_ref,
              row.due_amount,
              row['0-7days'],
              row['8-30days'],
              row['31-60days'],
              row['61-90days'],
              row['91-120days'],
              row['above 120days']
            ].join(",");
          }).join("\n");
          
          csvContent = `${customHeaders}\n${ageWiseRows}`;
          fileName = "age_wise_report.csv";
        }else{
          const customHeaders = [
            'Product Name',
            'Order Quantity',
            'Dispatch Quantity',
            'Total Amount',
          ].join(",");
  
          const rows = reportData.map((row) => Object.values(row).join(",")).join("\n");
  
          csvContent = `${customHeaders}\n${rows}`;
          fileName = "sales_report.csv";
        }
    
        const csvBlob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const downloadUrl = URL.createObjectURL(csvBlob);
  
        const link = document.createElement("a");
        link.setAttribute("href", downloadUrl);
        link.setAttribute("download", fileName);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        // Clean up the temporary URL
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
      }
    }
  };
  

  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Reports</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Reports</li>
            </ol>
          </div>

          <div className="tab-content ">
            <form>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="button_div">
                      <button type="submit" onClick={(e) => generateReports(e)}>
                        Generate Report
                      </button>
                      <Button
                        style={{ display: "none" }}
                        type="button"
                        label="Add Custom Filter"
                        onClick={() => setVisibleRight(true)}
                      />
                    </div>
                    <div className="row clearfix">
                      {/* select report list */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Select Report:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="selectedReports"
                            name="selectedReports"
                            onChange={(e) => {
                              setSelectedReports(e.target.value)
                              setValidation(false)
                            }}
                            value={selectedReport}
                          >
                            <option value={""} selected disabled>Select Report</option>
                            {
                              userRole !== "dealer" && (
                                <>
                                <option>Master Report</option>
                                <option>Age Wise Dealer's Outstanding</option>
                                </>
                              )
                            }
                            <option>Sales Report</option>
                          </select>
                          { validation && (
                            <div className="invalidDataError">
                            Report type is required
                          </div>
                          )}
                        </div>
                      </div>

                      {/* date from */}
                      {selectedReport == "Master Report" && (
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <label>
                            From Date:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="from_date"
                              name="from_date"
                              type="date"
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setFromDate(e.target.value)}
                              value={fromDate}
                              className="form-control"
                              placeholder="From Date *"
                            />
                          </div>
                        </div>
                      )}
                      {/* date to */}
                      {selectedReport == "Master Report" && (
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <label>
                            To Date:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="date_to"
                              name="date_to"
                              type="date"
                              className="form-control"
                              placeholder="Date to*"
                              max={new Date().toISOString().split("T")[0]}
                              onChange={(e) => setToDate(e.target.value)}
                              value={toDate}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h2>Custom Filter</h2>
        <form>
          <div>
            <label>From Day</label>
            <Calendar name="fromDay" id="fromDay" showIcon />
          </div>
          <div>
            <label>To Day</label>
            <Calendar name="toDay" id="toDay" showIcon />
          </div>
        </form>
      </Sidebar>
    </>
  );
};

export default Reports;
