import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import AboutUsPage from "../../frontend-UI/aboutuspage/AboutUsPage";
import HomePage from "../../frontend-UI/homepage/HomePage";
import Leadership from "../../frontend-UI/leadership/Leadership";
import BlogPage from "../../frontend-UI/blog/Blog";
import NewsEvents from "../../frontend-UI/news&events";

const CommonTemplate = () => {
  const {slug} = useParams();
  const [mode , setMode] = useState(slug);
  const [pageData, setPageData] = useState({});
  const [dynamicTagName, setDynamicTagName] = useState("");

  useEffect(() => {

    window.scroll({
      top : 0,
      behavior : "smooth"
    })

    const fetchData = async () => {
      if(slug){
        var mode_local = slug.replace("%20" , '-').replace(" " , "-");
        setMode(mode_local);
      }

      else{
        mode_local = 'home';
        setMode(mode_local);
      }

      const getPageBySlug = (mode_local) => {
        axiosPrivate
          .get("pages/get/slug/" + mode_local)
          .then((res) => {
            const fetchedPageData = res.data.data;
            setPageData(JSON.parse(fetchedPageData.page_json));
            setDynamicTagName(fetchedPageData.template_type);
          })
          .catch((error) => {
          });
      };
      getPageBySlug(mode_local);
    };
    fetchData();
  }, [slug]);

  const renderDynamicComponent = () => {
    switch (dynamicTagName) {
      case "about":
        return <AboutUsPage pageData={pageData} />;
      case "home":
        return <HomePage pageData={pageData} />;
      case "leadership":
        return <Leadership pageData={pageData} />;
      case "blog":
        return <BlogPage pageData={pageData} />;
      case "news-events":
        return <NewsEvents pageData={pageData} />;
      // Add additional cases for other dynamic components
      default:
        return <HomePage pageData={pageData} />;
    }
  };

  return renderDynamicComponent();
};

export default CommonTemplate;
