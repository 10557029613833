import React from 'react'
import { useState, useEffect } from 'react'
import axiosPrivate from "./../../hooks/axiosPrivate"
import DataTable2 from '../../context/components/DataTable2';

function ProductGallery() {
    const [gallery, setGallery] = useState([]);
   
    useEffect(() => {
        axiosPrivate
            .get('gallery')
            .then((res) => {
                setGallery(res.data.data)
            })
            .catch((error) => {
            });
    }, []);

    return (
        <>
            <div className="galleryContainer">
                <div className="outerContainer">
                    <h1 className="mainHeading">Our Farm Gallery</h1>
                    <div className="imageContainer row">
                        {gallery?.map((image, index) => {
                          let mediaType = image?.image?.split('.');
                            if (mediaType[1] == "jpg" || mediaType[1] == "webp" || mediaType[1] == "png") {
                                return (
                                    <div key={index} className="image col-lg-4 col-sm-6 col-12 my-2">
                                        <img src={process.env.REACT_APP_COMMON_FILE_URL + 'gallery/' + image.image} alt={image.alt} loading='lazy' className="galleryContent" />
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className="videoContainer">
                    <img src="../img/bg-pattan1.png" className="background" alt="background" loading='lazy' />
                    <h2 className="subHeading">Harmful Chemical Free Healthy Fresh Vegetables!</h2>
                    <div className="imageContainer row">
                        {gallery?.map((image, index) => {
                            let mediaType = image?.image?.split('.');
                            if (mediaType[1] == "mp4") {
                                return (
                                    <div key={index} className="image col-lg-4 col-sm-6 col-12 my-2">
                                        <video controls className="galleryContent">
                                            <source src={process.env.REACT_APP_COMMON_FILE_URL + 'gallery/' + image.image} type="video/mp4" />
                                        </video>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ProductGallery) //ProductGallery

